import React, { useCallback, useContext, useMemo } from 'react';

import { MainContext } from "../../MainContext";

import Table from '../../commonUI/Table';
import DebtRowMemoised from "./DebtRow";


const initialPageSize = 10;
const initialArrayDataObject = [];
const initialShowOnlyActiveState = true;

const Debt = () => {
    const { userData } = useContext(MainContext);
    
    const columns = useMemo(() => 
      [
        {
            Header: 'Объект',
            accessor: 'dest',
        },
        {
            Header: 'Номер',
            accessor: 'number',
        },
        {
            Header: 'Марка',
            accessor: 'brand',
        },
        {
            Header: 'Категория',
            accessor: 'category',
        },
        {
            Header: 'Комментарий',
            accessor: 'comment',
        },
        {
            Header: 'Статус парковки',
            accessor: 'parking_type',
        },
        {
            Header: 'Фото',
            accessor: 'useful_photo',
            disableFilters: true,
        },
        {
            Header: 'Доступ оплачен',
            accessor: 'paid',
            disableFilters: true,
        },
        {
            Header: 'Время заявки',
            accessor: 'created_at',
        },
        {
            Header: 'Время доступа',
            accessor: 'entered_at',
        },
        {
            Header: 'Точка доступа',
            accessor: 'entry_point',
        },
        {
            Header: 'origin_ID',
            accessor: 'origin_ID',
            // disableFilters: true,
        }
    ], []);

    const getJournalRowMemoised = useCallback(({
        row,
        hiddenColumnsLength,
        justAlteredRowsRef,
    }) => {

        return <DebtRowMemoised
            key={`row-entry-${row.original.origin_ID}__${row.original.updated_at.replace(/\s/g, '_')}__${hiddenColumnsLength}`} 
            preparedRow={row}
            justAlteredRowsRef={justAlteredRowsRef}
        />

    }, [])


    return (
        <Table
            location='/accounting'
            tablePrefix="debt"
            initialArrayDataObject={initialArrayDataObject}
            initialShowOnlyActiveState={initialShowOnlyActiveState}
            columns={columns}
            initialHiddenColumns={['useful_photo', 'entry_point', 'entered_at', 'created_at', 'origin_ID']}
            toggle={
                {
                    enabled: true,
                    addClassName: "debt-toggle",
                    mutateId: true,
                    showOnlyActiveLabel: "Только неоплаченные:",
                    showAllLabel: "Весь журнал с платными услугами:",
                    onlyHat: true,
                }
            } 
            dataSettings={{
                updateFrequency: 5000,
                cachedDataLifeSpan: 180000,
                initialPageSize,
                nocodb_auth: userData.nocodb_auth,
                onViewSwitchFilterCondition: '~not(area_status,like,Постоплата)~and((entered,eq,true)~and(((credit,eq,true)~and(paid,eq,false))~or(parking,eq,Парковка в кредит))))',
                offViewSwitchFilterCondition: '~not(area_status,like,Постоплата)~and((entered,eq,true)~and((credit,eq,true)~or((parking,eq,Парковка в кредит)~or(parking,eq,Парковка оплачена))~or(paid,eq,true)))',
                sortString:"-updated_at",
                altSortString: "-updated_at",
                localFieldToCompareBy: "updated_at_full",
                remoteFieldToCompareBy: "updated_at",
                keepTableSettings: false,
                syncRecordsPerPage: true,
            }}
            tableFooter={{
                minimalControls: true,
            }}
            returnMemoizedTableRowComponent={getJournalRowMemoised}
            noEntriesMessage={'Нет подходящих записей'}
            keepTableEntries={false}
        />
    );

}

export default Debt;