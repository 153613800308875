import { QuerySort, aggregate, readItems } from "@directus/sdk";
import { Collections, Schema, Types } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";
import { getDirectusDateFilter, reduceDirectusFilesToSringArray } from "../DirectusServices/directusHelpers";
import { formatDateTime } from "../dateTimeHelpers";
import { BrandFormatted } from "./provideBrands";

type LandObject = {
  Id: number;
  area_name: string;
  status: string;
};

export type LocalVehicleRowData = {
  dest: LandObject,
  number: string,
  brand: BrandFormatted,
  comment: string,
  docs?: Array<Types.UUID>,
  photo?: Types.UUID,
  mark?: string,
  id_issue_2024?: string,
  updated_at: Types.DateTime,
  updated_at_full: Types.DateTime,
  created_at: Types.DateTime,
  archived: boolean,
  origin_ID: number,
}

export type ProvideLocalVehiclesOptions = {
  pageNo?: number,
  recordsLimit?: number,
  filters?: Array<{ id: string, value: string | number | boolean }>,
  currentViewFilterCondition?: string,
  sortString?: Array<QuerySort<Schema, Collections.LocalVehicles>>,
  controller?: AbortController,
}


export async function provideLocalVehicles({
  pageNo = 0,
  recordsLimit = 50,
  filters = [],
  currentViewFilterCondition,
  sortString = ["-updated_at"],
  controller = undefined,
}: ProvideLocalVehiclesOptions) {
  const client = getDirectusClient();
  const entries: Array<LocalVehicleRowData> = [];

  const filter = filters.reduce((acc, current) => {
    
    if (current?.value === undefined) {
      return acc;
    }

    if (current.id === "origin_ID") {
      acc["_and"].push({
        "id": {
          "_eq": current.value
        }
      });
      return acc;
    }
    
    if (current.id === "dest") {
      acc["_and"].push({
        "lands_n_objects": {
          "area_name": {
            "_icontains": current.value
          }
        }
      });
      return acc;
    }
    
    if (["updated_at", "created_at"].includes(current.id)) {
      const dateConditions = getDirectusDateFilter({
        dateField: current.id,
        dateString: current.value as string,
      })
      acc["_and"] = acc["_and"].concat(dateConditions);
      return acc;
    }
    
    if ( current.id === "archived") {
      
      const bool = current.value === "true" || current.value === "TRUE" || current.value === 1 || current.value === true ? true : false;
      
      acc["_and"].push({
        [current.id]: {
          "_eq": bool
        }
      });
      return acc;
    }
    
    acc["_and"].push({
      [current.id]: {
        "_icontains": current.value
      }
    });
    return acc;
    
  }, { "_and": [] });

  if (currentViewFilterCondition?.includes("archived,eq,false")) {
    filter["_and"].push({
      archived: {
        _eq: false
      }
    });
  }
  
  const totalCountRequest = await client.request(aggregate("local_vehicles", {
    aggregate: {
      count: "*"
    },
    query: {
      filter: filter
    }
  }));

  const totalCount = (totalCountRequest?.[0]?.count && parseInt(totalCountRequest?.[0]?.count, 10)) || 0;

  const response = await client.request(readItems("local_vehicles", {
    limit: recordsLimit,
    offset: pageNo * recordsLimit,
    sort: sortString,
    filter,
    fields: [
      "id",
      "archived",
      "comment",
      "number",
      { "brands": ["brand", "id"] },
      "emoji_mark",
      {
        "document": ["directus_files_id"]
      },
      "photo",
      "id_issue_2024",
      "updated_at",
      "created_at",
      { "lands_n_objects": ["area_name", "id", "status"] }
    ],
    signal: controller.signal,
  }));

  // console.log("response", response);

  response.forEach(entry => {
    const object: LandObject = entry?.lands_n_objects ? {
      Id: entry.lands_n_objects.id,
      area_name: entry.lands_n_objects.area_name,
      status: entry.lands_n_objects.status?.join(", ")
    } : null;

    const brandFormatted: BrandFormatted = {
      label: entry?.brands?.brand,
      value: entry?.brands.id,
    }

    // why do cached data fail an it is called all the time?
    entries.push(
      {
        dest: object,
        number: entry.number,
        brand: brandFormatted,
        comment: entry.comment,
        docs: reduceDirectusFilesToSringArray(entry.document),
        mark: entry.emoji_mark,
        photo: entry.photo as string | null,
        updated_at: formatDateTime(entry?.updated_at),
        updated_at_full: entry?.updated_at,
        created_at: formatDateTime(entry?.created_at),
        archived: entry.archived,
        origin_ID: entry.id,
        id_issue_2024: entry?.id_issue_2024,
      }
    );

  });


  return { count: totalCount, data: entries };

}
