import { QuerySort, aggregate, readItems } from "@directus/sdk";
import { Collections, Schema, Types } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";
import { getDirectusDateFilter } from "../DirectusServices/directusHelpers";
import { formatDate, formatDateTime } from "../dateTimeHelpers";

type LandObject = {
  Id: number;
  area_name: string;
  status: string;
};

export type ContactRow = {
  object: Array<LandObject>,
  name: string,
  position: string,
  comment: string,
  phone: string,
  email: string,
  birth_date: Types.Date,
  power_of_attorney_expiration: Types.DateTime | null,
  days_before_powers_expiration: number | null,
  powersStatus: string,
  updated_at: Types.DateTime,
  updated_at_full: Types.DateTime,
  created_at: Types.DateTime,
  archived: boolean,
  origin_ID: number,
}

export type ProvideContactsOptions = {
  pageNo?: number,
  recordsLimit?: number,
  // filters?: Array<{ id: string, value: string }>,
  filters?: Array<{ id: string, value: string | number | boolean }>,
  currentViewFilterCondition?: string,
  sortString?: Array<QuerySort<Schema, Collections.ContactsOnObjects>>,
  controller?: AbortController,
}

export const provideContacts = async ({
  pageNo = 0,
  recordsLimit = 50,
  filters = [],
  currentViewFilterCondition,
  sortString = ["-updated_at"],
  controller = undefined,
}: ProvideContactsOptions): Promise<{ count: number, data: Array<ContactRow> }> => {
  const client = getDirectusClient();
  const entries: Array<ContactRow> = [];

  const filter = filters.reduce((acc, current) => {

    if (current.id === "object") {
      acc["_and"].push({
        "lands_n_objects": {
          "lands_n_objects_id": {
            "area_name": {
              "_icontains": current.value
            }
          }
        }
      });
    } else if (["power_of_attorney_expiration", "updated_at", "created_at"].includes(current.id)) {
      const dateConditions = getDirectusDateFilter({
        dateField: current.id,
        dateString: current.value as string,
      })
      // console.log("dateConditions", dateConditions);
      acc["_and"] = acc["_and"].concat(dateConditions);
    } else if (["archived", "entered", "credit", "paid"].includes(current.id)) {
      const bool = current.value === "true" || current.value === "TRUE" || current.value === 1 || current.value === true ? true : false;
      acc["_and"].push({
        [current.id]: {
          "_eq": bool
        }
      });
    } else {
      acc["_and"].push({
        [current.id]: {
          "_icontains": current.value
        }
      });
    }
    return acc;
  }, { "_and": [] });

  if (currentViewFilterCondition?.includes("archived,eq,false")) {
    filter["_and"].push({
      archived: {
        _eq: false
      }
    });
  }

  // console.log("filter to fetch", filter);

  const totalCountRequest = await client.request(aggregate("contacts_on_objects", {
    aggregate: {
      count: "*"
    },
    query: {
      filter: filter
    }
  }));
  const totalCount = (totalCountRequest?.[0]?.count && parseInt(totalCountRequest?.[0]?.count, 10)) || 0;

  const response = await client.request(readItems("contacts_on_objects", {
    limit: recordsLimit,
    offset: pageNo * recordsLimit,
    sort: sortString,
    filter,
    fields: [
      "archived",
      "birth_date",
      "comment",
      "email",
      "id",
      "name",
      "phone",
      "position",
      "updated_at",
      "created_at",
      // "lands_n_objects",
      {
        "lands_n_objects": [
          {
            "lands_n_objects_id": ["area_name", "id", "status"]
          }
        ]
      },
      "power_of_attorney",
      {
        "power_of_attorney": [
          "expiry",
          "archived"
        ]
      }
    ],
    deep: {
      "power_of_attorney": {
        _filter: {
          "archived": {
            _eq: true
          }
        }
      }
    },
    signal: controller.signal,
  }));

  // console.log("response", response);

  response.forEach(entry => {
    const rawObjects = entry?.lands_n_objects || [];
    
    const objects: LandObject[] = rawObjects.reduce((acc, current) => {
      
      const objFormatted = {
        id: current.lands_n_objects_id.id,
        area_name: current.lands_n_objects_id.area_name,
        status: current.lands_n_objects_id.status?.join(", ")
      }
      
      acc.push(objFormatted)
      
      return acc;
    }, [])

    const expiryPoA = formatDate(entry?.power_of_attorney?.[0]?.expiry)
    const statusPoA = entry?.power_of_attorney?.[0]?.archived ? null : "Действительная доверенность"

    entries.push({
      object: objects,
      name: entry?.name,
      position: entry?.position,
      comment: entry?.comment,
      phone: entry?.phone,
      email: entry?.email,
      birth_date: formatDate(entry?.birth_date),
      power_of_attorney_expiration: expiryPoA,
      days_before_powers_expiration: null,
      powersStatus: statusPoA,
      updated_at: formatDateTime(entry.updated_at),
      updated_at_full: formatDateTime(entry.updated_at),
      created_at: formatDateTime(entry.created_at),
      archived: entry.archived,
      origin_ID: entry.id,
    });
  });
  // console.log("provideContacts response", entries);
  // console.log("provideContacts return fresh data");
  return { count: totalCount, data: entries };
};
