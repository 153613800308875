import { getRidOfAliases, applyTimezone } from "./littleHelpers"
import { getColumnData, requestRowsData, makeFilterString } from "./CRUDJournalData"

const areasEndpoint = "lands_n_objects";
const brandsEndpoint = "brands" // /views/brands";
const blacklistEndpoint = "md_cyty6tsesdqfc4" // 'blacklist';
const depositsEndpoint = "deposits";
const journalEndpoint = 'journal'
const pedestriansEndpoint = 'pedestrians'
const vehiclesEndpoint = "md_cghkq5wf91jn25" // 'local_vehicles'
const contactsEndpoint = 'contacts_on_objects'


const keywordsEndpoint = 'keywords';

const provideAreas = async ({
    auth_token, 
    fields = "Id,area_name,status", 
    currentViewFilterCondition,
    controller,
}) => {
    let areasFormatted = [];

    let filterString = makeFilterString(undefined, currentViewFilterCondition);

    let areas = await requestRowsData({
        auth_token: auth_token,
        endpoint: areasEndpoint,
        recordsLimit: 1000,
        fields: fields,
        sortString: "area_name",
        filterString: filterString,
        controller: controller,
    });

    if (!areas || !areas.list || areas.list.length < 1) {
        return
    }
    
    areas.list.forEach(element => {
        let objectToPush = {
            value: element.Id,
            label: element.area_name,
        }

        if (fields.includes("status")) {
            objectToPush.area_status = element.status;
        }

        areasFormatted.push(
            objectToPush
        )
        
    });

    return areasFormatted;
}

const provideKeywords = async (auth_token, destinations) => {
    let filterString = 'where=(archived,eq,false)'
    let keywords = [];
    let tempDestIDs = [];

    destinations.forEach(dest => {
        tempDestIDs.push(dest.value);
    });

    filterString += `~and(${process.env.REACT_APP_SYS_LANDS_N_OBJECTS_ID},in,${tempDestIDs.join()})`

    let keywordsData = await requestRowsData({
        auth_token,
        endpoint: keywordsEndpoint,
        recordsLimit: 100,
        pageNo: 0,
        filterString
    });

    if (!keywordsData || !keywordsData.list) {
        return
    }

    keywordsData.list.forEach( res => {
        keywords.push({
            word: res.word,
            area: res[`${process.env.REACT_APP_SYS_LANDS_N_OBJECTS_ID}`],
            comment: res.comment,
        })
    });

    return keywords;

}

const provideBrands = async ({auth_token, controller}) => {
    let formatedData = [];


    const brandsData = await requestRowsData({
        auth_token: auth_token,
        endpoint: brandsEndpoint,
        recordsLimit: 10000,
        fields: "brand,Id",
        sortString: "brand",
        controller: controller,
    })

    if (!brandsData || !brandsData.list) {
        return
    }
    
    brandsData.list.forEach(option => {
        formatedData.push({value: option.Id, label: option.brand})
    })

    return formatedData;
}


const provideAllBrands = async ({
    auth_token, 
    currentViewFilterCondition = "",
    pageNo = 0,
    sortString,
    recordsLimit = 10000,
    filters = [],
    controller
}) => {
    let entries = [];
    let response;
    let totalCount = 0;

    let filterString = makeFilterString(filters, currentViewFilterCondition);

    response = await requestRowsData({
        auth_token,
        endpoint: brandsEndpoint,
        sortString: sortString,
        recordsLimit,
        pageNo,
        fields: `brand,Id,created_at,updated_at,journal_count,local_vehicles_count,blacklist_count`,
        filterString,
        controller
    });

    if (!response || !response.list) {
        return
    }

    totalCount = response.pageInfo.totalRows;

    response.list.forEach(entry => {
        let createdAt, updatedAt;

        if (typeof(entry?.created_at) === "string") {
            createdAt = applyTimezone(entry?.created_at, 3)
        } else {
            createdAt = "неизвестно";
        }

        if (typeof(entry?.updated_at) === "string") {
            updatedAt = applyTimezone(entry?.updated_at, 3)
        } else {
            updatedAt = "неизвестно";
        }
            
        entries.push(
            {   
                common_brand: entry?.brand,
                journal_count: entry?.journal_count, 
                local_vehicles_count: entry?.local_vehicles_count, 
                blacklist_count: entry?.blacklist_count, 
                updated_at_full: entry?.updated_at, 
                updated_at: updatedAt, 
                created_at: createdAt, 
                origin_ID: entry?.Id, // ID in database
                entered_at: entry?.entered_at ? applyTimezone(entry?.entered_at, 3) : undefined,
            }
        )

    })


    return {count: totalCount, data: entries};

}

const provideJournalEntriesCategories = () => {

    let  categoryOptions = [
        { value: "Легковой", label: "Легковой" },
        { value: "До 3.5 тонн", label: "До 3.5 тонн" },
        { value: "Более 3.5 тонн", label: "Более 3.5 тонн" },
        { value: "Длинномеры > 8 м", label: "Длинномеры > 8 м" },
        { value: "8 кубов конт.", label: "8 кубов конт." },
        { value: "20 кубов конт.", label: "20 кубов конт." },
        { value: "27 кубов конт.", label: "27 кубов конт." },
        { value: "32 кубов конт.", label: "32 кубов конт." },
        { value: "Транзит стр. техники", label: "Транзит стр. техники" },
        { value: "Пешеход", label: "Пешеход" },

    ]

    return categoryOptions;
}

const provideDeposits = async ({
    auth_token, 
    currentViewFilterCondition,
    pageNo = 0, 
    recordsLimit = 100, 
    filters = [],
    shallowQuery = true,
    sortString = "-created_at",
    controller = undefined,
}) => {
    let depositsObjects = [];
    let filterString;
    let relatedFields = 
        shallowQuery ?
            undefined
        : 
            [ { deposits_hm_journal: ['number', 'journal_bt_brands', 'Id', 'entered_at', "entry_point"] } ]
    
    filterString = makeFilterString(filters, currentViewFilterCondition);

    let response = await requestRowsData({
        auth_token: auth_token,
        endpoint: depositsEndpoint,
        recordsLimit,
        pageNo,
        filterString: filterString,
        sortString: sortString,
        fields: 'Id,deposits_bt_lands_n_objects,category,' +
            'status,limit,entered_vehicles_count,life_span_in_days,' +
            `${!shallowQuery ? "deposits_hm_journal," : ""}` +
            'live_long,comment,created_at,updated_at,last_related_update',
        relatedFields: relatedFields,
        controller,
    })

    if (!response || !response.list) {
        return
    }

    const mapDepositsData = (rawResponse, targetArray) => {
        let createdAt, updatedAt;
        let vehiclesLeft = rawResponse?.limit - parseInt(rawResponse.entered_vehicles_count);
        
        if (typeof(rawResponse?.created_at) === "string") {
            createdAt = applyTimezone(rawResponse?.created_at, 3)
        } else {
            createdAt = "неизвестно";
        }

        if (typeof(rawResponse?.last_related_update) === "string") {
            updatedAt = applyTimezone(rawResponse?.last_related_update, 3)
        } else {
            updatedAt = "неизвестно";
        }

        let expiration_date_utc_ts = Date.parse(rawResponse?.created_at) + rawResponse?.life_span_in_days * 86400000;
        let expiration_date = applyTimezone( new Date(expiration_date_utc_ts).toISOString(), 3 );

        targetArray.push({
            origin_ID: rawResponse.Id,
            dest: rawResponse?.['deposits_bt_lands_n_objects']?.area_name,
            dest_id: rawResponse?.['deposits_bt_lands_n_objects']?.Id,
            category: rawResponse?.category,
            available: vehiclesLeft,
            status: rawResponse?.status,
            limit: rawResponse?.limit,
            entered_vehicles_count: parseInt(rawResponse.entered_vehicles_count),
            entered_vehicles: rawResponse?.['deposits_hm_journal'],
            life_span_in_days: rawResponse?.life_span_in_days,
            expiration_date_utc_ts: expiration_date_utc_ts,
            expiration_date: expiration_date,
            live_long: rawResponse?.live_long,
            created_at: createdAt,
            updated_at: updatedAt,
            last_related_update: rawResponse?.last_related_update,
            comment: rawResponse?.comment
        })

    }

    for (let i = 0; i < response?.list?.length; i++) {
        mapDepositsData(response.list[i], depositsObjects);
    }


    return {count: response?.pageInfo?.totalRows, data: depositsObjects};
    
}

const provideBlacklist = async ({
    auth_token, 
    sortString = "blacklist_bt_lands_n_objects",
    currentViewFilterCondition = '(archived,eq,false)',
    recordsLimit = 100,
    controller = undefined,
}) => {
    let filterString = `where=${currentViewFilterCondition}`
    let carsObjects = [];

    let response = await requestRowsData({
        auth_token,
        endpoint: blacklistEndpoint,
        sortString: sortString,
        fields: "Id,number,comment,blacklist_bt_brands,blacklist_bt_lands_n_objects,sanction,files,updated_at,created_at",
        filterString,
        recordsLimit,
        controller,
    });

    if (!response || !response.list ) {
        return
    }

    response.list.forEach(responseObject => {

        let tempObj = {
            origin_ID: responseObject.Id,
            number: responseObject?.number,
            brand: getRidOfAliases(responseObject?.['blacklist_bt_brands']?.brand),
            relatedObject: getRidOfAliases(responseObject?.['blacklist_bt_lands_n_objects']?.area_name),
            sanction: responseObject?.sanction,
            updated_at_full: responseObject?.updated_at,
            comment: responseObject?.comment,
        }

        if (responseObject?.files) {
            // tempObj.files = JSON.parse(responseObject?.files)
            tempObj.files = responseObject?.files
        }

        carsObjects.push(tempObj)
    });
    
    
    return {count: response.pageInfo.totalRows, data: carsObjects};
}

const provideJournalEntries = async ({
    auth_token,
    currentViewFilterCondition = undefined,
    pageNo = 0,
    sortString,
    recordsLimit = 50,
    filters = [],
    quick = false,
    controller,
}) => {
    // console.log(`provideJournalEntries Called with condition ${currentViewFilterCondition}`)
    let entries = [];
    let response;
    let totalCount = 0;

    let filterString = makeFilterString(filters, currentViewFilterCondition);

    if (quick) {

        response = await requestRowsData({
            auth_token,
            endpoint: journalEndpoint,
            sortString: sortString,
            recordsLimit,
            pageNo,
            fields: "number,Id,brand_name,journal_m2m_lands_n_objects,entered_at",
            filterString,
            controller
        });

        if (!response || !response.list) {
            return
        }

        totalCount = response.pageInfo.totalRows;

        response.list.forEach(entry => {
            entries.push(
                {   
                    number: entry?.number,
                    brand: entry?.brand_name,
                    dest: entry?.journal_m2m_lands_n_objects[0]?.area_name, 
                    dest_id: entry?.journal_m2m_lands_n_objects[0]?.Id,
                    origin_ID: entry?.Id, // ID in database
                    entered_at: entry?.entered_at ? applyTimezone(entry?.entered_at, 3) : undefined,
                }
            )

        })

    } else {

        response = await requestRowsData({
            auth_token,
            endpoint: journalEndpoint,
            sortString: sortString,
            recordsLimit,
            pageNo,
            filterString,
            fields: "is_active,Id,number,category,comment,daily,credit,created_at,entered,parking,useful_photo,paid,entry_point,entered_at,updated_at,journal_m2m_lands_n_objects,journal_bt_brands,nc_xcf___deposits_id,created_by",
            relatedFields: [{
                "journal_m2m_lands_n_objects": ["Id","area_name","status"]
            }],
            controller,
        });




        if (!response || !response.list ) {
            return
        }
        
        totalCount = response.pageInfo.totalRows;

        response.list.forEach(entry => {
     
            let entryDestinations = [];
            let createdAt, updatedAt, enteredAt;
    
            if (entry?.['journal_m2m_lands_n_objects']?.length > 0 ) {

                entry['journal_m2m_lands_n_objects'].forEach(dest => {
                    entryDestinations.push({
                        id: dest.Id,
                        name: dest.area_name,
                        status: dest.status
                    })
                })

            } else {
                entryDestinations = null;
            }
            
            if (typeof(entry?.entered_at) === "string") {
                enteredAt = applyTimezone(entry?.entered_at, 3)
            } else {
                enteredAt = "";
            }
    
            if (typeof(entry?.created_at) === "string") {
                createdAt = applyTimezone(entry?.created_at, 3)
            } else {
                createdAt = "неизвестно";
            }
    
            if (typeof(entry?.updated_at) === "string") {
                updatedAt = applyTimezone(entry?.updated_at, 3)
            } else {
                updatedAt = "неизвестно";
            }
    
            
            entries.push(
                {   
                    number: entry?.number, // Категория
                    dest: entryDestinations, // Назначение
                    brand: entry?.['journal_bt_brands'], // Марка содежит id и имя бренда
                    category: entry?.category, // Категория
                    comment: entry?.comment, // Комментарий
                    credit: {type: "credit", state: entry?.credit}, // Кредит
                    daily: {type: "daily", state: entry?.daily}, // Суточный
                    entered: {type: "entered", state: entry?.entered}, // Въехал
                    paid: {type: "paid", state: entry?.paid}, // Статус оплаты доступа
                    parking_type: entry?.parking, // Статус парковки
                    // useful_photo: JSON.parse(entry?.useful_photo), // Статус парковки
                    useful_photo: entry?.useful_photo, // Статус парковки
                    created_at: createdAt, // Время создания заявки
                    updated_at: updatedAt, // Время последней редакции
                    updated_at_full: entry?.updated_at, // Время последней редакции без форматирования
                    origin_ID: entry?.Id, // ID in database
                    deposit_id: entry?.nc_xcf___deposits_id, // deposit ID in database
                    entry_point: entry?.entry_point, // КПП где зафиксирован доступ
                    entered_at: enteredAt, // время когда зафиксирован доступ
                    is_active: entry?.is_active, // Формула в NocoDB решает активна ли ещё заявка
                    created_by: entry?.created_by
                }
            )
        });

    }
    
    
    return {count: totalCount, data: entries};
    
}

const provideLocalPedestrians = async ({
    auth_token, 
    pageNo = 0, 
    recordsLimit = 50, 
    filters = [],
    currentViewFilterCondition,
    sortString = "-updated_at",
    controller = undefined,
}) => {

    let filterString = makeFilterString(filters, currentViewFilterCondition);
    let response;
    let entries = [];
    let totalCount = 0;
    
    response = await requestRowsData({
        auth_token,
        endpoint: pedestriansEndpoint,
        sortString: sortString,
        recordsLimit,
        pageNo,
        filterString,
        relatedFields: [{
            "pedestrians_m2m_lands_n_objects": ["Id","area_name","status"]
        }],
        controller,
    });

    if (!response || !response.list) {
        return
    }

    totalCount = response.pageInfo.totalRows;

    response.list.forEach(entry => {
        let createdAt, updatedAt;

        if (typeof(entry?.created_at) === "string") {
            createdAt = applyTimezone(entry?.created_at, 3)
        } else {
            createdAt = "неизвестно";
        }

        if (typeof(entry?.updated_at) === "string") {
            updatedAt = applyTimezone(entry?.updated_at, 3)
        } else {
            updatedAt = "неизвестно";
        }

        entries.push(
            {
                name: entry.name,
                comment: entry.comment,
                updated_at: updatedAt,
                updated_at_full: entry?.updated_at,
                created_at: createdAt,
                // docs: JSON.parse(entry.document),
                docs: entry.document,
                dest: entry.pedestrians_m2m_lands_n_objects,
                origin_ID: entry.Id,
                archived: entry.archived,
            }
        );

    });


    return {count: totalCount, data: entries};

}

const provideLocalVehicles = async ({
    auth_token,
    pageNo = 0, 
    recordsLimit = 50, 
    filters = [],
    currentViewFilterCondition,
    sortString = "-updated_at",
    controller = undefined,
}) => {

    let filterString = makeFilterString(filters, currentViewFilterCondition);
    let totalCount = 0;
    let response;
    let entries = [];

    response = await requestRowsData({
        auth_token,
        endpoint: vehiclesEndpoint,
        recordsLimit,
        pageNo,
        sortString,
        filterString,
        relatedFields: [{
            "local_vehicles_bt_lands_n_objects": ["Id","area_name","status"]
        }],
        controller,
    });

    if (!response || !response.list ) {
        return
    }
    
    totalCount = response.pageInfo.totalRows;

    response.list.forEach(entry => {
        
        const applyMscTimezone = (entry) => {
            let createdAt, updatedAt;

            if (typeof(entry?.created_at) === "string") {
                createdAt = applyTimezone(entry?.created_at, 3)
            } else {
                createdAt = "неизвестно";
            }
    
            if (typeof(entry?.updated_at) === "string") {
                updatedAt = applyTimezone(entry?.updated_at, 3)
            } else {
                updatedAt = "неизвестно";
            }

            return [createdAt, updatedAt]
        }

        let [createdAt, updatedAt] = applyMscTimezone(entry);


        entries.push(
            {
                dest: entry.local_vehicles_bt_lands_n_objects,
                number: entry.number,
                brand: entry.local_vehicles_bt_brands,
                comment: entry.comment,
                // docs: JSON.parse(entry.document),
                docs: entry.document,
                mark: entry.emoji_mark,
                updated_at: updatedAt,
                updated_at_full: entry?.updated_at,
                created_at: createdAt,
                archived: entry.archived,
                origin_ID: entry.Id,
                id_issue: entry.id_issue,
            }
        );
    });


    return {count: totalCount, data: entries};
  
}


const provideContacts = async ({
    auth_token,
    pageNo = 0, 
    recordsLimit = 50, 
    filters = [],
    currentViewFilterCondition,
    sortString = "-updated_at",
    controller = undefined,
}) => {

    let filterString = makeFilterString(filters, currentViewFilterCondition);
    let totalCount = 0;
    let response;
    let entries = [];

    response = await requestRowsData({
        auth_token,
        endpoint: contactsEndpoint,
        recordsLimit,
        pageNo,
        sortString,
        filterString,
        relatedFields: [{
            "contacts_mm_objects": ["Id","area_name","status"]
        }],
        controller,
    });

    if (!response || !response.list ) {
        return
    }
    
    totalCount = response.pageInfo.totalRows;

    response.list.forEach(entry => {
        
        const applyMscTimezone = (entry) => {
            let createdAt, updatedAt;

            if (typeof(entry?.created_at) === "string") {
                createdAt = applyTimezone(entry?.created_at, 3)
            } else {
                createdAt = "неизвестно";
            }
    
            if (typeof(entry?.updated_at) === "string") {
                updatedAt = applyTimezone(entry?.updated_at, 3)
            } else {
                updatedAt = "неизвестно";
            }

            return [createdAt, updatedAt]
        }

        let [createdAt, updatedAt] = applyMscTimezone(entry);
        let powersStatus = "Ошибка, сообщите администратору";

        if (entry?.days_before_powers_expiration === null) {
            powersStatus = "Нет доверенности";
        } else if (entry?.days_before_powers_expiration?.hours < 0) {
            powersStatus = "Доверенность просрочена";
        } else if (entry?.days_before_powers_expiration?.days > 0 && entry?.days_before_powers_expiration?.days < 46 ) {
            let num = entry?.days_before_powers_expiration?.days;
            powersStatus = `Доверенность истекает через ${num} дней`;
        } else if (entry?.days_before_powers_expiration?.days > 45) {
            powersStatus = "Действительная доверенность";

        }
        // console.log("entry?.days_before_powers_expiration, ", entry?.days_before_powers_expiration)

        entries.push(
            {
                object: entry?.contacts_mm_objects,
                name: entry?.name,
                position: entry?.position,
                comment: entry?.comment,
                phone: entry?.phone,
                email: entry?.email,
                birth_date: entry?.birth_date,
                power_of_attorney_expiration: entry?.power_of_attorney_expiration,
                days_before_powers_expiration: entry?.days_before_powers_expiration,
                powersStatus: powersStatus,
                related_owner_notion_id: entry?.related_owner_notion_id,
                updated_at: updatedAt,
                updated_at_full: entry?.updated_at,
                created_at: createdAt,
                archived: entry.archived,
                origin_ID: entry.Id,
            }
        );
    });


    return {count: totalCount, data: entries};
  
}


const provideSelectOptions = async ({userData, source, column_id}) => {
    let tempOptions = []; // { value: "К выпуску 2023", label: "К выпуску 2023" },
    let localStorageName = `${source}_${column_id}_options`;
    let knownOptions = JSON.parse(window.localStorage.getItem(localStorageName));
    
    if (Date.now() - knownOptions?.dateOfInsertion < 1000 * 60 * 60) {
        // console.log("options are fresh enough", (Date.now() - knownOptions?.dateOfInsertion ) / 1000 , "seconds")
        return knownOptions.arrayOfOptions;
    } else {
        console.log(`options for ${column_id} column in ${source} source are about to be queried`)
    }

    let columnData = await getColumnData({userData, source, column_id})
        
    if (columnData?.[0]?.colOptions?.options?.length > 0) {
        
        for (let opt of columnData?.[0]?.colOptions?.options) {
            tempOptions.push({ value: opt?.title, label: opt?.title })
        }
        
    }

    window.localStorage.setItem(localStorageName, JSON.stringify({
        arrayOfOptions: tempOptions,
        dateOfInsertion: Date.now()
    }))
   
    
    return tempOptions;
    
}


export {
    provideAreas,
    provideKeywords,
    provideBrands,
    provideAllBrands,
    provideJournalEntriesCategories,
    provideBlacklist,
    provideDeposits,
    provideJournalEntries,
    provideLocalPedestrians,
    provideLocalVehicles,
    provideContacts,
    provideSelectOptions,
}