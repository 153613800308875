import React from 'react';
import UserService from "./DirectusUserService";
import { allRoles } from "../App";
import LoadingIcon from '../components/commonUI/LoadingIcon';

interface RenderOnRoleProps {
  roles: readonly typeof allRoles[number][];
  fallbackElement?: React.ReactElement | null;
  children: React.ReactElement;
}

const RenderOnRole = ({ roles, fallbackElement = null, children }: RenderOnRoleProps): React.ReactElement => {
  const [hasRequiredRole, setHasRequiredRole] = React.useState(null);
  
  
  React.useEffect(() => {
    const checkRole = async () => {
      const result = await UserService.hasRole(roles);
      setHasRequiredRole(result);
    };
    checkRole();
  }, [roles]);

  return (
    <>
      {
        hasRequiredRole === null ?
        null
        :
        hasRequiredRole === true ?
        children
        :
        fallbackElement
      }
    </>
  );
}

export default RenderOnRole;
