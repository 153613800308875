import { Query, QueryFields, QueryFilter, readItems } from "@directus/sdk";
import { Collections, Schema } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";

export type ProvideAreasOptions = {
  fields?: QueryFields<Schema, Collections.LandsNObjects>,
  currentViewFilterCondition?: QueryFilter<Schema, Collections.LandsNObjects>,
  controller?: AbortController,
}

export type AreaFormatted = {
  value: number,
  label: string,
  area_status?: string,
}

export type AreasFormatted = Array<AreaFormatted>;

export const provideAreas = async ({
  fields = ["id", "area_name", "status"],
  controller,
  currentViewFilterCondition
}: ProvideAreasOptions) => {
  const areasFormatted: AreasFormatted = [];
  const client = getDirectusClient();
  
  // console.log("provideAreas called with, ", currentViewFilterCondition)
  
  const request: Query<Schema, Collections.LandsNObjects> = {
    limit: 1000,
    offset: 0,
    sort: "area_name" as const,
    fields,
    // @ts-expect-error directus SDK's type is missing abort signal
    signal: controller.signal,
  }

  if (currentViewFilterCondition) {
    request.filter = currentViewFilterCondition;
  }
  
  const response = await client.request(readItems("lands_n_objects", request));

  response.forEach(element => {
    const objectToPush: AreaFormatted = {
      value: element.id,
      label: element.area_name,
    }

    if (fields.includes("status")) {
      objectToPush.area_status = element.status.join(", ");
    }

    areasFormatted.push(
      objectToPush
    )

  });

  return areasFormatted;
}
