import { useState, useRef, useCallback, useEffect } from 'react';
// import { provideBrands } from './DataProviders/provideBrands';
import { provideLocalPedestrians } from './DataProviders/providePedestrians';
import { provideLocalVehicles } from './DataProviders/provideLocalVehicles';
import { provideJournalEntries,
    provideDeposits, 
    provideBlacklist, 
    // provideLocalPedestrians,
    // provideLocalVehicles,
    provideBrands,
    // provideContacts,
} from "./ProvideDataObjects";
import { provideContacts } from './DataProviders/provideContacts';
import { confirmDirectusDataWasUpdated } from "./DirectusServices/confirmDirectusDataWasUpdated"
import { confirmDataWasUpdated } from "./CRUDJournalData";


const getCurrentViewType = ({shallowQuery, showOnlyActiveRef}) => {
    let viewType = `initialViewData${!shallowQuery ? "Extended" : ""}`;
    if (!showOnlyActiveRef.current) {
        viewType = `altViewData${!shallowQuery ? "Extended" : ""}`;
    }

    return viewType;
}

const getCurrentConfig = (
    {
        pageIndex, 
        recordsPerPage,
        filters, 
        viewType, 
        viewCondition,
    }
) => {
    let config = {};

    config[`${viewType}`] = {
        pageIndex, 
        recordsPerPage,
        filters, 
        viewCondition,
    }


    return JSON.stringify(config);

}

const clearIntervals = (arrayWithIDs = [], intervalsRef) => {

    /**
     * TODO:
     * this looks horrible... I need to rework this.
     */
    
    arrayWithIDs.forEach(id => {
        !!id && clearInterval(id);
    });

    intervalsRef.current = [];
}



const useCachedDataWithUpdates = ({
    location,
    disabled = false,
    freeze = false,
    freezeRef,
    dataSource = "deposits",
    updateFrequency = 15000,
    cachedDataLifeSpan = updateFrequency * 2,
    PAGE_NO = 0,
    initialShowOnlyActiveState = true,
    showOnlyActive = initialShowOnlyActiveState,
    shallowQuery = true,
    onViewSwitchFilterCondition = '(status,eq,Действителен)',
    offViewSwitchFilterCondition = undefined,
    dependency,
    localFieldToCompareBy = "updated_at",
    remoteFieldToCompareBy = localFieldToCompareBy,
    sortString = "-updated_at",
    altSortString = "-updated_at",
    nocodb_auth,
    pageIndex,
    setTotalPages, // = () => {},
    gotoPage, // = () => {},
    recordsPerPage,
    filters,
    setTableData, // = () => {},
    initialStateObject,
    editedEntryID,
    hiddenColumns,
    loggingEnabled = false,
    forceReloadRef,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hookRerun, setHookRerun] = useState(true);
    const [isToggleDisabled, setToggleDisabled] = useState(false);
    const showOnlyActiveRef = useRef(initialShowOnlyActiveState);
    const filtersRef = useRef(filters);
    const previousView = useRef(initialShowOnlyActiveState);
    const tableDataRef = useRef([]);
    const abortControllerRef = useRef(undefined);
    const intervalID = useRef([]);
    const totalEntries = useRef(0);
    const deletingRowsRef = useRef([]);
    const justAlteredRowsRef = useRef([]);
    const requestDataRuns = useRef(false);
    const forceQueryRef = useRef(false);
    const firstRender = useRef(true);
    const sourceFetchingTimerRef = useRef(undefined);
    const dataFetchingWentFine = useRef(true);
    const configRef = useRef(
        getCurrentConfig({
            pageIndex, 
            recordsPerPage,
            filters, 
            viewType: getCurrentViewType({shallowQuery, showOnlyActiveRef}),
        })
    );
    let provideData; 
    let checkForChanges = confirmDataWasUpdated;

    if (dataSource === "journal" || dataSource === "debt" || dataSource === "reports" || dataSource === 'dezhJournal') {
        provideData = provideJournalEntries;
    } else if (dataSource === "blacklist") {
        provideData = provideBlacklist;
    } else if (dataSource === "deposits") {
        provideData = provideDeposits;
    } else if (dataSource === "localVehicles") {
        provideData = provideLocalVehicles;
        checkForChanges = confirmDirectusDataWasUpdated; 
    } else if (dataSource === "localPedestrians") {
        provideData = provideLocalPedestrians;
        checkForChanges = confirmDirectusDataWasUpdated; 
    } else if (dataSource === "allBrands") {
        provideData = provideBrands;
        checkForChanges = confirmDirectusDataWasUpdated; 
    } else if (dataSource === "contacts") {
        provideData = provideContacts;
        checkForChanges = confirmDirectusDataWasUpdated; 
    }


    const returnCleanUp = useCallback(() => {
        abortControllerRef.current?.abort();
        clearIntervals(intervalID.current, intervalID);
        previousView.current = showOnlyActive;
        abortControllerRef.current = undefined;
        firstRender.current = true;
        requestDataRuns.current = false;
        loggingEnabled && console.log(`%c${dataSource} subscription%c removed`, "color:gray", "color:LightBlue")
    }, [
        clearIntervals,
        intervalID,
        showOnlyActive,
        loggingEnabled,
        dataSource
    ])

    const updateLocalStorage = useCallback(({ data, storedData }) => {
        let viewType = getCurrentViewType({shallowQuery, showOnlyActiveRef});
        
        if (!storedData) {
            storedData = {}
        } 
    
        if (data) {
            storedData[`${viewType}`] = {
                data,
                config: configRef.current,
                injectionTime: Date.now(),
            }
        } else if (storedData[`${viewType}`]) {
            storedData[`${viewType}`].injectionTime = Date.now();            
        } else {
            storedData[`${viewType}`] = {
                injectionTime: Date.now()
            }            
        }
    
        window.localStorage.setItem(`${dataSource}Data`, JSON.stringify(storedData));

    }, [dataSource, shallowQuery, showOnlyActiveRef])

    
    const provideDataCallback = useCallback(async ({
        nocodb_auth,
        showOnlyActiveRef,
        pageNo,
        recordsPerPage,
        filtersRef,
        controller,
        forceQuery = false,
    }) => {
        let data = initialStateObject;
        
        if (loggingEnabled) {

            let callTimeString = new Date().toLocaleString('ru-Ru');
            sourceFetchingTimerRef.current = `${callTimeString} -- fetching ${dataSource}`;
            console.time(sourceFetchingTimerRef.current)

        }

        controller?.signal.addEventListener('abort', () => {
            loggingEnabled && console.log(`%cprovideDataCallback on ${dataSource} aborted%c`, "color:gray", "color:initial")

            !!sourceFetchingTimerRef.current && console.timeEnd(sourceFetchingTimerRef.current);
            sourceFetchingTimerRef.current = undefined;
    
            previousView.current = showOnlyActiveRef.current;

            return undefined;
        });
            
        
        try {
            let storedData = JSON.parse(window.localStorage.getItem(`${dataSource}Data`));
            let viewType = getCurrentViewType({shallowQuery, showOnlyActiveRef});

            if (previousView.current !== showOnlyActiveRef.current) {
                gotoPage && gotoPage(PAGE_NO);
            }


            if (
                !forceQuery 
                    && 
                configRef.current.includes(storedData?.[`${viewType}`]?.config?.replace(/^\{/,"")?.replace(/\}$/,""))
                    && 
                storedData?.[`${viewType}`]?.injectionTime + cachedDataLifeSpan >= Date.now()
            ) {
                
                data = storedData[`${viewType}`].data;
                loggingEnabled && console.log(`%cusing previously stored ${dataSource} data`, "color:LawnGreen", /* "color:initial", "viewType:",viewType, "storedData?.[`${viewType}`]", storedData?.[`${viewType}`] */)

            } else {

                loggingEnabled && console.log(`-- fetching fresh ${dataSource} data...`)
                

                // loggingEnabled && console.log(`-- provideData on ${dataSource} data called with:`,
                //     {
                //         auth_token: nocodb_auth,
                //         currentViewFilterCondition: showOnlyActiveRef.current ? onViewSwitchFilterCondition : offViewSwitchFilterCondition,
                //         pageNo: previousView.current === showOnlyActiveRef.current ? pageNo : PAGE_NO,
                //         recordsLimit: recordsPerPage,
                //         filters: filtersRef.current,
                //         shallowQuery,
                //         sortString: showOnlyActiveRef.current ? sortString : altSortString,
                //         controller: controller,
                //     },
                //     "\nshowOnlyActiveRef.current", showOnlyActiveRef.current
                // )

                data = await provideData({
                    auth_token: nocodb_auth,
                    currentViewFilterCondition: showOnlyActiveRef.current ? onViewSwitchFilterCondition : offViewSwitchFilterCondition,
                    pageNo: previousView.current === showOnlyActiveRef.current ? pageNo : PAGE_NO,
                    recordsLimit: recordsPerPage,
                    filters: filtersRef.current,
                    shallowQuery,
                    sortString: showOnlyActiveRef.current ? sortString : altSortString,
                    controller: controller,
                });

            }

        } catch (e) {
            console.log(`! no data for ${dataSource} provided because error below:`)
            console.log("error", e)           
        }

        
        !!sourceFetchingTimerRef.current && console.timeEnd(sourceFetchingTimerRef.current);
        sourceFetchingTimerRef.current = undefined;

        previousView.current = showOnlyActiveRef.current;

        loggingEnabled && !controller?.signal?.aborted && console.log(
            `~~~ got data on ${dataSource}: \n`, 
            data,
        )

        if (data) {
            let storedData = JSON.parse(window.localStorage.getItem(`${dataSource}Data`));
            updateLocalStorage({data, storedData});
        }

        return data


    }, [
        provideData,
        PAGE_NO,
        altSortString,
        cachedDataLifeSpan,
        dataSource,
        // gotoPage, // provided by library and is recreated on every render
        initialStateObject,
        loggingEnabled,
        onViewSwitchFilterCondition,
        offViewSwitchFilterCondition,
        shallowQuery,
        sortString,
        updateLocalStorage,
    ])

    const updateStores = useCallback(async (count, data, recordsPerPage, ok = true,) => {
        
        const tbodyElement = document.getElementById(`${dataSource}-table-body`)
        let isTableBodyFocused = tbodyElement?.contains(document.activeElement);
        
        if (deletingRowsRef.current.length !== 0 || freeze || freezeRef?.current || isTableBodyFocused) {
            
            loggingEnabled && console.log(`values for ${dataSource} won't be set, some %c'freeze' condition met%c`, "color:initial", "color:LawnGreen", )
            
            setIsLoading(false)            
            
        } else {
            loggingEnabled && console.log(`%cvalues for ${dataSource} set%c`, "color:LawnGreen", "color:initial", /* data, "ok?", ok */ )
            totalEntries.current = count;
            tableDataRef.current = data;
            setTableData && setTableData(data)
            setTotalPages && setTotalPages(Math.ceil(Number(totalEntries.current) / Number(recordsPerPage)));
            ok && setIsLoading(false);
        }

    }, [
        freeze,
        dataSource,
        deletingRowsRef.current,
        loggingEnabled,
        setTableData,
        setTotalPages,
        setIsLoading,
        recordsPerPage
    ])

    useEffect(() => {
        loggingEnabled && console.log(`%cuseCachedDataWithUpdates runs on ${dataSource}`, "color:MediumPurple",)
        
        if (disabled) {
            
            loggingEnabled && console.log(`useCachedDataWithUpdates hook on ${dataSource} is disabled`)
            return;

        }

        setIsLoading(true);
        showOnlyActiveRef.current = showOnlyActive;
        filtersRef.current = filters;
        abortControllerRef.current = new AbortController();
        

        let freshConfig = getCurrentConfig({
            pageIndex, 
            recordsPerPage,
            filters: filtersRef.current, 
            viewType: getCurrentViewType({shallowQuery, showOnlyActiveRef}),
            viewCondition: showOnlyActiveRef ? onViewSwitchFilterCondition : offViewSwitchFilterCondition,
        })

        if (firstRender.current === false && freshConfig !== configRef.current) {
            forceQueryRef.current = true;
        }

        configRef.current = freshConfig;

        
        // console.log("useCachedDataWithUpdates runs with forceQueryRef.current" , forceQueryRef.current,
        // "\nonViewSwitchFilterCondition", onViewSwitchFilterCondition,
        // "\nfreshConfig", freshConfig,
        // "\nfreshConfig === configRef.current", freshConfig === configRef.current)
        
        
        if ( firstRender.current === true || forceQueryRef.current ) {

            const handleData = async (data, logMessage, critical = false) => {
                if (!!data && data !== initialStateObject) {
                    
                    let copiedData = JSON.parse(JSON.stringify(data))
                    await updateStores(copiedData.count, copiedData.data, recordsPerPage);
                    dataFetchingWentFine.current = true;
        
                } else {
        
                    if (!tableDataRef.current || tableDataRef.current === initialStateObject) {
                        setIsLoading(true);
                    }
        
                    if (!abortControllerRef.current?.signal?.aborted) {
                        loggingEnabled && logMessage && 
                        console.log(logMessage, `color:${critical ? "LightCoral" : "gray"}`, `color:${critical ? "initial" : "LightCoral"}` )
                        dataFetchingWentFine.current = false;
                    }
                    
                }
            }

            (async () => {

                requestDataRuns.current = true;

                let data = await provideDataCallback({
                    nocodb_auth, 
                    showOnlyActiveRef,
                    filtersRef,
                    pageNo: pageIndex,
                    recordsPerPage,
                    controller: abortControllerRef.current,
                    forceQuery: forceQueryRef.current,
                });
                await handleData(data, `${dataSource} recieved %cno data on initial call%c`, true);
                forceQueryRef.current = false;
                requestDataRuns.current = false;

                
                loggingEnabled && console.log(`%c${dataSource} subscription%c set`, "color:gray", "color:LawnGreen")
                let id = window.setInterval(async () => {

                    if (location !== window.location.pathname) {
                        
                        loggingEnabled && console.log(`%c${dataSource} subsription cleaned up after location change%c`, "color:gray")
                        return returnCleanUp();
                    }
                    
                    if ( requestDataRuns.current === true ) {
                        loggingEnabled && console.log(`%c${dataSource} subscription%c skipped cycle to avoid overlap`, "color:gray", "color:LightCoral")
                        return
                    }
        
                    loggingEnabled && console.log(`%c${dataSource} subscription%c executes`, "color:gray", "color:Gold")
                    
                    if (justAlteredRowsRef.current?.length > 0 || forceReloadRef?.current === true) {
                        
                        loggingEnabled && console.log(`%c${dataSource} subscription%cis aware of altered data`, "color:gray")
                                                
                        requestDataRuns.current = true;
                        let data = await provideDataCallback({
                            nocodb_auth, 
                            showOnlyActiveRef, 
                            pageNo: pageIndex, 
                            recordsPerPage,
                            filtersRef,
                            controller: abortControllerRef.current,
                            forceQuery: true,
                        });

                        await handleData(data, `%c${dataSource} subscription%c recieved no data`);
                        justAlteredRowsRef.current = [];

                        if (forceReloadRef?.current !== undefined) {
                            forceReloadRef.current = false;
                        }

                        requestDataRuns.current = false;
        
                    } else {

                        let dataStatus;

                        if (dataFetchingWentFine.current === true) {

                            loggingEnabled && console.time(`quering if ${dataSource} data was updated`)
            
                            requestDataRuns.current = true;
                            dataStatus = await checkForChanges({
                                nocodb_auth: nocodb_auth, 
                                source: dataSource, 
                                fieldToCompareBy: remoteFieldToCompareBy,
                                sortBy: showOnlyActiveRef.current ? sortString : altSortString,
                                lastKnownUpdateTimeString: tableDataRef.current?.[0]?.[`${localFieldToCompareBy}`],
                                knownTotalCount: totalEntries.current,
                                filters: filters,
                                currentViewFilterCondition: showOnlyActiveRef.current ? onViewSwitchFilterCondition : offViewSwitchFilterCondition,
                                recordsLimit: recordsPerPage,
                                pageIndex,
                                controller: abortControllerRef.current,
                                timeout: 160000,
                            })

                            loggingEnabled && console.timeEnd(`quering if ${dataSource} data was updated`)

                        }
                        
                        if (dataStatus?.fresh === true || dataFetchingWentFine.current === false) {
                            
                            loggingEnabled && console.log(`%c${dataSource} subscription%c found stale data`, "color:gray", "color:DarkViolet")
                            requestDataRuns.current = true;
                            
                            let data = await provideDataCallback({
                                nocodb_auth, 
                                showOnlyActiveRef,
                                pageNo: pageIndex,
                                recordsPerPage, 
                                filtersRef,
                                controller: abortControllerRef.current,
                                forceQuery: true,
                            });

                            await handleData(data, `%c${dataSource} subscription%c recieved no data :(`);
            
                        } else if (dataStatus?.fresh === false ) {

                            let storedData = JSON.parse(window.localStorage.getItem(`${dataSource}Data`));
                            updateLocalStorage({storedData})
                            
                            loggingEnabled && console.log(`%c${dataSource} subscription%c found no updates and %cprolonged previously stored data%c lifetime`, "color:gray", "color:initial", "color:LawnGreen", "color:initial")
        
                        } else {
                            setIsLoading(true);
                            setHookRerun(hookRerun => !hookRerun);
                        }
                        requestDataRuns.current = false;
        
                    }
                    
        
                }, updateFrequency)

                if (intervalID.current.length > 0) {
                    clearIntervals(intervalID.current, intervalID);
                    intervalID.current.push(id);
                } else {
                    intervalID.current.push(id);
                }

                previousView.current = showOnlyActive;
                firstRender.current = false;

            })();
            
        }

        
        return () => { 
            loggingEnabled && console.log(`%cuseCachedDataWithUpdates on ${dataSource} returns CleanUp`, "color:MediumPurple",)
            returnCleanUp() 
        };

    }, [
        dependency,
        location,
        freeze,
        disabled,
        hiddenColumns?.length,
        initialStateObject,
        altSortString,
        dataSource,
        localFieldToCompareBy,
        nocodb_auth,
        onViewSwitchFilterCondition,
        offViewSwitchFilterCondition,
        remoteFieldToCompareBy,
        shallowQuery,
        sortString,
        updateFrequency,
        updateLocalStorage,
        loggingEnabled, 
        pageIndex, 
        recordsPerPage, 
        provideDataCallback,
        filters, 
        showOnlyActive, 
        editedEntryID,
        hookRerun,
        setTableData,
    ]);

    // console.log(
    //   "useCachedDataWithUpdates returns:\n",
    //   "isLoading? ", isLoading,
    //   tableDataRef,
    //   totalEntries,
    // )

    return [
        isLoading,
        tableDataRef,
        justAlteredRowsRef,
        totalEntries,
        deletingRowsRef,
        forceQueryRef,
        isToggleDisabled, 
    ]

}


export default useCachedDataWithUpdates;
