import React, { useState, useRef, useContext } from 'react';
import { matchSorter } from "match-sorter";
import Select, { createFilter } from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';


import { MainContext } from "../MainContext";
import { createDeposit } from "../../services/CRUDJournalData";
import useSelectOptions from "../../services/useSelectOptions"
import LoadingIcon from "../commonUI/LoadingIcon";


const  categoryOptions = [
    { value: "Легковой", label: "Легковой" },
    { value: "До 3.5 тонн", label: "До 3.5 тонн" },
    { value: "Более 3.5 тонн", label: "Более 3.5 тонн" },
    { value: "Длинномеры > 8 м", label: "Длинномеры > 8 м" },
    { value: "8 кубов конт.", label: "8 кубов конт." },
    { value: "20 кубов конт.", label: "20 кубов конт." },
    { value: "27 кубов конт.", label: "27 кубов конт." },
    { value: "32 кубов конт.", label: "32 кубов конт." },
    { value: "Транзит стр. техники", label: "Транзит стр. техники" },
]

const reasonableFilterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.label}`,
    trim: true,
}

const AddDepositForm = () => {
    const { siteSettings, userData } = useContext(MainContext);
    const [formState, setFormState] = useState('readyForInput');
    // ! formState can be:
    // readyForInput
    // sendingData
    // success
    // errorOnSubmit
    const errorData = useRef();

    const [
        areOptionsLoading,
        allDestinationOptions,
        destinationOptions,
        setDestinationOptions,
    ] = useSelectOptions({
        siteSettings,
        nocodb_auth: userData.nocodb_auth,
        optionName: "destinationOptions",
        // currentViewFilterCondition: "(status,like,Постоплата)~not(status,like,Только по депозитам)"
    })

    const {
        control,
        register,
        handleSubmit,
        reset,
        // getValues,
        // setValue,
        formState: { errors },
    } = useForm({
        criteriaMode: "all",
    });

    const onDestinationsInputChange = (value, {action}) => {

        if (action === 'input-change') {
            let filteredOptions = matchSorter(destinationOptions, value, { keys: ['label']});
            setDestinationOptions(filteredOptions);

            if (value === "" || value === []) {
                setDestinationOptions(allDestinationOptions.current);
            }

        } else if (action === 'menu-close') {
            setDestinationOptions(allDestinationOptions.current);   
        }

    }

    const onError = () => { 
        window.scrollTo(0, 0)
    }

    // const moveFormToInitialState = () => {

    //     if (formState !== "readyForInput") {
    //         reset()
    //     }

    //     setFormState("readyForInput");

    // }

    const saveFormData = async (data) => {
        window.scrollTo(0, 0);
        const response = await createDeposit(userData, data);

        if (response?.status !== 200) {
            let responseMessage;

            try {
                responseMessage = await response?.text();
            } catch (error) {
                throw new Error(`${response}`)
            }

            throw new Error(`${response.status} | ${response.statusText} | ${responseMessage}`); 
        
        } else {

            let timeout_id = window.setTimeout(() => {
                setFormState("success")
                clearTimeout(timeout_id);
            }, 450)

        }

    }

    const onSubmit = async (data) => {

        try {
            setFormState("sendingData");
            await saveFormData(data);      
        } catch (e) {
            errorData.current = e;
            setFormState("errorOnSubmit");
        }

    }

    return (
        <div id="add-deposit-wrapper" className={`${formState === "sendingData" ? "loading" : ""}`}>
            <LoadingIcon addClassName={`${formState === "sendingData" ? "" : "invisible"}`} />
            {   formState === "success" &&
                <div id="deposit-success-container">
                    <p>Депозит успешно доставлен</p>
                    <button 
                        className='button repeat' 
                        onClick={() => {reset(); setFormState("readyForInput");} }>
                        Отправить ещё
                    </button>
                </div>
            }
            <form 
                onSubmit={handleSubmit(onSubmit, onError)} 
                id="add-deposit-form" 
                className={`${formState === "readyForInput" ? "visible" : ""}`} 
            >
                <div className="boxProperty">    
                    <div className="propertyContainer">
                            <label htmlFor={`destination`}>Пункт назначения</label> 
                            <Controller
                                control={control}
                                name={`dep-destination`}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Select
                                        key={`destination`}
                                        id={`destination`}
                                        ref={ref}
                                        onChange={(e) => { onChange(e) } }
                                        onBlur={ (e) => { onBlur(e) } }
                                        options={destinationOptions}
                                        isLoading={areOptionsLoading}
                                        loadingMessage={() => {return "Идёт загрузка..."}}
                                        noOptionsMessage={() => {return "Нет опций"}}
                                        placeholder=""
                                        isSearchable={true}
                                        filterOption={createFilter(reasonableFilterConfig)}
                                        onInputChange={onDestinationsInputChange}
                                        value={value}
                                        classNamePrefix="select"
                                        defaultValue={undefined}
                                    />
                                )}
                                rules={{ required: "Выберите из списка" }}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`dep-destination`}
                                render={({message}) => <span className="errorMessage">{message}</span>}
                            />
                    </div>
                </div>
                <div className="boxProperty">
                    <div className="propertyContainer">
                        <label htmlFor={`dep-category`}>Категория транспорта</label> 
                        <Controller
                            control={control}
                            name={`dep-category`}
                            defaultValue=""
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    id={`dep-category`}
                                    ref={ref}
                                    onChange={(e) => { onChange(e); }}
                                    onBlur={onBlur}
                                    options={categoryOptions}
                                    noOptionsMessage={() => {return "Нет опций"}}
                                    filterOption={createFilter(reasonableFilterConfig)}
                                    placeholder=""
                                    isSearchable={true}
                                    value={value}
                                    classNamePrefix="select"
                                />
                            )}
                            rules={{ required: "Выберите из списка" }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name={`dep-category`}
                            render={({message}) => <span className="errorMessage">{message}</span>}
                        />
                    </div>
                </div>
                <div className="boxProperty">
                    <div className="propertyContainer">
                        <label htmlFor={`dep-comment`}>Комментарий для внимания охраны</label> 
                        <textarea
                            autoComplete="off"
                            className="classyInput"
                            id={`dep-comment`}
                            {...register(`dep-comment`)}
                        />
                    </div>
                </div>
                <div className="boxProperty">
                    <div className="propertyContainer">
                        <label htmlFor={`dep-limit`}>Сколько машин допустить</label> 
                        <Controller
                            control={control}
                            name={`dep-limit`}
                            defaultValue=""
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <input
                                    ref={ref}
                                    onBlur={onBlur}
                                    onChange={(e) => { onChange(e); }}
                                    value={value}
                                    id={`dep-limit`}
                                    autoComplete="off"
                                    className="classyInput"
                                    type={"number"}
                                />
                            )}
                            rules={{ required: "Необходимо число" }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name={`dep-limit`}
                            render={({message}) => <span className="errorMessage">{message}</span>}
                        />
                    </div>
                </div>
                <div id="submit-form-container" className={`${formState === "success" || formState === "readyForInput" ? "" : "invisible"}`}>
                    <button 
                        className="button submit" 
                        id="main-submit" 
                        type="submit"
                    >
                        Отправить
                    </button>
                </div>
            </form>
            <div id="error-on-submit-container" className={`${formState === "errorOnSubmit" ? "visible" : ""}`}>
                <h1>При отправке произошла ошибка</h1>
                <div className="appeal">
                    <p>Пожалуйста, свяжитесь с <a href="https://t.me/RomanSergeev" target="_blank" rel="noreferrer">@Романом</a> и сообщите ему информацию об ошибке:</p>
                    { errorData.current?.status && errorData.current?.statusText ?
                        <>
                            <p>Код ошибки: <code>{errorData.current?.status}</code></p>
                            <p>Текст ошибки: <code>{errorData.current?.statusText}</code></p>
                        </>
                    :
                        <>
                            <p>Текст ошибки: <code>{errorData.current?.name} | {errorData.current?.message}</code></p>
                        </>
                    }
                    <button className="button retry" onClick={() => setFormState("readyForInput")}>Попробовать ещё раз</button>
                </div>
            </div>
        </div>

    );
}

export default AddDepositForm;