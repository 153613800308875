import React, { useContext, useEffect, useRef } from 'react';

import Modal from 'react-modal';
import { MainContext } from '../MainContext';
import ExitModalButton from './ExitModalButton';
import useCurrentSizes from "../../services/useCurrentSizes"

Modal.setAppElement(`#root`);

const ModalDialog = ({ children, isOpen, exitFunction }) => {
    const { siteSettings } = useContext(MainContext);
    const [viewportWidth, viewportHeight] = useCurrentSizes();
    const contentContainer = useRef();


    useEffect(() => {
        let mq_mobile = window.matchMedia('(max-width: 700px)');
        
        if (mq_mobile.matches) {
            if (isOpen) {
                document.getElementsByTagName('body')[0].style.position = 'fixed';
            }
            
            return () => {
                // God bless clean-up functions!
                document.getElementsByTagName('body')[0].style.position = 'initial';
            }
        }
        
    }, [isOpen]);

    useEffect(() => {
        let intervalId = window.setInterval(() => {
            
            if (contentContainer.current) {

                if (contentContainer.current.scrollWidth <= viewportWidth) {
                    contentContainer.current.style.setProperty("min-width", contentContainer.current.scrollWidth + "px")
                } else {
                    contentContainer.current.style.setProperty("min-width", viewportWidth + "px")
                }

                if ( contentContainer.current.scrollHeight <= viewportHeight) {
                    contentContainer.current.style.setProperty("min-height", contentContainer.current.scrollHeight + "px")
                } else {
                    contentContainer.current.style.setProperty("min-height", viewportHeight + "px")
                }


                clearInterval(intervalId)
            }

        }, 100)

        return () => clearInterval(intervalId)

    }, [children, contentContainer.current])

    const onExit = () => {
        document.getElementsByTagName('body')[0].style.position = 'initial';
        exitFunction()
    }


    return (
        <Modal 
            isOpen={isOpen}
            onRequestClose={onExit}
            shouldCloseOnOverlayClick={true}
            className="Modal"
            overlayClassName="Overlay"
            preventScroll={true}
            contentRef={node => (contentContainer.current = node)}
        >   
            <ExitModalButton closeFunction={onExit} title={siteSettings.tooltips && "Отмена `Esc`"} />
            { children }
        </Modal>
    );
}

export default ModalDialog;
