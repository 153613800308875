import React, { useState, useContext, useEffect, useRef } from 'react';
import { MainContext } from '../MainContext';
import LoadingIcon from './LoadingIcon';

const Spoiler = ({
    header, contentID, addClassName = "",
    defaultCollapsed = true, isDataLoaded = true,
    children, itemsCount = children?.length, showCount = true,
    shouldRenderCollapsed = false}) => {
        
    const { siteSettings } = useContext(MainContext);
    const [isCollapsed, setIsCollapsed] = useState(
        JSON.parse(window.localStorage.getItem("spoilers"))?.[`${contentID}`] ?
        true 
        :
        JSON.parse(window.localStorage.getItem("spoilers"))?.[`${contentID}`] === undefined 
        ?
        defaultCollapsed
        :
        false
    );
    const [shouldRenderChildren, setShouldRenderChildren] = useState(shouldRenderCollapsed);
    const [maxContentHeight, setMaxContentHeight] = useState(0);
    const [animationOnAir, setAnimationOnAir] = useState(false);
    const aniSpeedVar = useRef();
    const foldableContentRef = useRef();

    useEffect(() => {
        let spoilersState = JSON.parse(window.localStorage.getItem("spoilers")) || {}
        spoilersState[`${contentID}`] = isCollapsed;
        window.localStorage.setItem("spoilers", JSON.stringify(spoilersState));
        
        aniSpeedVar.current = getComputedStyle(document.body).getPropertyValue('--ani-speed');

        let intervalID, timeoutID;

        if (foldableContentRef.current === undefined) {
            return
        }

        if (!isCollapsed) {
            setShouldRenderChildren(true)
            
            intervalID = window.setInterval(() => {
                
                setMaxContentHeight(foldableContentRef.current.children[0].clientHeight)
                
            }, 100) 
            
        } else {
            intervalID && clearInterval(intervalID)
        }

        timeoutID = window.setTimeout(() => {

            if (isCollapsed && !shouldRenderCollapsed) {
                setShouldRenderChildren(false)
            }

        }, 2500)

        return () => {
            clearTimeout(timeoutID)
            intervalID && clearInterval(intervalID)
        }

    }, [isCollapsed, setShouldRenderChildren])


    const handleClick = () => {
        
        if (isCollapsed) {
            
            setAnimationOnAir(true)
            setShouldRenderChildren(true);
            setIsCollapsed(false);
            setMaxContentHeight(foldableContentRef.current.children[0].clientHeight);
            
            window.setTimeout(() => {

                setAnimationOnAir(false)

            }, parseInt(aniSpeedVar.current.replace("ms", "")))

        } else if (!isCollapsed) {

            setAnimationOnAir(true)

            setIsCollapsed(true);
            setMaxContentHeight(0);

            window.setTimeout(() => {
                
                setAnimationOnAir(false)
                
            }, parseInt(aniSpeedVar.current.replace("ms", "")))
            
        }


    }


    return (
        <div className={`spoiler${isCollapsed ? " collapsed" : ""}${" " + addClassName}`}>
        <div 
            className={`spoiler-toggle ${isCollapsed ? "collapsed" : ""}`}
            title={!isDataLoaded ? `Раздел «${header}» загружается` : siteSettings.tooltips ? `${!isCollapsed ? "Скрыть" : "Показать"} раздел «${header}»` : ""}
        >
            <div className='spoiler-header-button-container'> 
                <button
                    disabled={animationOnAir || !isDataLoaded}
                    className='spoiler-header-button'
                    onClick={handleClick}
                >
                    {
                        isDataLoaded ?
                        <>
                            {
                                (itemsCount > 0 || showCount === false) &&
                                <svg xmlns="http://www.w3.org/2000/svg" className="chevrons" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <polyline points={isCollapsed && !animationOnAir ? "7 7 12 12 17 7" : "7 11 12 6 17 11"} />
                                    <polyline points={isCollapsed && !animationOnAir ? "7 13 12 18 17 13": "7 17 12 12 17 17"} />
                                </svg> 
                            }
                        </>
                        :
                        <>
                            <LoadingIcon 
                                addClassName={'spoiler-postfix'}
                            />
                        </>
                    }
                    {header}
                    {
                        isDataLoaded && showCount &&
                        <span className='items-count'>{itemsCount}</span>
                    }
                </button>
            </div>
        </div>
        <div
            className={`spoiler-veil${isCollapsed ? " collapsed" : ""}${isDataLoaded ? " loaded" : ""}`} 
            id={contentID}
            ref={foldableContentRef}
            style={{
                "height": maxContentHeight + "px",
                "overflow": !isCollapsed && !animationOnAir ? "visible" : "hidden",
            }}
        >
            <div className={`spoiler-content-container`}>
                {
                    shouldRenderChildren &&
                    children
                }
            </div>
        </div>
    </div>
    );
}

export default Spoiler;