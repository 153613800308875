import React, { useState, useEffect, useRef } from 'react';
import Portal from '../commonUI/Portal';


const FoldableNavigation = ({contentID, addClassName = "", locationPathname, children}) => {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const [maxContentHeight, setMaxContentHeight] = useState(0);
    const [animationOnAir, setAnimationOnAir] = useState(false);
    const aniSpeedVar = useRef();
    const foldableContentRef = useRef();
    
    useEffect(() => {
        aniSpeedVar.current = getComputedStyle(document.body).getPropertyValue('--ani-speed');
        const backgroundFaderElement = document.getElementById("background-fade");
        
        let intervalID = window.setInterval(() => {
                
            setMaxContentHeight(foldableContentRef.current.children[0].clientHeight)
            
        }, 100)

        if (!isCollapsed && backgroundFaderElement) {
            backgroundFaderElement.addEventListener("click", closeMenu);
        }

        return () => {
            intervalID && clearInterval(intervalID);
            backgroundFaderElement && backgroundFaderElement.removeEventListener("click", closeMenu);
        }

    }, [isCollapsed])

    useEffect(() => {
        
        if (!isCollapsed) {
            closeMenu();
        }

    }, [locationPathname])


    const closeMenu = () => {
        setIsCollapsed(true);
        document.querySelector("#background-fade").classList.remove('active');
        document.querySelector("body").classList.remove('scroll-blocked');
        // console.log("closeMenu called")
    }

    const handleClick = () => {
        
        if (isCollapsed) {
            
            setAnimationOnAir(true)

            setIsCollapsed(false);
            setMaxContentHeight(foldableContentRef.current.children[0].clientHeight);
            
            document.querySelector("#background-fade").classList.add('active');
            document.querySelector("body").classList.add('scroll-blocked');
            
            window.setTimeout(() => {

                setAnimationOnAir(false)

            }, parseInt(aniSpeedVar.current.replace("ms", "")))

        } else if (!isCollapsed) {

            setAnimationOnAir(true)

            setIsCollapsed(true);
            setMaxContentHeight(0);
            document.querySelector("#background-fade").classList.remove('active')
            document.querySelector("body").classList.remove('scroll-blocked');



            window.setTimeout(() => {
                
                setAnimationOnAir(false)

                
            }, parseInt(aniSpeedVar.current.replace("ms", "")))
            
        }

    }

    return (
        <>
        <div className={`spoiler${isCollapsed ? " collapsed" : ""}${" " + addClassName}`}>
            <div 
                className={`spoiler-toggle ${isCollapsed ? "collapsed" : ""}`}
                title={"Показать меню"}
            >
                <div className='spoiler-header-button-container'> 
                    <button
                        disabled={animationOnAir}
                        className='spoiler-header-button hamburger'
                        onClick={handleClick}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="hamburger" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="4" y1="6" x2="20" y2="6" />
                            <line x1="4" y1="12" x2="20" y2="12" />
                            <line x1="4" y1="18" x2="20" y2="18" />
                        </svg> 
                    </button>
                </div>
            </div>
            <div
                className={`spoiler-veil${isCollapsed ? " collapsed" : " loaded"}`} 
                id={contentID}
                ref={foldableContentRef}
                style={{
                    "height": maxContentHeight + "px",
                    "overflow": !animationOnAir ? "visible" : "hidden",
                }}
            >
                <div className={`spoiler-content-container`}>
                    {
                        children
                    }
                </div>
            </div>
        </div>
        <Portal 
            id={'background-fade'}
        />
        </>
    );
}

export default FoldableNavigation;