import React, { useContext, useState } from 'react';
import { getRidOfAliases, applyTimezone, asyncForEach } from "../../../services/littleHelpers"
import { updateValues } from "../../../services/CRUDJournalData"
import { MainContext } from '../../MainContext';

const markEntriesPaid = async (auth_token, chosenEntries, withParking) => {
    const alteredRowsIds = [];

    await asyncForEach(chosenEntries, async (entry) => {

        try {
            await updateValues({
                auth_token: auth_token, 
                values: [
                    {
                        rowID: entry.values.origin_ID,
                        newValue: true,
                        targetProperty: "paid"
                    }
                ]
            })
            alteredRowsIds.push(entry.values.origin_ID)
        } catch (err) {
            console.error(err)
            return [];
        }

    })

    await asyncForEach(withParking, async (entry) => {

        try {
            await updateValues({
                auth_token: auth_token, 
                values: [
                    {
                        rowID: entry.values.origin_ID,
                        newValue: "Парковка оплачена",
                        targetProperty: "parking"
                    }
                ]
            })

            alteredRowsIds.push(entry.values.origin_ID)

        } catch (err) {
            console.error(err)
            return [];
        }
    })

    return alteredRowsIds;
    
}

export const GetReport = ({areaToFilterBy, markedEntries, justAlteredRowsRef, toggleAllRowsSelected, setRecentReportAreaId}) => {
    const { userData } = useContext(MainContext);
    const [isLoading, setIsLoading] = useState(false);
    
    console.log("GetReport rendered with areaToFilterBy", areaToFilterBy)

    /**
     * Component on report call provides csv with following columns:
     * Гос. Номер	Марка	Время получения     Категория
     * 
     * On download chosen entries will be marked as paid.
     */

    let entriesWithParking = markedEntries.filter((entry) => {
        return entry.original.parking_type === "Парковка в кредит"
    })
    let parkingCount = entriesWithParking ? entriesWithParking.length : 0;

    const formatData = (entries) => {
        let csv = 'Гос. Номер\tМарка\tВремя получения\tКатегория\n';
            
        entries.forEach(entry => {
            if (entry.original.parking_type === "Парковка в кредит") {

                csv += [
                    entry.original.number.toUpperCase(),
                    getRidOfAliases(entry.original.brand.brand),
                    entry.original.created_at,
                    entry.original.category,
                ].join("\t")
                csv += `\n`

                csv += [
                    entry.original.number.toUpperCase(),
                    getRidOfAliases(entry.original.brand.brand),
                    entry.original.created_at,
                    `Стоянка [${entry.original.comment}]`
                ].join("\t")
                csv += `\n`

            } else {
                csv += [
                    entry.original.number.toUpperCase(),
                    getRidOfAliases(entry.original.brand.brand),
                    entry.original.created_at,
                    entry.original.category,
                    entry.original?.comment
                ].join("\t")
                csv += `\n`

            }
        })

        return csv;
    }

    const downloadCSV = (csv, filename) => {  
        let csvFile, downloadLink;

        let BOM = "\uFEFF";
        csv = BOM + csv;
        csvFile = new Blob([csv], {type: 'text/csv;charset=utf-8;', endings: "native"});  
        
        downloadLink = document.createElement("a");  
        downloadLink.download = filename;  
        downloadLink.href = window.URL.createObjectURL(csvFile);  
        downloadLink.style.display = "none";  
      
        document.body.appendChild(downloadLink);

        downloadLink.click();  
    }  

    const handleReportRequest = async () => {
        setIsLoading(true);
        let formattedAreaName = getRidOfAliases(areaToFilterBy.label).replace(/участок/gi,"").replace(/\s/g,"").replace(/\/|\\|,|\*/,"-")
        console.log("handleReportRequest called with areaToFilterBy and formattedAreaName", areaToFilterBy, formattedAreaName)

        let nowDate = new Date();
        let todayString = applyTimezone(nowDate.toISOString(), 3).replace(/\s.+$/, "");

        downloadCSV(
            formatData(markedEntries),
            `area--${formattedAreaName}--${todayString}--report.csv`
        )

        let result = await markEntriesPaid(userData.nocodb_auth, markedEntries, entriesWithParking);
        
        if (result?.length === 0) {
            window.alert(`При попытке пометить выбранные записи оплаченными произошла ошибка, попробуйте ещё раз`)
            setIsLoading(false);
        } else {
            justAlteredRowsRef.current = justAlteredRowsRef.current.concat(result);
            toggleAllRowsSelected(false);
            setIsLoading(false);
            setRecentReportAreaId(areaToFilterBy?.value)
        }

    }

    return ( <>
        <div id="get-report-container">
            <div id="report-info-block">
                <p>
                    Для расчёта с «{areaToFilterBy.label}»
                    выбрано {markedEntries.length} записей.<br/>
                    { parkingCount > 0 ?
                        <>
                            
                            {`В отчётную ведомость будут включены ${parkingCount} дополнительные строчки для оплаты стоянки.`}
                            <br/>
                        </>
                        :
                        null
                    }
                    При нажатии на кнопку «Выгрузить» выбранные записи будут помечены как оплаченные.
                </p>
            </div>
            <button 
                key={`loading-${isLoading.current}`}
                className={`button makeReport ${isLoading ? "loading" : ""}`}
                disabled={isLoading}
                onClick={handleReportRequest}
            >
                Выгрузить
            </button>
        </div>
    </> );
}

const propsAreEqual = (prev, next) => {
    if (prev.areaToFilterBy.value === next.areaToFilterBy.value) {
        if (prev.markedEntries.length === next.markedEntries.length) {
            return true; 
        }
    }
    return false; 
}

const GetReportMemoized = React.memo(GetReport, propsAreEqual);
 
export default GetReportMemoized;