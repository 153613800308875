import React, {useEffect, useState} from 'react';

import { Link } from "react-router-dom";

const UseShortcutNumber = (currentLocation, navContainer, elementId) => {
    const [shortcutNumber, setShortcutNumber] = useState(0);

    
    useEffect(() => {

        if (!currentLocation) {
            return;
        }

        let linksElements = navContainer?.current?.querySelectorAll("a");

        for (let i = 0; i < linksElements?.length; i++) {
            if (linksElements[i].id === elementId) {
                setShortcutNumber(i + 1);
            }
        }

    }, [navContainer, currentLocation]);

    return [shortcutNumber]
}


const LinkToAddToJournalElement = ({currentLocation, navContainer, tooltips = true}) => {
    const thisID = "nav-Add-To-Journal";
    const [shortcutNumber] = UseShortcutNumber(currentLocation, navContainer, thisID)

        
    return (
        <Link to="/add" id={thisID} title={tooltips ? `Добавление заявок в журнал \`Ctrl + ${shortcutNumber}\`` : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-circle-plus" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="9" />
                <line x1="9" y1="12" x2="15" y2="12" />
                <line x1="12" y1="9" x2="12" y2="15" />
            </svg>
        </Link>
    )
}

const LinkToJournalElement = ({currentLocation, navContainer, tooltips = true, queryParameters = [{"id": "", "value": ""}], className="", passID, title }) => {
    const thisID = passID ? passID : "nav-Journal";
    const [shortcutNumber] = UseShortcutNumber(currentLocation, navContainer, thisID)
    const linkDest = {pathname: "/"}
    let thisTitle = tooltips && title ? title : tooltips ? `Поиск и изменения в журнале \`Ctrl + ${shortcutNumber}\`` : "";
    
    if (queryParameters[0].id && queryParameters.length > 0) {
        linkDest.search = '?'
        queryParameters.forEach(p => (
            linkDest.search += `${p.id}=${p.value}&`

        ))  

    }

    return (
        <Link to={linkDest} id={thisID} title={thisTitle} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-journal-checkup" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                <rect x="9" y="3" width="6" height="4" rx="2" />
                <path d="M9 14h.01" />
                <path d="M9 17h.01" />
                <path d="M12 16l1 1l3 -3" />
            </svg>
        </Link>
    );
}

const LinkToLocalPedestriansElement = ({currentLocation, navContainer, tooltips = true, queryParameters = [{"id": "", "value": ""}], className="", passID, title }) => {
    const thisID = passID ? passID : "nav-Pedestrians";
    const [shortcutNumber] = UseShortcutNumber(currentLocation, navContainer, thisID)
    const linkDest = {pathname: "/pedestrians"}
    let thisTitle = tooltips && title ? title : tooltips ? `Поиск среди допущенных пешеходов \`Ctrl + ${shortcutNumber}\`` : "";
    
    if (queryParameters[0].id && queryParameters.length > 0) {
        linkDest.search = '?'
        queryParameters.forEach(p => (
            linkDest.search += `${p.id}=${p.value}&`

        ))  

    }

    return (
        <Link to={linkDest} id={thisID} title={thisTitle} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-pedestrians" width="48" height="48" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="7" cy="5" r="2" />
                <path d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
                <circle cx="17" cy="5" r="2" />
                <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
            </svg>
        </Link>
    );
}


const LinkToLocalVehiclesElement = ({currentLocation, navContainer, tooltips = true, queryParameters = [{"id": "", "value": ""}], className="", passID, title }) => {
    const thisID = passID ? passID : "nav-Vehicles";
    const [shortcutNumber] = UseShortcutNumber(currentLocation, navContainer, thisID)
    const linkDest = {pathname: "/local-vehicles"}
    let thisTitle = tooltips && title ? title : tooltips ? `Поиск среди транспорта жителей \`Ctrl + ${shortcutNumber}\`` : "";
    
    if (queryParameters[0].id && queryParameters.length > 0) {
        linkDest.search = '?'
        queryParameters.forEach(p => (
            linkDest.search += `${p.id}=${p.value}&`

        ))  

    }

    return (
        <Link to={linkDest} id={thisID} title={thisTitle} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-car" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="7" cy="17" r="2" />
                <circle cx="17" cy="17" r="2" />
                <path d="M5 17h-2v-6l2 -5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
            </svg>
        </Link>
    );
}

const LinkToSettingsElement = ({currentLocation, navContainer, tooltips = true}) => {
    const thisID = "nav-Settings";
    const [shortcutNumber] = UseShortcutNumber(currentLocation, navContainer, thisID)

    
    return (
        <Link to="/settings" id={thisID} title={tooltips ? `Настройки \`Ctrl + ${shortcutNumber}\`` : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-settings" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                <circle cx="12" cy="12" r="3" />
            </svg>
        </Link>
    );
}


const LinkToAccountingElement = ({currentLocation, navContainer, tooltips = true}) => {
    const thisID = "nav-Accounting";
    const [shortcutNumber] = UseShortcutNumber(currentLocation, navContainer, thisID)
        
    return (
        <Link to="/accounting" id={thisID} title={tooltips ? `Управление платным доступом \`Ctrl + ${shortcutNumber}\`` : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-circle-plus" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                <rect x="9" y="3" width="6" height="4" rx="2" />
                <path d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
                <path d="M12 17v1m0 -8v1" />
            </svg>
        </Link>
    )
}

const LinkToBlacklistElement = ({currentLocation, navContainer, tooltips = true, queryParameters = [{"id": "", "value": ""}], className="", passID, title }) => {
    const thisID = passID ? passID : "nav-Blacklist";
    const [shortcutNumber] = UseShortcutNumber(currentLocation, navContainer, thisID)
    const linkDest = {pathname: "/blacklist"}
    let thisTitle = tooltips && title ? title : tooltips ? `Управление черным списком \`Ctrl + ${shortcutNumber}\`` : "";

    if (queryParameters[0].id && queryParameters.length > 0) {
        linkDest.search = '?'
        queryParameters.forEach(p => (
            linkDest.search += `${p.id}=${p.value}&`

        ))  

    }
        
    return (
        <Link to={linkDest} id={thisID} title={thisTitle} className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-forbidden-vehicle" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="7" cy="17" r="2" />
                <path d="M15.585 15.586a2 2 0 0 0 2.826 2.831" />
                <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h1m3.96 0h4.04v4m0 4v4m-4 0h6m6 0v-6h-6m-2 -5h5l3 5" />
                <line x1="3" y1="3" x2="21" y2="21" />
            </svg>
        </Link>
    )
}

const LinkToContactsElement = ({currentLocation, navContainer, tooltips = true, queryParameters = [{"id": "", "value": ""}], className="", passID, title }) => {
    const thisID = passID ? passID : "nav-Contacts";
    const [shortcutNumber] = UseShortcutNumber(currentLocation, navContainer, thisID)
    const linkDest = {pathname: "/contacts"}
    const thisTitle = tooltips && title ? title : tooltips ? `Контактные лица на участках \`Ctrl + ${shortcutNumber}\`` : "";

    if (queryParameters[0].id && queryParameters.length > 0) {
        linkDest.search = '?'
        queryParameters.forEach(p => (
            linkDest.search += `${p.id}=${p.value}&`

        ))  

    }
        
    return (
        <Link to={linkDest} id={thisID} title={thisTitle} className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon-phone"
                width={44}
                height={44}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
            </svg>
        </Link>
    )
}

const LinkToMainSearch = ({currentLocation, navContainer, tooltips = true}) => {
    const thisID = "nav-MainSearch";
    const [shortcutNumber] = UseShortcutNumber(currentLocation, navContainer, thisID)
        
    return (
        <Link to="/search" id={thisID} title={tooltips ? `Глобальный поиск \`Ctrl + ${shortcutNumber}\`` : ""}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-main-search" width="64" height="64" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="10" cy="10" r="7" />
                <line x1="21" y1="21" x2="15" y2="15" />
            </svg>
        </Link>
    )
}


export {
    LinkToAddToJournalElement,
    LinkToJournalElement,
    LinkToLocalPedestriansElement,
    LinkToLocalVehiclesElement,
    LinkToSettingsElement,
    LinkToAccountingElement,
    LinkToBlacklistElement,
    LinkToContactsElement,
    LinkToMainSearch
}