
import { QueryFilter, readField } from "@directus/sdk";
import { DateTime } from "luxon";
import { Collections, Schema, System, Types } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";

export const getDirectusDateFilter = (
  { dateField, dateString }:
    { dateString: string, dateField: string }
) => {
  // dateString could be any part of date string formatted like below:
  // yyyy-MM-dd HH:mm
  // e.g: yyyy, yyyy-MM, yyyy-MM-dd, MM-dd, yyyy-MM-dd HH:mm, HH:mm, dd HH, dd HH:mm ...
  const filter: Array<QueryFilter<Schema, Collections.ContactsOnObjects>> = [];

  if (/^\d{2}-\d{2}$/.test(dateString)) {
    // MM-dd format
    const [month, day] = dateString.split('-').map(Number);
    filter.push(
      { [`month(${dateField})`]: { _eq: month } }
    );
    filter.push(
      { [`day(${dateField})`]: { _eq: day } }
    );
    return filter;
  }

  if (/^\d{2}:\d{2}$/.test(dateString)) {
    // HH:mm format
    const [hour, minute] = dateString.split(':').map(Number);
    filter.push({
      [`hour(${dateField})`]: { _eq: hour > 3 ? hour - 3 : hour } // Quite agly solution to avoid timezone issues
    });
    filter.push({
      [`minute(${dateField})`]: { _eq: minute }
    });
    return filter;
  }

  const formats = [
    "yyyy",
    "yyyy-MM",
    "yyyy-MM-dd",
    "yyyy-MM-dd HH:mm",
    "dd HH",
    "dd HH:mm"
  ];

  for (const format of formats) {
    const parsed = DateTime.fromFormat(dateString.replace(/\s+/, " ").trim(), format);
    if (parsed.isValid) {
      const components = parsed.toObject();

      if (format === "yyyy") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
      } else if (format === "yyyy-MM") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
      } else if (format === "yyyy-MM-dd") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
      } else if (format === "yyyy-MM-dd HH:mm") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
        filter.push({ [`minute(${dateField})`]: { _eq: components.minute } });
      } else if (format === "MM-dd HH:mm") {
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
        filter.push({ [`minute(${dateField})`]: { _eq: components.minute } });
      } else if (format === "dd HH") {
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
      } else if (format === "dd HH:mm") {
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
        filter.push({ [`minute(${dateField})`]: { _eq: components.minute } });
      }

      return filter;
    }
  }

  return filter;
}


export function reduceDirectusFilesToSringArray(files: Array<{
  directus_files_id: Types.UUID,
  [key: string]: any
} | any>): string[] {
  return files.reduce<string[]>((acc, current) => {
    if (current?.directus_files_id) {
      acc.push(current.directus_files_id);
    }
    return acc;
  }, []);
}

export type SupportedTransformations = "thumbnail" | "fullhd";

export function getDirectusImageSourceUrl(
  { uuid, key }: { uuid: string, key?: SupportedTransformations }
) {
  const baseUrl = process.env.REACT_APP_DIRECTUS_URL.replace(/\/$/, "");

  return `${baseUrl}/assets/${uuid}${key ? `?key=${key}` : ""}`;
}

export type OptionForSelect = {
  value: string,
  label: string,
}

export type CollectionKeys = Exclude<keyof Schema, keyof System>;
export type ColumnKeys<T extends CollectionKeys> = keyof Schema[T][number];

export type provideSelectOptionsArgs<T extends CollectionKeys> = {
  source: T;
  column_id: ColumnKeys<T>;
};

export async function provideSelectOptions<T extends CollectionKeys>({ source, column_id }: provideSelectOptionsArgs<T>) {
  const tempOptions: OptionForSelect[] = [];
  const localStorageName = `${String(source)}_${String(column_id)}_options`;

  const knownOptions = JSON.parse(window.localStorage.getItem(localStorageName));
  const client = getDirectusClient();

  if (Date.now() - knownOptions?.dateOfInsertion < 1000 * 60 * 60) {
    return knownOptions.arrayOfOptions;
  } else {
    console.log(`options for ${column_id as string} column in ${source} source are about to be queried`)
  }

  const columnData = await client.request(readField(source, column_id as string));

  if (columnData.meta?.options?.choices?.length > 0) {

    columnData.meta.options.choices.forEach(opt => {
      tempOptions.push({ value: opt?.value, label: opt?.text })
    });

  }

  window.localStorage.setItem(localStorageName, JSON.stringify({
    arrayOfOptions: tempOptions,
    dateOfInsertion: Date.now()
  }))

  return tempOptions;

}
