import { useState, useEffect } from "react";

const getWidth = () => {
  if (typeof window !== "undefined") {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  } else {
    console.log("You are on the server, can't execute getWidth");
    return undefined;
  }
};

const getHeight = () => {
  if (typeof window !== "undefined") {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    );
  } else {
    console.log("You are on the server, can't execute getWidth");
    return undefined;
  }
}


function useCurrentSizes() {
  let [width, setWidth] = useState(getWidth());
  let [height, setHeight] = useState(getHeight());

  useEffect(() => {
    const resizeListener = () => {
      setWidth(getWidth());
      setHeight(getHeight());
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return [width, height];
}

export default useCurrentSizes;
