

export const initialTitleTooltips = {
    destination: "Сократить список можно введя часть названия: запрос 'ж у' покажет только '1215Ж Участок'. Вернуть полный список можно отчистив поле ввода. Нажатие на 'Tab' или 'Пробел' выберет выделенное значение и закроет список.",
    number: "'Tab' берёт в фокус следующий элемент, 'Shift + Tab' - предыдущий",
    brand: "Перечисленные в этом поле марки хранятся локально и обновляются каждые 24 часа. В настройках есть возможность запросить загрузку свежих данных.",
    daily: "Допуск на сутки. Если элемент в фокусе, 'пробел' меняет значение.",
    category: "Если поле в фокусе, открыть выпадающее меню можно нажав 'стрелку вниз'",
    debt: "Допуск в кредит по запросу Администрации. Если элемент в фокусе, 'пробел' меняет значение.",
    comment: "",
    buttons: {
        add: "Добавить ещё одну машину на те же пункты назначения",
        addFirst: "Добавить машину",
        category: "Указать категорию",
        comment: "Добавить комментарий",
        trash: "Удалить заявку через 5 секунд. Повторное нажатие отменяет действие.",
        duplicate: "Создать дубликат",
        submit: "'Ctrl + Enter' для отправки заявки",
        refresh: "Сбросить значения до начальных"
    },
    table: {
        hideColumn: "Скрыть колонку «%NAME» из действующего вида журнала. Для отмены ищите кнопку справа от таблицы.",
        showInitialColumns: "Показать стандартные колонки",
        showAllColumns: "Показать все колонки",
        showAllColumnsReverse: "Вернуть колонки к начальному состоянию",
        filter: "Нажмите и введите поисковый запрос, - записи будут отфильтрованы по совпадению в «%NAME». Колонки с иконкой лупы у названия могут быть отфильтрованы. Можно применить несколько фильтров.",
        filterShortcut: " `Ctrl + F`",
    },
    depositComment: "При создании депозита был оставлен комментарий:",
}