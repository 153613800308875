import { readMe } from '@directus/sdk';
import { getDirectusClient } from './DirectusSDKClient';
import { allRoles } from '../App';
import { UserData } from '../components/MainContext';

export interface DirectusUser {
  id: string;
  first_name: string;
  last_name: string | null;
  email: string;
  avatar: any | null;
  role: Role | null;
}

export interface Role {
  id: string;
  name: typeof allRoles[number];
  nocodb_auth: string | null;
  policies: Array<Policy> | null;
}

interface Policy {
  id: string;
  name: string;
}

class UserService {
  private static instance: UserService;
  private client = getDirectusClient();
  private userFetchPromise: Promise<UserData | null> | null = null;
  private user: UserData | null = null;
  private tokenExpires: number | null = null;
  private tokenExpiresAt: number | null = null;
  private refreshTimer: NodeJS.Timeout | null = null;
  private readonly REFRESH_BUFFER = 60000; // 1 minute buffer
  private lastLoginTimestamp: number | null = null;
  private readonly MAX_SESSION_DURATION = 11 * 60 * 60 * 1000; // 11 days

  private constructor() {
    this.loadFromStorage();
    this.startRefreshTimer();
  }

  private startRefreshTimer() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
    if (this.tokenExpiresAt) {
      const timeUntilRefresh = this.tokenExpiresAt - Date.now() - this.REFRESH_BUFFER;
      this.refreshTimer = setTimeout(async () => await this.refreshToken(), Math.max(0, timeUntilRefresh));
    }
  }

  private stopRefreshTimer() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    }
  }

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  private saveToStorage() {
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('lastLoginTimestamp', this.lastLoginTimestamp.toString());
  }

  private loadFromStorage() {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const storedLastLoginTimestamp = localStorage.getItem('lastLoginTimestamp');

    if (isLoggedIn && storedLastLoginTimestamp) {
      this.lastLoginTimestamp = parseInt(storedLastLoginTimestamp, 10);
    }

  }

  public async doLogin(email: string, password: string, otp?:string): Promise<void> {
    try {
      // console.log("calling doLogin with: ", email, password, otp)
      const auth = this.client;
      const session = await auth.login(email, password, {
        otp,
        mode: 'session',
      });

      // console.log("logged in, session token expires at: ", new Date(session.expires_at), "\nresieved session as: \n", session)

      this.tokenExpiresAt = session.expires_at;
      this.tokenExpires = session.expires;
      this.lastLoginTimestamp = Date.now();
      this.user = await this.fetchCurrentUser();
      this.saveToStorage();
      this.startRefreshTimer();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        
      }
      console.error('Login failed:', error);
      throw error;
    }
  }

  public async doLogout(callback?: () => void): Promise<void> {
    try {
      const auth = this.client;
      
      await auth.logout()
        .catch(error => console.error("Logout error:", error));
      
      this.stopRefreshTimer()
      this.clearAuth()

      if (callback) {
        callback();
      }

    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  }

  public async checkAuth(): Promise<boolean> {
    // console.log("this.lastLoginTimestamp", this.lastLoginTimestamp)
    // console.log("Date.now() - this.lastLoginTimestamp", Date.now() - this.lastLoginTimestamp)
    // console.log("this.MAX_SESSION_DURATION", this.MAX_SESSION_DURATION)
    
    if (!this.lastLoginTimestamp) {
      return false
    }
    
    if (this.lastLoginTimestamp && Date.now() - this.lastLoginTimestamp > this.MAX_SESSION_DURATION) {
      console.log("%cLast login was more than MAX_SESSION_DURATION or never occurred, clearing auth", "color: red")
      this.doLogout(() => window.setTimeout(() => {
        window.location.reload()
      }, 350));
      return false;
    }

    if (this.tokenExpiresAt && Date.now() < this.tokenExpiresAt) {
      // console.log("session token is still valid")
      return true;
    }

    return await this.refreshToken();
    // try {
    // } catch (error) {
    //   console.error('Token refresh failed:', error);
    //   return false;
    // }

  }

  private async refreshToken() {
    // console.log("trying to refresh token\n")
    try {
      const response = await this.client.refresh();
      // const response = await this.client.request(refresh('session'));
      this.tokenExpiresAt = response.expires_at;
      this.tokenExpires = response.expires;
      this.startRefreshTimer();
      this.saveToStorage();
      console.log("Token refreshed successfully, new expiry time: ", new Date(this.tokenExpiresAt));
      return true;
    } catch (error) {
      this.clearAuth();
      return false;
    }
  }

  private clearAuth() {
    this.user = null;
    this.tokenExpires = null;
    this.tokenExpiresAt = null;
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('lastLoginTimestamp');
  }

  private formatUserData(directusUser: DirectusUser): UserData {
    const username = `${directusUser.first_name || ""} ${directusUser.last_name || ""}`.trim();
    
    // console.log("directusUser", directusUser);
    
    const roles = [directusUser.role.name];

    const shoudlAddDezhurniy = ["kpp1", "kpp2", "patrol1", "patrol2", "dev", "admin"].some(role => { return role === directusUser.role.name });
    
    if (shoudlAddDezhurniy) {
      roles.push("dezhurniy");
    }
    
    if (roles.includes("Master")) {
      roles.push("analyst");
      roles.push("dezhurniy");
    }
    
    if (roles.includes("Chief of Security")) {
      roles.push("admin");
      roles.push("accountant");
    }
    
    if (roles.includes("Administrator")) {
      roles.push("admin");
      roles.push("analyst");
      roles.push("dev");
    }

    // const shoudlAddKpp1 = ["kpp1", "kpp2", "patrol", "dev", "admin", "analyst"].some(role => { return role === directusUser.role.name });
    
    const user: UserData = {
      username: username,
      first_name: directusUser.first_name,
      last_name: directusUser.last_name,
      email: directusUser.email,
      nocodb_auth: directusUser.role.nocodb_auth,
      roles,
      directus_role: directusUser.role,
    }
    // console.log("fetched from Directus user", user)

    return user;
  }

  private async fetchCurrentUser(): Promise<UserData> {
    const directusUser = await this.client.request(readMe({
      fields: [
        "id", "first_name", "last_name", "email", "avatar",
        // @ts-expect-error SDK type checkings fail on policy property nesting level, but this porperty exists.
        { role: ["id", "name", "nocodb_auth", { policies: [{ policy: ["id", "name"] }] }] }
      ]
    })) as DirectusUser;

    // console.log("returning fomatted user", this.formatUserData(directusUser));

    return this.formatUserData(directusUser);
  }

  public async getCurrentUser(): Promise<UserData | null> {
    if (this.user) {
      return this.user;
    }

    if (this.userFetchPromise) {
      return this.userFetchPromise;
    }

    this.userFetchPromise = this.fetchCurrentUser()
      .then((user) => {
        this.user = user;
        return user;
      })
      .catch((error) => {
        throw error;
        console.error("Error fetching current user:", error);
        return null;
      })
      .finally(() => {
        this.userFetchPromise = null;
      });

    return this.userFetchPromise;

  }

  public getUsername(): string | undefined {
    if (this.user?.first_name || this.user?.last_name) {
      const name = `${this.user.first_name || ''} ${this.user.last_name || ''}`;
      return name.trim();
    }
    return undefined;
  }

  public getNocodbAuth(): any | null {
    if (!this.user) return null;
    return this.user.nocodb_auth;
  }

  public async getRole(): Promise<typeof allRoles[number] | null> {
    if (!this.user) return null;
    try {
      const user = await this.getCurrentUser();
      return user.directus_role.name;
    } catch (error) {
      console.error("Error fetching user", error);
      return null;
    }
  }

  public async hasRole(roles: readonly typeof allRoles[number][]): Promise<boolean> {

    try {
      const user = await this.getCurrentUser();
      return roles.some(role => user.roles?.includes(role));
    } catch (error) {
      console.error("Error fetching user", error);
      return false;
    }

  }
}

export default UserService.getInstance();
