import React, { useState, useRef } from 'react';
import Viewer from 'react-viewer';
import { useHotkeys } from 'react-hotkeys-hook';
import { getDirectusImageSourceUrl } from "../../services/DirectusServices/directusHelpers";

const RotateIcon = ({reverse = false}) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="icon-rotate" 
            width="44" height="44" viewBox="0 0 24 24" 
            strokeWidth="1.5" stroke="currentColor" 
            fill="none" strokeLinecap="round" strokeLinejoin="round"
            transform={`${reverse ? "scale(-1,1)": ""}`}
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M16.3 5h.7a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h5l-2.82 -2.82m0 5.64l2.82 -2.82" transform="rotate(-45 12 12)" />
        </svg>
    );
} 

const NextImageIcon = ({reverse = false}) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="icon-chevron" 
            width="44" height="44" viewBox="0 0 24 24" 
            strokeWidth="1.5" stroke="currentColor" 
            fill="none" strokeLinecap="round" strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            { reverse === false ?
                <polyline points="9 6 15 12 9 18" />
                :
                <polyline points="15 6 9 12 15 18" />
            }
        </svg>
    );
}

const ZoomInIcon = ({reverse = false}) => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="icon-zoom" 
            width="44" height="44" viewBox="0 0 24 24" 
            strokeWidth="1.5" stroke="currentColor" 
            fill="none" strokeLinecap="round" strokeLinejoin="round"
            transform={`${reverse ? "scale(-1,1)": ""}`}
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="10" cy="10" r="7" />
            <line x1="7" y1="10" x2="13" y2="10" />
            {
                reverse === false &&
                <line x1="10" y1="7" x2="10" y2="13" />
            }
            <line x1="21" y1="21" x2="15" y2="15" />
        </svg>
    );
}

const ResetIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="icon-reset" 
            width="44" height="44" viewBox="0 0 24 24" 
            strokeWidth="1.5" stroke="currentColor" 
            fill="none" strokeLinecap="round" strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
            <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
        </svg>
    );
}


/* list with keys/actionTypes for react-viewer:

  zoomIn = 1,
  zoomOut = 2,
  prev = 3,
  next = 4,
  rotateLeft = 5,
  rotateRight = 6,
  reset = 7,
  close = 8,
  scaleX = 9,
  scaleY = 10,
  download = 11,
toolbar

*/ 

const toolbar = [
    {
        key: 'prev',
        actionType: 3,
        render: <NextImageIcon reverse={true} />
    },
    {
        key: 'rotateLeft',
        actionType: 5,
        render: <RotateIcon reverse={true} />
    },
    {
        key: 'rotateRight',
        actionType: 6,
        render: <RotateIcon  />
    },
    {
        key: 'zoomOut',
        actionType: 2,
        render: <ZoomInIcon reverse={true} />,
    },
    {
        key: 'reset',
        actionType: 7,
        render: <ResetIcon  />
    },
    {
        key: 'zoomIn',
        actionType: 1,
        render: <ZoomInIcon />,
    },  
    {
        key: 'next',
        actionType: 4,
        render: <NextImageIcon  />
    },
]

const InTableFilesPreview = ({files}) => {
    const [ visible, setVisible ] = useState(false);
    // const [activeIndex, setActiveIndex] = useState(0);
    // const activeIndex = useRef(0);
    let images = [];

    useHotkeys('esc',  () => setVisible(false), {keyup: true, enableOnTags: ["INPUT", "TEXTAREA", "SELECT"]} )

      // console.log(files)
    function extractImages(file, imagesArray) {
      if (!file?.mimetype && file.match(/.{8}-.{4}-.{4}-.{4}-.{12}/)) {
        const src = getDirectusImageSourceUrl({
          uuid: file,
          key: "fullhd"
        });
        const srcThumbnail = getDirectusImageSourceUrl({
          uuid: file,
          key: "thumbnail"
        });
          
        imagesArray.push({
          srcThumbnail: srcThumbnail,
          src: src,
          alt: ''
        })
      } else if (file?.mimetype?.includes("image")) {
          imagesArray.push({src: file.url, alt: ''})
      }
    }
   
    if (Array.isArray(files)) {
      files?.forEach(file => extractImages(file, images));
    } else if (files?.mimetype || files?.match(/.{8}-.{4}-.{4}-.{4}-.{12}/)) {
      extractImages(files, images)
    }

    const handleClickOnImagePreview = (e) => {
        // console.log(e.target.attributes.index.value)
        // setActiveIndex(e.target.attributes.index.value)
        // activeIndex.current = e.target.attributes.index.value;
        setVisible(true)
    }

    const handleExitViewer = () => {
        setVisible(false)
    }

    // const imageChangeHandler = (e) => {
    //     console.log(e)
    // }

    /**
     * TODO: setting active index to Viewer component
     * randomly leads to errors - undefined next image logged in 
     * onChange event. For the time being, no matter what image is clicked,
     * first image will be opened in Viewer. 
     */

    return (
        <>
            <div className='image-in-table-container'>
                {
                    images.map((img, index) => {
                      return (
                            <img 
                                key={`img-prev-${index}`}
                                className="image-in-table-preview"
                                index={index}
                                src={ img?.srcThumbnail || img?.src } 
                                alt={img?.alt}
                                loading="lazy"
                                onClick={handleClickOnImagePreview}
                            />
                        );
                    })
                }
            </div>
            <Viewer
                key={images.length}
                visible={visible}
                onClose={() => { handleExitViewer() } }
                images={images}
                onMaskClick={() => { handleExitViewer() } }   
                attribute={false}
                noClose={true}
                changeable={images.length > 1 ? true : false}
                scalable={false}
                disableKeyboardSupport={true}
                // activeIndex={activeIndex.current}
                // onChange={imageChangeHandler}
                minScale={0.2}
                noImgDetails={true}
                noNavbar={true}
                zIndex={11}
                zoomSpeed={0.3}
                customToolbar={() => toolbar}
            />
        </>
    );
}


export default InTableFilesPreview;