import React, { useContext } from 'react';
import { TextInput, SimpleInTableCheckbox, CarIdIssueSelect } from "../inputElements";
import InTableFilesPreview from "../commonUI/InTableFilesPreview";
import { MainContext } from '../MainContext';
import { getRidOfAliases } from '../../services/littleHelpers';

const VehicleRow = ({ preparedRow, deletingRowsRef, justAlteredRowsRef }) => {
    const { userData, siteSettings } = useContext(MainContext);

    const cellRenderFunction = (cell) => {

        let backhoeIcon = 
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-backhoe" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="4" cy="17" r="2" />
                <circle cx="13" cy="17" r="2" />
                <line x1="13" y1="19" x2="4" y2="19" />
                <line x1="4" y1="15" x2="13" y2="15" />
                <path d="M8 12v-5h2a3 3 0 0 1 3 3v5" />
                <path d="M5 15v-2a1 1 0 0 1 1 -1h7" />
                <path d="M21.12 9.88l-3.12 -4.88l-5 5" />
                <path d="M21.12 9.88a3 3 0 0 1 -2.12 5.12a3 3 0 0 1 -2.12 -.88l4.24 -4.24z" />
            </svg>

        if (cell.column.id === "dest") {
            let d = cell.value;

            return (
                <span 
                    className={(d?.status?.includes("Строящийся") || d?.status?.includes("На реконструкции")) ? "on-construction" : ""} 
                    title={siteSettings.tooltips && d?.status ? `${d?.status.replace(",", ", ")}` : ""}
                    key={`dest-in-${cell.row.origin_ID}-row-${d.origin_ID}`}
                >
                    {(d?.status?.includes("Строящийся") || d?.status?.includes("На реконструкции")) && backhoeIcon}
                    <p>{getRidOfAliases(d.area_name)}</p>
                </span>
            );

        } else if (cell.column.id === "brand") {
          console.log(cell.valuee)
          return `${getRidOfAliases(cell.value?.brand || cell.value?.label)}`
        } else if (cell.column.id === "number") {

            return (
                <>

                    {
                        cell.row.original?.mark ?
                        <>
                            <p 
                                title={siteSettings.tooltips ? `${cell.row.original?.mark?.replace(/^.+\s+?/g, "")}` : ""}
                                className='vehicle-number'
                            >
                                <i className='vehicle-mark'>{cell.row.original?.mark?.replace(/\s.+$/g, "")}</i>
                                {cell.value}
                            </p>
                        </>
                        :
                        <p className='vehicle-number'>
                            {cell.value}
                        </p> 
                    }
                </>
            );
        

        } else if (cell.column.id === "comment") {

            const canEditComment = (userRoles) => {

                if (userRoles.includes('admin')) {
                    return false;
                } else {
                    return true;
                }

            }

            return(
                <TextInput
                    cell={cell}
                    textArea={true}
                    targetEndpoint="local_vehicles"
                    allowedRoles={['admin', 'dev']}
                    customDisablingFunction={canEditComment}

                />
            );
        } else if (cell.column.id === "photo") {
            return (
                <InTableFilesPreview
                    files={cell.value}
                />
            );
        } else if (cell.column.id === "docs") {

            return (
                <InTableFilesPreview
                    files={cell.value}
                />
            );
            
        } else if (cell.column.id === "id_issue_2024") {
          
          return (
                <CarIdIssueSelect
                    cell={cell}
                />
            );

        } else if (cell.column.id === "archived") {
            let disabled = true;

            if (userData.roles.includes("admin")) {
                disabled = false;
            }

            return (
                <SimpleInTableCheckbox
                    cell={cell}
                    justAlteredRowsRef={justAlteredRowsRef}
                    targetEndpoint={'local_vehicles'}
                    isChecked={cell.value}
                    disabled={disabled}
                />
            );
        }

        return cell.render('Cell');
    }

    const { key, ...rowProps } = preparedRow?.getRowProps() || {};

    return (
        <>
            <tr 
                key={`row-updated-at-${preparedRow?.original?.updated_at_full}-${key}`}
                id={`local-vehicle-entry-id-${preparedRow.original.origin_ID}`}
                className={`local-vehicle-entry`}
                {...rowProps}
            >
                {
                    preparedRow.cells.map((cell, i) => {
                      const { key, ...cellProps } = cell?.getCellProps() || {};
                      
                      return (
                        <td
                          className={`cell-in-${cell.column.id} boxProperty`}
                          key={`${cell.row.original.origin_ID}-${cell.column.header}-${key}`}
                          {...cellProps}
                        >
                          {cellRenderFunction(cell)}
                        </td>
                      );
                    })
                }
            </tr>
        </>
    );
}


function rowsPropsAreEqual(prevRow, nextRow) {
    return prevRow.preparedRow.original.origin_ID === nextRow?.preparedRow?.original?.origin_ID
  }

const VehicleRowMemoised = React.memo(VehicleRow, rowsPropsAreEqual)

export default VehicleRowMemoised;