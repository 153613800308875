import { createContext } from "react";
import { allRoles } from "../App";
import { Role as DirectusRole } from "../services/DirectusUserService";

export type SiteSettings = {
  theme?: "light" | "dark";
  tooltips?: boolean;
}

export type UserData = {
  username: string;
  email: string;
  nocodb_auth: string | null;
  roles: Array<typeof allRoles[number]> | null;
  first_name: string;
  last_name: string | null;
  directus_role: DirectusRole | null;
}

export type MainContextType = {
  siteSettings?: SiteSettings;
  setSiteSettings?: (settings: Partial<SiteSettings>) => void;
  userData?: UserData;
  changeUserData?: (data: any) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
}

const defaultContext: MainContextType = {
  siteSettings: { theme: "light", tooltips: true },
  setSiteSettings: () => {},
  userData: undefined,
  changeUserData: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {}
};

export const MainContext = createContext<MainContextType>(defaultContext);
