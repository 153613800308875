import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import usePortal from '../../services/usePortal';

/**
 * @example
 * <Portal id="modal">
 *   <p>Thinking with portals</p>
 * </Portal>
 */

const Portal = ({ id, children }) => {
  const target = usePortal(id);

  return createPortal(
    children,
    target,
  );
  
};

export default Portal;