import { useState, useRef, useContext, useEffect } from "react";
import { useAsyncDebounce } from "react-table";
import { MainContext } from "../MainContext";
import { useSearchParams } from "react-router-dom";
import { initialTitleTooltips } from "../settings/tooltipsText";

const ColumnFilter = ({ column }) => {
  const { siteSettings } = useContext(MainContext);
  const { filterValue, setFilter } = column;
  const [value, setValue] = useState(filterValue);
  const [searchParams] = useSearchParams();
  const inputEl = useRef();
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1500);

  useEffect(() => {
    if (!column.filterValue) {
      setValue("")
    }

  }, [column.filterValue])

  useEffect(() => {
    
    for (const [key, value] of searchParams.entries()) {

      if (key === column.id) {
        setValue(value)
      }

    }

  }, [searchParams]);


  return (
    <div 
      className="column-filter-container"
      title={
        siteSettings.tooltips 
        ? 
          initialTitleTooltips.table.filter.replace("%NAME", column.Header) 
          + 
          `${column.id === "number" || column.id === "name" ? initialTitleTooltips.table?.filterShortcut : ""}`
        :
          ""
      }
    >
      <svg xmlns="http://www.w3.org/2000/svg" onClick={() => {inputEl.current.focus()}} className="icon-list-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <circle cx="15" cy="15" r="4" />
        <path d="M18.5 18.5l2.5 2.5" />
        <path d="M4 6h16" />
        <path d="M4 12h4" />
        <path d="M4 18h4" />
      </svg>
      <input
        className="column-filter"
        id={`column-filter-id-${column.id}`}
        ref={inputEl}
        placeholder={column.Header}
        value={value || ""}
        autoComplete="off"
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default ColumnFilter;