import React, { useContext, useEffect, useRef, useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import Select, { components, createFilter } from 'react-select';
import { matchSorter } from "match-sorter";
import { getRidOfAliases, focusMainSearch, applyTimezone, notifySecurityChat } from "../services/littleHelpers"
import { MainContext } from './MainContext';
import { setExistingOrNewOption, uploadFiles, updateValues, deleteJournalRow, deleteRowWithTimeout, prolongDeposit } from "../services/CRUDJournalData";
import { provideSelectOptions } from '../services/DirectusServices/directusHelpers';
import ModalDialog from './modals/ModalDialog';

export const reasonableFilterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.label}`,
    trim: true,
}

const wasEditedInLast24hours = (updated_atTimestring, hours = 24) => {
    let diffInMS = Date.now() - Date.parse(updated_atTimestring);
    return diffInMS < hours * 60 * 60 * 1000;
}

const shouldBeDisabled = (userData, updated_atTimestring) => {
    if (userData.roles.includes('admin')) {
        return false
    } else if (
        userData.roles.includes('kpp1') 
        || 
        userData.roles.includes('dezhurniy') 
        || 
        userData.roles.includes('kpp3')
    ) {
        return !wasEditedInLast24hours(updated_atTimestring)
    }

    return true;

}

const CreatableBrandSelect = ({ 
    cell, 
    allOptionsRef,
    isLoading, 
    localStorageName, 
    isDisabled, 
    onChangeFunction,
    forwardRef
}) => {

    const { userData } = useContext(MainContext);
    const [brandsOptions, setBrandsOptions] = useState(allOptionsRef.current);
    const [menuCanBeOpened, setMenuCanBeOpened] = useState(undefined);
    const thisCreatableBrandSelect = useRef(forwardRef ? forwardRef : null);
    const newValueRef = useRef();

    let disabled = isDisabled;

    if (disabled === undefined || disabled === null) {
        disabled = shouldBeDisabled(userData, cell.row.original.updated_at);
    }

    useEffect(() => {
        let intervalID = window.setInterval(() => {

            if (allOptionsRef.current?.length > 0) {
                setBrandsOptions(allOptionsRef.current)
                clearInterval(intervalID);
            }
        }, 1500)

        return () => {
            clearInterval(intervalID)
        }

    }, [])



    const onInputChange = (value, {action}) => {

        if (action === 'input-change') {
            let filteredOptions = matchSorter(brandsOptions, value, { keys: ['label']});
            setBrandsOptions(filteredOptions);
    
            if (value === "") {
                setBrandsOptions(allOptionsRef.current);
            }
    
        } else if (action === 'menu-close') {
            setBrandsOptions(allOptionsRef.current);   
        }
    }

    const makeDefaultValue = (desiredValue, brandsOptions) => {

        if (brandsOptions !== undefined) { 
            return brandsOptions.filter(opt => opt?.label === desiredValue?.brand)
        } else {
            return undefined;
        }

    }
    

    const onChange = (newValue) => {

        if (!onChangeFunction) {
            
            setExistingOrNewOption({
                targetEndpoint: 'brands',
                auth_token: userData.nocodb_auth,
                options: brandsOptions, 
                setOptions: setBrandsOptions, 
                allOptionsRef, 
                cell, 
                newValue,
                newValueRef,
                localStorageName
            })

        } else {
            
            onChangeFunction(newValue);
            
        }

    }

    const handleKeyDown = (e) => {
        /**
         * keycode 40 == arrowDown
         * keycode 38 == arrowUp
         * ---
         * Function block menu opening on arrow keys
         */

        if (e.keyCode === 40 || e.keyCode === 38 ) {

            if (thisCreatableBrandSelect.current?.inputRef.getAttribute("aria-expanded") !== "true") {
                setMenuCanBeOpened(false)
            }

        } else {  

            setMenuCanBeOpened(undefined)

        }

    }
    

    return (
        <CreatableSelect
            ref={thisCreatableBrandSelect}
            isClearable={false}
            key={`creatable-select-${cell?.value?.id}-${allOptionsRef.current?.length}`}
            onChange={(e) => { onChange(e); }}
            onKeyDown={handleKeyDown}
            menuIsOpen={menuCanBeOpened}
            captureMenuScroll={true}
            options={brandsOptions}
            isSearchable={true}
            filterOption={createFilter(reasonableFilterConfig)}
            onInputChange={onInputChange}
            isDisabled={disabled}
            isLoading={isLoading} 
            loadingMessage={() => {return "Идёт загрузка..."}}
            noOptionsMessage={() => {return "Нет опций"}}
            placeholder=""
            formatCreateLabel={(value) => `Добавить: "${value}"`}
            classNamePrefix="creatable-select"
            defaultValue={() => {
                    if(newValueRef.current) {
                        console.log("setting newValueRef.current as default", newValueRef.current)
                        return newValueRef.current
                    } else {
                        return makeDefaultValue(cell?.value, allOptionsRef.current)
                    }
                }
            }
        />
    );
}

const ParkingTypeSelect = ({cell, isDisabled = undefined }) => {
    const { userData } = useContext(MainContext);
    let disabled;

    if (isDisabled === undefined) {
        disabled = true;
        
        if (cell.row.original.parking_type !== null && wasEditedInLast24hours(cell.row.original.updated_at)) {
            disabled = false;
        }
    
        if (cell.row.original.parking_type === "Парковка в кредит") {
            disabled = false;
        } 

    } else {
        disabled = isDisabled
    }

    const options = [
        { value: "Парковка в кредит", label: "Парковка в кредит" },
        { value: "Парковка оплачена", label: "Парковка оплачена" },
    ]

    const setDefaultValue = (desiredValue, options) => {

        if (options !== undefined || options !== null) { 
            return options.filter(opt => opt.label === desiredValue)
        } else {
            return undefined;
        }

    }

    const onChange = (e) => {

        updateValues({
            auth_token: userData.nocodb_auth,
            targetEndpoint: "journal",
            values: [ 
                {
                    targetProperty: "parking",
                    rowID: cell.row.original.origin_ID,
                    newValue: e.value
                }
            ]
        })

    }


    return (
        <Select
            key={`select-${cell.value}-in-${cell.row.id}`}
            onChange={(e) => { onChange(e); } }
            options={options}
            placeholder=""
            isSearchable={false}
            classNamePrefix="select"
            defaultValue={setDefaultValue(cell?.value, options)}
            captureMenuScroll={true}
            closeMenuOnScroll={true}
            isDisabled={disabled}
        />
    );

}

const CategorySelect = ({cell}) => {
    const value = useRef(cell.value)
    const { userData } = useContext(MainContext);
    const [menuCanBeOpened, setMenuCanBeOpened] = useState(undefined);
    const thisSelectRef = useRef();

    let disabled = shouldBeDisabled(userData, cell.row.original.updated_at);


    const options = [
        { value: "Пешеход", label: "Пешеход" },
        { value: "Легковой", label: "Легковой" },
        { value: "До 3.5 тонн", label: "До 3.5 тонн" },
        { value: "Более 3.5 тонн", label: "Более 3.5 тонн" },
        { value: "Длинномеры > 8 м", label: "Длинномеры > 8 м" },
        { value: "8 кубов конт.", label: "8 кубов конт." },
        { value: "20 кубов конт.", label: "20 кубов конт." },
        { value: "27 кубов конт.", label: "27 кубов конт." },
        { value: "32 кубов конт.", label: "32 кубов конт." },
        { value: "Транзит стр. техники", label: "Транзит стр. техники" },
    ]

    const setDefaultValue = (desiredValue, options) => {

        if (options !== undefined || options !== null) { 
            return options.filter(opt => opt.label === desiredValue)
        } else {
            return undefined;
        }

    }

    const onBlur = (e) => {

        if (cell.value !== value.current) {

            updateValues({
                auth_token: userData.nocodb_auth,
                values: [ 
                    {
                        cell: cell,
                        newValue: value.current
                    }
                ]
            })
            
        }

    }

    const onChange = (e) => {
        value.current = e.value;  
    }

    const handleKeyDown = (e) => {
        /**
         * keycode 40 == arrowDown
         * keycode 38 == arrowUp
         * ---
         * Function block menu opening on arrow keys
         */

        if (e.keyCode === 40 || e.keyCode === 38 ) {

            if (thisSelectRef.current?.inputRef.getAttribute("aria-expanded") !== "true") {
                setMenuCanBeOpened(false)
            }

        } else {  

            setMenuCanBeOpened(undefined)

        }

    }

    return (
        <Select
            ref={thisSelectRef}
            key={`select-${cell.value}-in-${cell.row.id}`}
            onChange={(e) => { onChange(e); } }
            onBlur={ (e) => { onBlur(e)} }
            onKeyDown={handleKeyDown}
            menuIsOpen={menuCanBeOpened}
            options={options}
            placeholder=""
            isSearchable={true}
            filterOption={createFilter(reasonableFilterConfig)}
            classNamePrefix="select"
            defaultValue={setDefaultValue(cell?.value, options)}
            captureMenuScroll={true}
            closeMenuOnScroll={true}
            isDisabled={disabled}
        />
    );
}

const CarIdIssueSelect = ({cell}) => {
    const { userData } = useContext(MainContext);
    const [options, setOptions] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const value = useRef(cell.value)
    const [menuCanBeOpened, setMenuCanBeOpened] = useState(undefined);
    const thisSelectRef = useRef();

    let disabled = shouldBeDisabled(userData, cell.row.original.updated_at);
    useEffect(() => {
        (async () => {
          let opts = await provideSelectOptions({
                source: "local_vehicles", 
                column_id: cell?.column?.id,
            }); 
            
            setOptions(opts);
            setIsLoading(false);
        })()

    }, [userData, cell?.column?.id]);

    // const options = [
    //     { value: "К выпуску 2023", label: "К выпуску 2023" },
    //     { value: "Произведён 2023", label: "Произведён 2023" },
    // ]
    const setDefaultValue = (desiredValue, options) => {
        
        if (!options) {
            return undefined;
        }

        if (options !== undefined || options !== null) { 
            return options.filter(opt => opt.label === desiredValue)
        } else {
            return undefined;
        }

    }

    const onBlur = (e) => {

        if (cell.value !== value.current) {

            updateValues({
                auth_token: userData.nocodb_auth,
                targetEndpoint: "local_vehicles",
                values: [ 
                    {
                        cell: cell,
                        newValue: value.current
                    }
                ]
            })
            
        }

    }

    const onChange = (e) => {
        if (e === null) {
            value.current = null;
        } else {
            value.current = e.value;  
        }
    }

    const handleKeyDown = (e) => {
        /**
         * keycode 40 == arrowDown
         * keycode 38 == arrowUp
         * ---
         * Function block menu opening on arrow keys
         */

        if (e.keyCode === 40 || e.keyCode === 38 ) {

            if (thisSelectRef.current?.inputRef.getAttribute("aria-expanded") !== "true") {
                setMenuCanBeOpened(false)
            }

        } else {  

            setMenuCanBeOpened(undefined)

        }

    }

    return (
        <Select
            ref={thisSelectRef}
            key={`select-${cell.value}-in-${cell.row.id}-${isLoading ? "loading" : "loaded"}`}
            onChange={(e) => { onChange(e); } }
            onBlur={ (e) => { onBlur(e)} }
            onKeyDown={handleKeyDown}
            menuIsOpen={menuCanBeOpened}
            options={options}
            isLoading={isLoading}
            placeholder=""
            isSearchable={true}
            isClearable={true}
            filterOption={createFilter(reasonableFilterConfig)}
            classNamePrefix="select"
            defaultValue={setDefaultValue(cell?.value, options)}
            captureMenuScroll={true}
            closeMenuOnScroll={true}
            isDisabled={disabled}
        />
    );
}

const FileInput = ({
    inputID,
    ButtonAdditionalClassName,
    pathToSaveFiles,
    proceed,
    setProceed,
    setUploadedFilesLinks,
    handleInput
}) => {
    const { userData } = useContext(MainContext);
    const [chosenFiles, setChosenFiles] = useState([]);

    useEffect(() => {

        if (proceed === "start") {
            
            if (chosenFiles.lenght < 1) {
                setUploadedFilesLinks([]);
                setProceed("success")
                return
            }

            (async () => {
                
                let links = await uploadFiles(userData.nocodb_auth, pathToSaveFiles, chosenFiles);

                if (!links || links === 0) {
                    setProceed("failed")
                    return;
                }

                links.forEach(link => {
                    if (link === 0) {
                        setProceed("failed")
                        return;
                    }
                })

                setUploadedFilesLinks(links);
                setProceed("success")
                
            })()

        }
        
    }, [chosenFiles, proceed, setChosenFiles]);

    const innerHandleInput = async (e) => {
        setChosenFiles(e.target.files);
        
        if (handleInput) {
            await handleInput(e);
        }
    }

    
    return (
        <>
            <label 
                htmlFor={inputID} 
                className={`button ${ButtonAdditionalClassName} ${proceed !== "idle" ? "disabled" : ""}`}
            >
                {
                    chosenFiles?.length < 1 ?
                    "Загрузить фото"
                    :
                    <>
                        Заменить фото
                        <ul className="uploaded-files-names">
                            {
                                Array.from(chosenFiles).map((file, index) => (
                                    <li key={`file-${index}`}>{file.name}</li>
                                ))
                            }
                        </ul>
                    </>
                }
            </label>
            <input
                id={inputID} 
                type="file" 
                accept="image/*"
                multiple={true}
                className='file-upload'
                onInput={innerHandleInput}
                disabled={proceed !== "idle" ? true : false }

            />
        </>
    );
}

const FileInputWithParkingHandling = ({ FileInput, targetRowId, setReadyToApply, setParkingStartTime, setUploadedFilesLinks,  proceed, setProceed}) => {

    const handleInput = (e) => {

        let date = new Date(e.target.files[0].lastModified)
        setParkingStartTime(applyTimezone(date.toISOString(), 3));

        setReadyToApply(true)
    }

    return (
        <>
            <FileInput
                ButtonAdditionalClassName={`file-upload-label`}
                inputID={`${targetRowId}-upload-photo`}
                handleInput={handleInput}
                pathToSaveFiles={"journal_parking"}
                proceed={proceed}
                setProceed={setProceed}
                setUploadedFilesLinks={setUploadedFilesLinks}
            />
        </>
    );
}

const SimpleInTableCheckbox = ({cell, changeHandler, targetEndpoint, justAlteredRowsRef, disabled, isChecked, helperText = "", passed_origin_ID}) => {
    const { userData } = useContext(MainContext);
    let defaultChecked = isChecked !== undefined ? isChecked : cell.value?.state;
    let origin_ID = passed_origin_ID ? passed_origin_ID : cell.row.original.origin_ID;
    
    const onChangeFunction = async (e) => {
        if (changeHandler) {
            changeHandler(e)
        } else {
            console.log("SimpleInTableCheckbox onChangeFunction", cell.value?.state)
            updateValues({
                auth_token: userData.nocodb_auth, 
                targetEndpoint,
                values: [
                    {
                        cell, 
                        newValue: e.target.checked,
                    }
                ]
            });

            justAlteredRowsRef.current.push(`${origin_ID}`)
        }

    }
    
    return (
        <>
            <input 
                type="checkbox" 
                className={`classyCheckbox`}
                id={`${origin_ID}-checkbox-${cell?.column?.id}`}
                defaultChecked={defaultChecked} 
                disabled={disabled} 
                onChange={(e) => {onChangeFunction(e)}}
                title={helperText}
            />
        </>
    );

}

const AllRowsSelection = ({ indeterminate, checked, onChange }) => {
    const { siteSettings } = useContext(MainContext);
    const inputRef = useRef();

    useEffect(() => {
        
        if (inputRef.current) {
            inputRef.current.indeterminate = indeterminate;
        }

    }, [inputRef, indeterminate]);
    

    // console.log("AllRowsSelection renders with", indeterminate, checked, onChange, inputRef.current)

    return (
        <input
            ref={inputRef}
            type="checkbox" 
            className='classyCheckbox selectAllRows'
            title={siteSettings.tooltips ? `${checked ? "Отменить выбор всех записей": "Добавить все записи"}` : ""}
            checked={checked}
            onChange={onChange}
        />
    );

}

const ToggleRowSelection = ({ cell, customTitle, selectionLimit = Infinity, selectedFlatRows, previouslySelectedRowRef}) => { 
    const { siteSettings } = useContext(MainContext);
    const [isChecked, setIsChecked] = useState(cell.row.isSelected);
    const defaultTitle = siteSettings.tooltips ? `${cell.row.isSelected ? "Добавить запись к отчетной ведомости" : "Не добавлять запись к ведомости"}` : ""
    let title = customTitle ? customTitle : defaultTitle;
    
    /**
     * selectedFlatRows argument will not include 
     * just selected row (the one handleInputChange() called on).
     */

    const handleInputChange = (e) => {

        if (e.target.checked && selectionLimit !== Infinity) {
            console.log("selectionLimit", selectionLimit);
            if ( selectedFlatRows.length > selectionLimit - 1 ) {
                selectedFlatRows.find(row => row.id !== previouslySelectedRowRef.current.id)
                    .toggleRowSelected(false)
                    
            }

        }
        
        setIsChecked(e.target.checked);
        cell.row.toggleRowSelected(e.target.checked);

        if (e.target.checked && !!previouslySelectedRowRef?.current) {
            previouslySelectedRowRef.current = cell.row;
        }

    }
    
    return (
        <input
            type="checkbox" 
            className='classyCheckbox selectRow'
            key={`rowIs${cell.row.isSelected ? "Selected" : "NotSelected"}`}
            title={title}
            checked={isChecked}
            onChange={handleInputChange}
        />
    );
}

const WithEnteranceHandling = ({
    SimpleInTableCheckbox, 
    cell, 
    justAlteredRowsRef, 
    depositsDataRef, 
    justUsedDepositsRef, 
    showOnlyActive, 
    setRowExists, 
    canBeMarkedAsEntered,
    freezeRef,
}) => {
    const { userData } = useContext(MainContext);
    const [confirmationRequiered, setConfirmationRequiered] = useState(false);
    const [confirmationSubject, setСonfirmationSubject] = useState(<code>error 💩</code>);
    const [canBeEdited, setCanBeEdited] = useState(false);
    
    useEffect(() => {
    
        if (canBeMarkedAsEntered) {
            setCanBeEdited(
                !shouldBeDisabled(userData, cell.row.original.updated_at)
            )
        }

    }, [canBeMarkedAsEntered]);

    useEffect(() => {
    
        if (confirmationRequiered) {
            freezeRef.current = true;
        } else {
            freezeRef.current = false;
        }

    }, [confirmationRequiered]);


    const fillEntryProperties = (token, roles, cell) => {
        let updated_at = new Date().toUTCString();

        let newValues = [
            {
                cell,
                newValue: true,
                targetProperty: "entered"
            },
            {
                cell,
                newValue: updated_at,
                targetProperty: "entered_at"
            }
        ];

        if (roles.includes('kpp1')) {
            // console.log("roles.includes('kpp1')", roles.includes('kpp1'))

            newValues.push(
                {
                    cell,
                    newValue: "КПП-1",
                    targetProperty: "entry_point"
                }
            )

        } else if (roles.includes('kpp2')) {
            // console.log("roles.includes('kpp2')", roles.includes('kpp2'))

            newValues.push(
                {
                    cell,
                    newValue: "КПП-2",
                    targetProperty: "entry_point"
                }
            )

        } else if (roles.includes('kpp3')) {
            // console.log("roles.includes('kpp3')", roles.includes('kpp3'))

            newValues.push(
                {
                    cell,
                    newValue: "КПП-3",
                    targetProperty: "entry_point"
                }
            )
        }

        // console.log("passing newValues to set", newValues)

        updateValues({
            auth_token: token,
            values: newValues
        })
        
        window.setTimeout(() => {
            focusMainSearch();
        }, 1500)

    }

    const hideInactiveEntry = () => {

        if (!cell.row.original?.daily?.state && showOnlyActive) {
            setRowExists(false);
        }

    }

    const cancelConfirmation = () => {
        const thisEnteredCheckbox = document.getElementById(`${cell.row.original.origin_ID}-checkbox-${cell?.column?.id}`)
        thisEnteredCheckbox.checked = false;
        setConfirmationRequiered(false)
    }
    
    const returnConfirmationContent = (depositsDataRef, cell, okFunction, useCredit) => {
        /**
         * data in cell can be stale, if fields where just updated 
         * and blur event never occured. To get certain values,
         * we have to look into the DOM - 4ms price on my laptop
         */

        let entryOriginID = cell.row.original.origin_ID;
        let thisRowElement = document.querySelector(`#journal-entry-id-${entryOriginID}`);
        let vehicleDestId = cell.row.original.dest[0].id;
        
        let vehicleNumberInputElement = thisRowElement.querySelector(".cell-in-number input"); 
        let vehicleNumber = vehicleNumberInputElement ? vehicleNumberInputElement.value : cell.row.original.number;

        let brandInputElement = thisRowElement.querySelector(".cell-in-brand .creatable-select__single-value");
        let vehicleBrand = getRidOfAliases(
            brandInputElement ? brandInputElement.textContent : cell.row.original.brand.brand
        );
        
        let categorySelectElement = thisRowElement.querySelector(".cell-in-category .select__single-value");
        let vehicleCategory = categorySelectElement ? categorySelectElement.textContent : cell.row.original.category;
        
        let commentInputElement = thisRowElement.querySelector(".cell-in-comment textarea");
        let vehicleComment = commentInputElement ? commentInputElement.value : cell.row.original.comment;

        let targetDeposit = depositsDataRef.current.find((dep) => {

            if (dep.dest_id === vehicleDestId && dep.category === vehicleCategory) {
                // double check if deposit is still active:
                if (dep.available >= 1) {
                    return true
                }
            }
            return false
        })
        
        if (!targetDeposit) {

            return (
                <>
                    <p className='confiration-message'>
                        Нет депозитов ({vehicleCategory}) для {vehicleBrand} {vehicleNumber}
                    </p>
                    <div className='boxProperty'>
                        <label htmlFor={`${entryOriginID}-verify-comment`}>Ваш комментарий</label>
                        <textarea 
                            id={`${entryOriginID}-verify-comment`} 
                            className='classyInput'
                            autoComplete="off"
                            defaultValue={vehicleComment}
                            spellCheck={false}
                        />
                    </div>
                    <div className='boxProperty'>
                        <button className='button' onClick={useCredit} >Допустить в кредит</button>
                        <button className='button' onClick={okFunction} >Допустить бесплатно</button>
                    </div>

                </>
            );

        } else {

            return (
                <>
                    <p className='confiration-message'>
                        {vehicleBrand} {vehicleNumber} допускается на территорию в счёт депозита:
                    </p>
                    <div className='boxProperty'>
                        <div className='deposit'>
                            <p className='deposit-destination'>{targetDeposit.dest}</p>
                            <p className='deposit-category'>
                                {targetDeposit.category}
                                <span className='deposit-available'>
                                    x{targetDeposit.available}
                                </span>
                            </p>
                        </div>
                        <button className='button' onClick={(e) => okFunction(e, targetDeposit)} >Да, в счёт депозита</button>
                    </div>
                    {   
                        targetDeposit.comment &&
                        <div className='boxProperty'>
                            <p className='deposit-comment'>{targetDeposit.comment}</p>
                        </div>
                    }
                    <div className='boxProperty'>
                        <label htmlFor={`${entryOriginID}-verify-comment`}>Ваш комментарий</label>
                        <textarea 
                            id={`${entryOriginID}-verify-comment`} 
                            autoComplete="off"
                            className='classyInput'
                            defaultValue={vehicleComment}
                            spellCheck={false}
                        />
                        <button className='button' onClick={okFunction} >Нет, бесплатно</button>
                    </div>

                </>
            );
        }
       

    }

    const onCheckboxChange = async (e) => {
        // console.log("onCheckboxChange", e)
        if (!e.target.checked) {
            
            await updateValues({
                auth_token: userData.nocodb_auth, 
                values: [
                    {
                        cell, 
                        newValue: false,
                        targetProperty: "entered",
                    },
                    {
                        cell, 
                        newValue: null,
                        targetProperty: "nc_xcf___deposits_id",
                    },
                    {
                        cell, 
                        newValue: false,
                        targetProperty: "paid",
                    },
                    {
                        cell, 
                        newValue: null,
                        targetProperty: "entered_at",
                    },
                    {
                        cell, 
                        newValue: null,
                        targetProperty: "entry_point",
                    }
                ]
            });
            
            justAlteredRowsRef.current.push(`${cell.row.original.origin_ID}`)
            
            return;
        }

        const credit = cell.row.original?.credit?.state;
        const destinationStatus = cell.row.original.dest[0]?.status;

        /**
         * allPossibleDestinationStatuses =
         * ["Строящийся", "Только по депозитам", "Служебный",
         * "Арендатор", "Внешний", "Свободный доступ", "Жилой",
         * "Весь транспорт платный", "На реконструкции", "Постоплата"]
         */

        // console.log("target destination status", destinationStatus)

        const markEntryEntered = (userData, cell) => {
            console.log("fillEntryProperties called with use roles", userData.roles)
            fillEntryProperties(userData.nocodb_auth, userData.roles, cell);
            justAlteredRowsRef.current.push(`${cell.row.original.origin_ID}`);
            hideInactiveEntry();
        }

        if (credit) {
            markEntryEntered(userData, cell);
        } else if ( ( destinationStatus?.includes("Свободный доступ") ||
            destinationStatus?.includes("Служебный") || 
            destinationStatus?.includes("Постоплата") ) 
                &&
            !destinationStatus?.includes("Только по депозитам") ) {

            markEntryEntered(userData, cell);

        } else if ( !destinationStatus ||
            destinationStatus?.includes("Арендатор") ||
            destinationStatus?.includes("Внешний") ||
            destinationStatus?.includes("Жилой") ||
            destinationStatus?.includes("Только по депозитам") ) { 
            
            let thisRowElement = document.getElementById(`journal-entry-id-${cell.row.original.origin_ID}`);
            
            let categorySelectElement = thisRowElement.querySelector(".cell-in-category .select__single-value")
            let categorySeenInUI = categorySelectElement ? categorySelectElement.textContent : cell.row.original.category
        
            if ( 
                (categorySeenInUI === "Легковой" && !destinationStatus?.includes("Весь транспорт платный")) ||
                !categorySeenInUI ||
                categorySeenInUI === "Пешеход"
            ) {
                
                markEntryEntered(userData, cell);
                
            } else {
                const doConfirmation = (event, usingDeposit = null) => {
                    let updatedComment = document.getElementById(`${cell.row.original.origin_ID}-verify-comment`).value;
                    
                    if (usingDeposit) {
                        const depID = usingDeposit.origin_ID;
                        let target = process.env.REACT_APP_SYS_DEPOSITS_ID
                        updateValues({
                            auth_token: userData.nocodb_auth, 
                            values: [
                                {
                                    cell, 
                                    newValue: depID,
                                    targetProperty: target,
                                },
                                {
                                    cell, 
                                    newValue: true,
                                    targetProperty: "paid",
                                }
                            ]
                        });

                        justUsedDepositsRef.current.push(depID);
                    }

                    updateValues({
                        auth_token: userData.nocodb_auth, 
                        values: [
                            {
                                cell, 
                                newValue: updatedComment,
                                targetProperty: "comment",
                            },
                            {
                                cell, 
                                newValue: true,
                                targetProperty: "entered",
                            }
                        ]
                    });

                    fillEntryProperties(userData.nocodb_auth, userData.roles, cell, e.target.checked);
                    justAlteredRowsRef.current.push(`${cell.row.original.origin_ID}`)
                    hideInactiveEntry();
                    setConfirmationRequiered(false)
                }

                const useCredit = () => {
                    let updatedComment = document.getElementById(`${cell.row.original.origin_ID}-verify-comment`).value;
                    
                    updateValues({
                        auth_token: userData.nocodb_auth, 
                        values: [
                            {
                                cell, 
                                newValue: updatedComment,
                                targetProperty: "comment",
                            },
                            {
                                cell, 
                                newValue: true,
                                targetProperty: "credit",
                            },
                            {
                                cell, 
                                newValue: true,
                                targetProperty: "entered",
                            }
                        ]
                    });

                    fillEntryProperties(userData.nocodb_auth, userData.roles, cell, e.target.checked);
                    justAlteredRowsRef.current.push(`${cell.row.original.origin_ID}`)
                    hideInactiveEntry();
                    setConfirmationRequiered(false)

                }

                setСonfirmationSubject(() => returnConfirmationContent(depositsDataRef, cell, doConfirmation, useCredit))
                setConfirmationRequiered(true);
            }

        }

    } 

    
    return (
        <>
            <ModalDialog isOpen={confirmationRequiered} exitFunction={cancelConfirmation}>
                <h1>Требуется подтверждение</h1>
                {confirmationSubject}
            </ModalDialog>
            <SimpleInTableCheckbox
                changeHandler={onCheckboxChange}
                cell={cell}
                justAlteredRowsRef={justAlteredRowsRef}
                disabled={!canBeEdited}
            />
        </>
    );
}

const ParkingConfirmatonContent = ({cell, doConfirmation, cancelParking}) => {
    const [readyToApply, setReadyToApply] = useState(false);
    const [uploadedFilesLinks, setUploadedFilesLinks] = useState();
    const [proceed, setProceed] = useState("idle"); // "idle", "start", "success", "failed"
    const [parkingStartTime, setParkingStartTime] = useState();
    
    useEffect(() => {

        if (parkingStartTime) {
            let textArea = document.getElementById(`${cell.row.original.origin_ID}-verify-comment`)
            let cleanText = textArea.value.replace(/\s\[начало парковки:\s.+\]/, "");

            textArea.value = cleanText + ` [начало парковки: ${parkingStartTime}]`

        }

    }, [parkingStartTime])

    useEffect(() => {

        if (proceed === "success") {
            // console.log("ParkingConfirmatonContent will do confirmation with", uploadedFilesLinks)
            window.setTimeout(() => {
                doConfirmation(uploadedFilesLinks)
            }, 1500)

        } else if (proceed === "failed") {
            
            console.log("Parking confirmation failed")

            window.setTimeout(() => {
                cancelParking();
            }, 4000)

        }
        
    }, [proceed]);



    const handleProceedParking = async () => {
        setProceed("start");
        setReadyToApply(false);
    }
    
    return (
        <>
            <p className='confiration-message'>
                Отметить платную парковку для {getRidOfAliases(cell.row.original?.brand?.brand)} {cell.row.original?.number}?
            </p>
            <div className='boxProperty'>
                <label htmlFor={`${cell.row.original.origin_ID}-verify-comment`}>Комментарий</label>
                <textarea 
                    id={`${cell.row.original.origin_ID}-verify-comment`} 
                    className='classyInput'
                    autoComplete="off"
                    defaultValue={cell.row.original.comment}
                    spellCheck={false}
                />
            </div>
            <div className='boxProperty'>
                <FileInputWithParkingHandling
                    FileInput={FileInput}
                    targetRowId={cell.row.original.origin_ID}
                    setReadyToApply={setReadyToApply}
                    readyToApply={readyToApply}
                    proceed={proceed}
                    setProceed={setProceed}
                    setUploadedFilesLinks={setUploadedFilesLinks}
                    setParkingStartTime={setParkingStartTime}
                />
            </div>
            <div className='boxProperty'>
                <button 
                    className='button' 
                    disabled={!readyToApply}
                    onClick={handleProceedParking}
                >
                    {
                        readyToApply === false && proceed === "start" 
                        ?
                        "Идёт загрузка"
                        :
                        proceed === "success" 
                        ?
                        "Фото загружены"
                        :
                        proceed === "failed" 
                        ?
                        "Что-то пошло не так, попробуйте ещё раз"
                        :
                        "Сообщить о начале стоянки"
                    }
                </button>
            </div>

        </>
    );
}

const WithParkingHandling = ({SimpleInTableCheckbox, cell, justAlteredRowsRef, freezeRef }) => {
    const { userData } = useContext(MainContext);
    const [confirmationRequiered, setConfirmationRequiered] = useState(false);
    const [confirmationSubject, setСonfirmationSubject] = useState(<code>error 💩</code>);
    /**
     * In NocoDB Parking data is stored in two columns:
     * first with 'parking' select "Парковка в кредит" or "Парковка оплачена",
     * second with loose 'comment' containing duration
     */

     useEffect(() => {
    
        if (confirmationRequiered) {
            freezeRef.current = true;
        } else {
            freezeRef.current = false;
        }

    }, [confirmationRequiered]);

    const cancelParking = () => {
        const thisCheckbox = document.getElementById(`${cell.row.original.origin_ID}-checkbox-${cell?.column?.id}`)
        thisCheckbox.checked = false;
        setConfirmationRequiered(false)
    }

    const isMarkingParkingAllowed = () => {

        if (cell.row.original.entered.state !== true) {
            return false
        }

        if (userData.roles.includes("patrol1") || userData.roles.includes('patrol2') || userData.roles.includes("admin")) {
            return wasEditedInLast24hours(cell.row.original.updated_at, 168);
        }

        return false
    }

    const onCheckboxChange = (e) => {

        if (!e.target.checked) {
            let thisRowElement = document.querySelector(`#journal-entry-id-${cell.row.original.origin_ID}`);
            
            let commentInputElement = thisRowElement.querySelector(".cell-in-comment textarea");
            let currentComment = commentInputElement ? commentInputElement?.value : cell.row.original.comment;

            let clearedComment;

            if ( currentComment ) {
                clearedComment = currentComment?.replace(/\s+?\[нач.+\]/g, "");
            } else {
                clearedComment = currentComment;
            }
            
            updateValues({
                auth_token: userData.nocodb_auth, 
                values: [
                    {
                        cell, 
                        newValue: null,
                        targetProperty: "parking",
                    },
                    {
                        cell, 
                        newValue: null,
                        targetProperty: "useful_photo",
                    },
                    {
                        cell, 
                        newValue: clearedComment,
                        targetProperty: "comment",
                    }
                ],
            });

            justAlteredRowsRef.current.push(`${cell.row.original.origin_ID}`)
            document.activeElement.blur();
            return;
        }
        
        const doConfirmation = async (fileLink) => {
            let updatedComment = document.getElementById(`${cell.row.original.origin_ID}-verify-comment`).value;
            let valuesToSet = [
                {
                    cell, 
                    newValue: updatedComment,
                    targetProperty: "comment",
                },
                {
                    cell, 
                    newValue: "Парковка в кредит",
                    targetProperty: "parking",
                }
            ]

            if (fileLink) {

                valuesToSet.push(
                    {
                        cell, 
                        addNewValue: fileLink,
                        targetProperty: "useful_photo",
                    }
                )

                const data = {
                    area_name: getRidOfAliases(cell.row.original.dest[0].name),
                    brand_name: getRidOfAliases(cell.row.original.brand.brand),
                    plate_number: cell.row.original.number,
                    files: fileLink,
                }

                const response = await notifySecurityChat({"parking-start": {data}});
                
                if (response?.status !== 200) {
                    window.alert("Парковка отмечена, но уведомление в чат не доставлено!")
                }

            }

            updateValues({
                auth_token: userData.nocodb_auth, 
                values: valuesToSet,
            });

            justAlteredRowsRef.current.push(`${cell.row.original.origin_ID}`)
            document.activeElement.blur();
            setConfirmationRequiered(false)

        }

        setСonfirmationSubject(
            <ParkingConfirmatonContent 
                cell={cell} 
                doConfirmation={doConfirmation} 
                cancelParking={cancelParking}
            />
        )
        setConfirmationRequiered(true);

    }

    return (
        <>
            <ModalDialog isOpen={confirmationRequiered} exitFunction={cancelParking}>
                <h1>Требуется подтверждение</h1>
                {confirmationSubject}
            </ModalDialog>
            <SimpleInTableCheckbox
                changeHandler={onCheckboxChange}
                cell={cell}
                justAlteredRowsRef={justAlteredRowsRef}
                disabled={!isMarkingParkingAllowed()}
                isChecked={!!cell.value}
                helperText={cell.value}
            />
        </>
    );

}

const TextInput = ({ 
      cell,
      targetProperty, // use to override cell's column id
      textArea = false, 
      onlyNumber = false, 
      targetEndpoint = "journal", 
      allowedRoles = [], 
      dependantValuesToSet,
      customDisablingFunction, 
      customDefaultValue }) => {
    const { userData } = useContext(MainContext);
    let disabled = true;
    let defaultValue = cell.value;
    
    if (customDefaultValue) {
        defaultValue = customDefaultValue;
    } 

    if (!customDisablingFunction) {
        
        if (allowedRoles.length > 0) {
    
            allowedRoles.forEach(role => {
    
                if (userData.roles.includes(role)) {
                    disabled = !wasEditedInLast24hours(cell.row.original.updated_at);
                }
    
            })
    
        } else {
            disabled = shouldBeDisabled(userData, cell.row.original.updated_at);
        }
        
    } else {
        disabled = customDisablingFunction(userData.roles);
    }


    const onBlur = (e) => {
        if (cell.value === null && e.target.value === "") {
            return
        }

        if (`${cell.value}` !== `${e.target.value}` ) {
            
            let valuesToSet = [{
                newValue: e.target.value,
                cell
            }]

            if (dependantValuesToSet) {

                for (let v of dependantValuesToSet) {
                    valuesToSet.push({
                        newValue: v.value,
                        targetProperty: v.name,
                        cell
                    })
                }
                
            }


            if (targetProperty) {
                valuesToSet.targetProperty = targetProperty;
            }

            updateValues({
                auth_token: userData.nocodb_auth,
                targetEndpoint: targetEndpoint,
                values: valuesToSet
                
            })
            
        }

    }

    return (
        <>
            { textArea === true ?
                <textarea
                    autoComplete="off"
                    className={`classyInput`}
                    defaultValue={defaultValue}
                    onBlur={(e) => {onBlur(e)}}
                    spellCheck={false}
                    disabled={disabled}
                >
                
                </textarea>
                :
                <input 
                    autoComplete="off"
                    className={`classyInput`}
                    defaultValue={defaultValue}
                    onBlur={(e) => {onBlur(e)}}
                    disabled={disabled}
                    type={onlyNumber ? "number" : "text"}
                /> 
            }
        </>
    );

}

const DeleteRowButton = ({cell, deletingRowsRef, justAlteredRowsRef, tableDataRef, setRowExists, target = "journal" }) => {
    const { userData } = useContext(MainContext);
    let isDisabled = true;
    let handleClickOnDelete;

    if ( target === "journal") {

        if (userData.roles.includes('admin')) {
            isDisabled = false;
        } else if (userData.roles.includes('dezhurniy') && cell.row.original.is_active) {
            isDisabled = false;
        }

        handleClickOnDelete = () => {
            deleteJournalRow(userData.nocodb_auth, cell, deletingRowsRef, justAlteredRowsRef, tableDataRef, setRowExists)
        }

    } else if (target === "deposit") {

        if (cell.row.original.entered_vehicles_count < 1) {
            isDisabled = false;
        }

        handleClickOnDelete = () => {
            
            deleteRowWithTimeout({
                target: "deposit",
                nocodb_auth: userData.nocodb_auth,
                cell, 
                deletingRowsRef, 
                justDeletedRowsRef: justAlteredRowsRef, 
                tableDataRef, 
                setRowExists
            })

        }
        
    } else if (target === "brand") {
        
        if (
            cell.row.original.blacklist_count === "0" 
            && cell.row.original.journal_count === "0" 
            && cell.row.original.local_vehicles_count === "0" 
        ) {
            isDisabled = false;
        }

        handleClickOnDelete = () => {

            deleteRowWithTimeout({
                target: "brand",
                nocodb_auth: userData.nocodb_auth,
                cell, 
                deletingRowsRef, 
                justDeletedRowsRef: justAlteredRowsRef, 
                tableDataRef, 
                setRowExists
            })

        }

    }

    
    return (
        <button 
            key={`${target}-delete-row-${cell.row.id}`}
            className='delete-row minimal' 
            id={`${target}-delete-row-${cell.row.id}`} 
            tabIndex={-1} 
            disabled={isDisabled}
            onClick={handleClickOnDelete}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-trash" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <line x1="4" y1="7" x2="20" y2="7" />
                <line x1="10" y1="11" x2="10" y2="17" />
                <line x1="14" y1="11" x2="14" y2="17" />
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" className="icom-undo" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M15 4.55a8 8 0 0 0 -6 14.9m0 -4.45v5h-5" />
                <line x1="18.37" y1="7.16" x2="18.37" y2="7.17" />
                <line x1="13" y1="19.94" x2="13" y2="19.95" />
                <line x1="16.84" y1="18.37" x2="16.84" y2="18.38" />
                <line x1="19.37" y1="15.1" x2="19.37" y2="15.11" />
                <line x1="19.94" y1="11" x2="19.94" y2="11.01" />
            </svg>
        </button>
    );
}


const ProlongDeposit = ({ isDisabled, cell, userData, tooltips, setEditedDepositId, justAlteredRowsRef}) => {
    const [disabled, setDisabled] = useState(isDisabled);

    const handleClickOnProlongDeposit = async () => {
        setDisabled(true);
        const result = await prolongDeposit(userData, cell.row.original.origin_ID);
        
        if (result?.status === 200) {
            justAlteredRowsRef.current.push(cell.row.original.origin_ID)
            setEditedDepositId(cell.row.original.origin_ID)
        } else {
            setDisabled(false);

            window.alert("Продлить срок действия депозита не удалось, сообщите администратору")
        }
    }

    return (
        <button
            key={`prolong-deposit-${cell.row.id}`}
            className='prolong-deposit' 
            id={`prolong-deposit-${cell.row.id}`} 
            tabIndex={-1} 
            disabled={disabled}
            onClick={handleClickOnProlongDeposit}
            title={tooltips && !isDisabled ? "Убрать ограничение в сроке действия депозита" : ""}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-infinity" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M9.828 9.172a4 4 0 1 0 0 5.656a10 10 0 0 0 2.172 -2.828a10 10 0 0 1 2.172 -2.828a4 4 0 1 1 0 5.656a10 10 0 0 1 -2.172 -2.828a10 10 0 0 0 -2.172 -2.828" />
            </svg>
        </button>
    );
}

const onSelectInputChange = (value, currentState, setCurrentState, allOptionsRef, action) => {
    if (action === 'input-change') {
        let filteredOptions = matchSorter(currentState, value, { keys: ['label']});
        setCurrentState(filteredOptions);

        if (value === "" || value === []) {
            setCurrentState(allOptionsRef.current);
        }

    } else if (action === 'menu-close') {
        setCurrentState(allOptionsRef.current);   
    }
}

export { 
    wasEditedInLast24hours,
    CreatableBrandSelect,
    ParkingTypeSelect, 
    CategorySelect, 
    SimpleInTableCheckbox,
    ToggleRowSelection,
    AllRowsSelection,
    WithEnteranceHandling,
    WithParkingHandling,
    TextInput,
    DeleteRowButton,
    ProlongDeposit,
    FileInput,
    FileInputWithParkingHandling,
    onSelectInputChange,
    CarIdIssueSelect,
};