import React, { useCallback, useContext } from 'react';
import UserService from '../services/DirectusUserService';
import { MainContext } from './MainContext';
import { useNavigate } from 'react-router-dom';
const NotWelcomePage: React.FC = () => {
  const { userData, setIsAuthenticated } = useContext(MainContext);
  const navigate = useNavigate();
  const username = userData?.username;

  const handleLogout = useCallback(async () => {
    try {
      await UserService.doLogout();
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      // Handle logout error (e.g., show error message)
    }
  }, [setIsAuthenticated, navigate]);

  return (
    <div id="welcome-container">
      <h1>Ресурс не существует или доступ ограничен</h1>
      <p className="username">Вы вошли как <span>{username}</span></p>
      <button
        className='button logout'
        onClick={handleLogout}
      >
        Выйти из учётной записи
      </button>
    </div>
  );
};

export default NotWelcomePage;
