import React, { useState } from 'react';

import InTableFilesPreview from "../commonUI/InTableFilesPreview";
import { updateValues } from "../../services/CRUDJournalData";
import RemoveFromBlacklistDialog from './RemoveFromBlacklistDialog';


const BlacklistItem = ({ item, canBeReleased = false, detailsExpanded = false, setConfirmationRequiered, confirmationSubjectRef }) => {
    const [photoVisible, setPhotoVisible] = useState(detailsExpanded);
    const [existance, setExistance] = useState(true);

    const handleClickOnBlacklistRelease = () => {

        const archiveBlacklistedCar = async(auth_token, itemID, newComment) => {

            try {
                await updateValues({
                    auth_token: auth_token,
                    targetEndpoint: "blacklist",
                    values: [
                        {
                            rowID: itemID,
                            newValue: true,
                            targetProperty: "archived"
                        },
                        {
                            rowID: itemID,
                            newValue: newComment,
                            targetProperty: "comment"
                        },
                        
                    ]
                })

                window.setTimeout(() => {
                    setExistance(false);
                    setConfirmationRequiered(false);
                }, 4000)

                confirmationSubjectRef.current = (
                    <div className='blacklist-confirmation-container'>
                        <h1>{item.number} убрана из черного списка</h1>
                    </div>
                );

            } catch (err) {
                console.log(err)

                confirmationSubjectRef.current = (
                    <div className='blacklist-confirmation-container'>
                        <h1>Произошла ошибка</h1>
                        <p>Пожалуйста, попробуйте ещё раз</p>
                    </div>
                );

            }
        }

        confirmationSubjectRef.current = (
            <RemoveFromBlacklistDialog
                car={item}
                confirmFunction={archiveBlacklistedCar}
                setConfirmationRequiered={setConfirmationRequiered}
            />
        );

        setConfirmationRequiered(true);

    }

    let BLIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" className="shield-x" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3" />
            <path d="M10 10l4 4m0 -4l-4 4" />
        </svg>
    );

    let photoIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon-photo" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="15" y1="8" x2="15.01" y2="8" />
            <rect x="4" y="4" width="16" height="16" rx="3" />
            <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
            <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
        </svg>
    );

    const commentIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" className="message-importante" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
            <line x1="12" y1="8" x2="12" y2="11" />
            <line x1="12" y1="14" x2="12" y2="14.01" />
        </svg>
    );


    let archiveButton = (
        <div id="release-from-blacklist-container">
            <button 
                id="release-from-blacklist-button" 
                className='button'
                onClick={handleClickOnBlacklistRelease} 
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="icon-blacklist-off" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="3" y1="3" x2="21" y2="21" />
                    <path d="M17.669 17.669a12 12 0 0 1 -5.669 3.331a12 12 0 0 1 -8.5 -15c.797 .036 1.589 0 2.366 -.126m3.092 -.912a12 12 0 0 0 3.042 -1.962a12 12 0 0 0 8.5 3a12 12 0 0 1 -1.117 9.379" />
                </svg>
            </button>
        </div>
    );  


    return (
        <div className='bad-guest' style={{"display": `${!existance ? "none" : "inherit"}`}}>
            <p className='bad-guest-number'>
                <i>{BLIcon}</i>
                {item.number}
                {
                    item?.comment && !detailsExpanded &&
                    <i className="bad-guest-comment" title={item.comment}>
                        {commentIcon}
                    </i>
                }
            </p>
            <p className='bad-guest-brand'>{item.brand}</p>
            {
                ((detailsExpanded && item?.comment) || item?.files) &&
                <div className='bl-details-container'>
                    {
                        item?.comment && detailsExpanded &&
                        <p className='bad-guest-comment expanded'>
                            {item.comment}
                        </p>
                    }
                    {
                        item?.files && !photoVisible &&
                        <button 
                            className="button bl-detail" 
                            title={`Смотреть ${item?.files.length} фото`}
                            onClick={() => { setPhotoVisible(true) }}
                        >
                            {photoIcon}
                        </button>
                    }
                    {   
                        item?.files && photoVisible &&
                        <div className='bad-guest-photo-container'>
                            <InTableFilesPreview
                                files={item.files}
                            />
                        </div>
                    }
                </div>
            }
            {
                item.relatedObject &&
                <p className='bad-guest-related-object'>{item.relatedObject}</p>
            }
            <p className='bad-guest-sanction'>{item.sanction}</p>
            {
                canBeReleased && 
                archiveButton
            }
        </div>
    );
    
}


function propsAreEqual(prev, next) {

    if (prev.item.origin_ID === next.item.origin_ID) {

        if (prev.item.updated_at_full === next.item.updated_at_full) {
            return true
        }

    }

    return false;
}

const BlacklistItemMemoised = React.memo(BlacklistItem, propsAreEqual)

export default BlacklistItemMemoised;