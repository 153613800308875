import React, { useState, useContext } from 'react';
import { 
    CreatableBrandSelect, 
    CategorySelect,
    TextInput, 
    DeleteRowButton,
    SimpleInTableCheckbox,
    WithEnteranceHandling,
    WithParkingHandling,
} from "../inputElements";
import InTableFilesPreview from "../commonUI/InTableFilesPreview";
import { MainContext } from '../MainContext';
import { allSecurityRoles } from "../../App";
import { getRidOfAliases } from "../../services/littleHelpers";

export const JournalRow = ({ 
    row, 
    allBrandsOptionsRef, 
    areBrandsLoading, 
    tableDataRef, 
    deletingRowsRef, 
    justAlteredRowsRef, 
    depositsDataRef, 
    justUsedDepositsRef,
    canBeMarkedAsEntered,
    showOnlyActive,
    freezeRef,

}) => {

    const { userData, siteSettings } = useContext(MainContext);
    const [rowExists, setRowExists] = useState(true);
    
    if (!row) { 
        return <></>
    }

    const cellRenderFunction = (cell) => {

        let backhoeIcon = 
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-backhoe" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="4" cy="17" r="2" />
                <circle cx="13" cy="17" r="2" />
                <line x1="13" y1="19" x2="4" y2="19" />
                <line x1="4" y1="15" x2="13" y2="15" />
                <path d="M8 12v-5h2a3 3 0 0 1 3 3v5" />
                <path d="M5 15v-2a1 1 0 0 1 1 -1h7" />
                <path d="M21.12 9.88l-3.12 -4.88l-5 5" />
                <path d="M21.12 9.88a3 3 0 0 1 -2.12 5.12a3 3 0 0 1 -2.12 -.88l4.24 -4.24z" />
            </svg>

        if (cell.column.id === "dest") {
            let destElements = [];

            cell.value?.forEach(d => {

                destElements.push(
                    <span 
                        className={(d?.status?.includes("Строящийся") || d?.status?.includes("На реконструкции")) ? "on-construction" : ""} 
                        title={siteSettings.tooltips && d.name + `${d?.status ? " — " + d?.status.replace(",", ", ") : ""}`}
                        key={`dest-in-${cell.row.id}-row-${d.id}`}
                    >
                        {(d?.status?.includes("Строящийся") || d?.status?.includes("На реконструкции")) && backhoeIcon}
                        <p>{getRidOfAliases(d.name)}</p>
                    </span>
                )

            });

            return (
                destElements
            );

        } else if (cell.column.id === "created_by") {
            
            if (!cell.value) {
                return
            }

            if (cell.value.includes(",")) {

                return (
                    cell.value.split(",").map((d, i) => 
                        <span key={`created_by-${i}`}>{d}</span>
                    )
                )

            } else {

                return <span>{cell.value}</span>

            }


        } else if (cell.column.id === "entry_point") {

            if (cell.value) {
                return <span>{cell.value}</span>
            }

        } else if (cell.column.id === "brand") {
            return (
                <CreatableBrandSelect 
                    cell={cell} 
                    allOptionsRef={allBrandsOptionsRef}
                    isLoading={areBrandsLoading}
                    localStorageName={"brandsOptions=all"}
                />
            );
        } else if (cell.column.id === "category") {
            return (
                <CategorySelect
                    cell={cell}
                />
            );
        } else if (
            cell.column.id === "credit" ||
            cell.column.id === "daily"
        ) {

            return <SimpleInTableCheckbox 
                cell={cell} 
                justAlteredRowsRef={justAlteredRowsRef} 
                disabled={!userData.roles.includes("admin")}    
                />

        } else if (cell.column.id === "entered") {

            return <WithEnteranceHandling
                SimpleInTableCheckbox={SimpleInTableCheckbox}
                cell={cell}
                justAlteredRowsRef={justAlteredRowsRef}
                setRowExists={setRowExists}
                depositsDataRef={depositsDataRef}
                justUsedDepositsRef={justUsedDepositsRef}
                canBeMarkedAsEntered={canBeMarkedAsEntered}
                freezeRef={freezeRef}
                showOnlyActive={showOnlyActive}
            />

        } else if (cell.column.id === "parking_type") {

            return <WithParkingHandling
                SimpleInTableCheckbox={SimpleInTableCheckbox}
                cell={cell}
                justAlteredRowsRef={justAlteredRowsRef}
                freezeRef={freezeRef}
            />

        } else if (cell.column.id === "number") {

            return <TextInput
                cell={cell}
            />

        } else if (cell.column.id === "comment") {

            return <TextInput
                cell={cell}
                textArea={true}
                allowedRoles={allSecurityRoles}
            />

        } else if (cell.column.id === "delete") {
            
            return <DeleteRowButton
                cell={cell}
                deletingRowsRef={deletingRowsRef}
                justAlteredRowsRef={justAlteredRowsRef}
                tableDataRef={tableDataRef}
                setRowExists={setRowExists}
            />

        } else if (cell.column.id === "useful_photo") {
            
            return (
                <InTableFilesPreview
                    files={cell.value}
                />
            );

        }

        return cell.render('Cell');
    }

    // console.log("!!! JournalRow renders with row ", row)
    // console.log("JournalRow renders", row.original.origin_ID)
    // console.log("!!! JournalRow renders with tableDataRef", tableDataRef)
    const { key, ...rowProps } = row?.getRowProps() || {};

    try {

        return (
            <>
                <tr 
                    key={`row-updated-at-${row?.original?.updated_at_full}-${rowExists && "exist"}-${key}`}
                    id={`journal-entry-id-${row?.original?.origin_ID}`}
                    className={`journal-entry ${!rowExists ? "deleted" : ""}`}
                    {...rowProps}
                >
                    {
                        row ?
                        row?.cells.map((cell, _i) => {
                          const { key, ...cellProps } = cell.getCellProps();
                          return (
                            <td 
                                className={`cell-in-${cell.column.id} boxProperty`} 
                                key={`${cell.row.original.origin_ID}-${cell.column.header}-${key}`}
                                {...cellProps}
                            >
                                { cellRenderFunction(cell) }
                            </td>
                          );
                        })
                        :
                        <></>
                    }
                </tr>
            </>
        );
    
    } catch (error) {
        console.log("JournalRow got error after prepareRow", error)
        return <></>
    }
}


function rowsPropsAreEqual(prevRow, nextRow) {
    // console.log("Memoized row ", nextRow?.canBeMarkedAsEntered)
    
    // let updatedElementHasFocusWithin = document.getElementById(`journal-entry-id-${prevRow?.row?.original?.origin_ID}`)?.contains(document.activeElement);
    
    // if (updatedElementHasFocusWithin) {
    //     return true
    // }

    if (
        prevRow?.row?.original?.origin_ID === nextRow?.row?.original?.origin_ID
            &&
        prevRow?.row?.original?.dest?.[0]?.id === nextRow?.row?.original?.dest?.[0]?.id
            &&
        prevRow?.canBeMarkedAsEntered === nextRow?.canBeMarkedAsEntered
    ) {

        return true

    } else {

        return false

    }
  }

const JournalRowMemoised = React.memo(JournalRow, rowsPropsAreEqual)

export default JournalRowMemoised;