import reactStringReplace from 'react-string-replace';

const phoneCallIcon = <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon-phone-call"
    width={44}
    height={44}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
    <path d="M15 7a2 2 0 0 1 2 2" />
    <path d="M15 3a6 6 0 0 1 6 6" />
</svg>


const formatPhoneNumber = (sourceString) => {
    let replacedText;
    const rusPhoneRegex = /(\+?[7-8][\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2})/g;

    const clearAndFormatNumber = (number) => {
        let cleared, formatted;
        cleared = number.replace(/(\s+)|(-)|(\+)|(\()|(\))/g, "").replace(/^(8)|^(7)/,"+7")
        formatted = cleared.replace(/.{2}/, '$& ').replace(/.{3}/, '$&(').replace(/.{7}/, '$&) ').replace(/.{12}/, '$&-').replace(/.{15}/, '$&-')
        return {formatted, cleared};
    }

    replacedText = reactStringReplace(sourceString, rusPhoneRegex, (match, i) => {

        let processed = clearAndFormatNumber(match);

        return (
            <span key={match + i} className="phone-number">
                <a href={`tel:${processed.cleared}`}>{phoneCallIcon}</a>
                {processed.formatted}
            </span>
        );
    });

    return replacedText;
}

export default formatPhoneNumber;