import React, { useState, useEffect } from 'react';

import { initialTitleTooltips } from "../settings/tooltipsText"

const RevealAllColumns = ({
    hiddenColumns, 
    thisInitialHiddenColumns, 
    setHiddenColumns, 
    showTooltips = true}) => {
        
    let [revealAll, setRevealAll] = useState(hiddenColumns.length >= thisInitialHiddenColumns.length);

    useEffect(() => {
        if (hiddenColumns.length >= thisInitialHiddenColumns.length) {
            setRevealAll(true)
        } else {
            setRevealAll(false)
        }
    }, [hiddenColumns.length, thisInitialHiddenColumns.length]);


    const handleClick = () => {
        if (hiddenColumns.length < thisInitialHiddenColumns.length) {
            setHiddenColumns(thisInitialHiddenColumns)
        } else if (thisInitialHiddenColumns.length < hiddenColumns.length) {
            setHiddenColumns(thisInitialHiddenColumns)
        } else {
            setHiddenColumns([])
        }
    }
    
    const getTitle = (revealAll) => {

        if (!showTooltips) {
            return ""
        }

        if (!revealAll) {
            return initialTitleTooltips.table.showAllColumnsReverse
        }

        if (revealAll && thisInitialHiddenColumns.length >= hiddenColumns.length) {
            return initialTitleTooltips.table.showAllColumns;
        } else {
            return initialTitleTooltips.table.showInitialColumns;
        }

    }
    
    return (
        <button 
            className={revealAll ? 'expand-columns' : 'expand-columns reverse' } 
            onClick={handleClick} 
            title={getTitle(revealAll)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-show-all" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 13v-8l-3 3m6 0l-3 -3" />
                {
                    thisInitialHiddenColumns.length >= hiddenColumns.length ?
                    <>
                        <line x1="9" y1="17" x2="10" y2="17" />
                        <line x1="14" y1="17" x2="15" y2="17" />
                        <line x1="19" y1="17" x2="20" y2="17" />
                        <line x1="4" y1="17" x2="5" y2="17" />
                        <line x1="9" y1="21" x2="10" y2="21" />
                        <line x1="14" y1="21" x2="15" y2="21" />
                        <line x1="19" y1="21" x2="20" y2="21" />
                        <line x1="4" y1="21" x2="5" y2="21" />
                    </>
                    :
                    <>
                        <line x1="9" y1="19" x2="10" y2="19" />
                        <line x1="14" y1="19" x2="15" y2="19" />
                        <line x1="19" y1="19" x2="20" y2="19" />
                        <line x1="4" y1="19" x2="5" y2="19" />
                    </>
                }
            </svg>
        </button>
    );
}

export default RevealAllColumns;