import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');
const theRoot = createRoot(rootElement);

function renderApp() {
  theRoot.render(
    // <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    // </React.StrictMode>
  )
}


// UserService.initKeycloak(renderApp);
renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
