import { QuerySort, aggregate, readItems } from "@directus/sdk";
import { Collections, Schema, Types } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";
import { getDirectusDateFilter, reduceDirectusFilesToSringArray } from "../DirectusServices/directusHelpers";
import { formatDateTime } from "../dateTimeHelpers";

type LandObject = {
  Id: number;
  area_name: string;
  status: string;
};

export type PedestrianRowData = {
  dest: Array<LandObject>,
  name: string,
  comment: string,
  docs: Array<Types.UUID>,
  updated_at: Types.DateTime,
  updated_at_full: Types.DateTime,
  created_at: Types.DateTime,
  archived: boolean,
  origin_ID: number,
}

export type ProvideLocalPedestriansOptions = {
  pageNo?: number,
  recordsLimit?: number,
  filters?: Array<{ id: string, value: string | number | boolean }>,
  currentViewFilterCondition?: string,
  sortString?: Array<QuerySort<Schema, Collections.Pedestrians>>,
  controller?: AbortController,
}


export async function provideLocalPedestrians({
  // auth_token,
  pageNo = 0,
  recordsLimit = 50,
  filters = [],
  currentViewFilterCondition,
  sortString = ["-updated_at"],
  controller = undefined,
}: ProvideLocalPedestriansOptions) {
  const client = getDirectusClient();
  const entries: Array<PedestrianRowData> = [];

  const filter = filters.reduce((acc, current) => {
    if (current.id === "origin_ID") {
      acc["_and"].push({
        "id": {
          "_eq": current.value
        }
      });
    } else if (current.id === "dest") {
      acc["_and"].push({
        "lands_n_objects": {
          "lands_n_objects_id": {
            "area_name": {
              "_icontains": current.value
            }
          }
        }
      });
    } else if (["updated_at", "created_at"].includes(current.id)) {
      const dateConditions = getDirectusDateFilter({
        dateField: current.id,
        dateString: current.value as string,
      })
      acc["_and"] = acc["_and"].concat(dateConditions);
    } else if (["archived", "entered", "credit", "paid"].includes(current.id)) {
      const bool = current.value === "true" || current.value === "TRUE" || current.value === 1 || current.value === true ? true : false;
      acc["_and"].push({
        [current.id]: {
          "_eq": bool
        }
      });
    } else {
      acc["_and"].push({
        [current.id]: {
          "_icontains": current.value
        }
      });
    }
    return acc;
  }, { "_and": [] });

  if (currentViewFilterCondition?.includes("archived,eq,false")) {
    filter["_and"].push({
      archived: {
        _eq: false
      }
    });
  }

  const totalCountRequest = await client.request(aggregate("pedestrians", {
    aggregate: {
      count: "*"
    },
    query: {
      filter: filter
    }
  }));

  const totalCount = (totalCountRequest?.[0]?.count && parseInt(totalCountRequest?.[0]?.count, 10)) || 0;

  const response = await client.request(readItems("pedestrians", {
    limit: recordsLimit,
    offset: pageNo * recordsLimit,
    sort: sortString,
    filter,
    fields: [
      "id",
      "archived",
      "comment",
      "name",
      {
        "document": ["directus_files_id"]
      },
      "updated_at",
      "created_at",
      {
        "lands_n_objects": [
          {
            "lands_n_objects_id": ["area_name", "id", "status"]
          }
        ]
      }
    ],
    signal: controller.signal,
  }));


  response.forEach(entry => {
    const rawObjects = entry?.lands_n_objects || [];

    const objects: LandObject[] = rawObjects.reduce((acc, current) => {
      const objFormatted: LandObject = {
        Id: current.lands_n_objects_id.id,
        area_name: current.lands_n_objects_id.area_name,
        status: current.lands_n_objects_id.status?.join(", ")
      }

      acc.push(objFormatted)

      return acc;
    }, [])



    entries.push(
      {
        name: entry.name,
        comment: entry.comment,
        updated_at: formatDateTime(entry?.updated_at),
        updated_at_full: entry?.updated_at,
        created_at: formatDateTime(entry?.created_at),
        docs: reduceDirectusFilesToSringArray(entry.document),
        dest: objects,
        origin_ID: entry.id,
        archived: entry.archived,
      }
    );

  });


  return { count: totalCount, data: entries };

}
