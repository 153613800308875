import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';


const TableFakeFooter = ({
    isLoading = false,
    prefix, 
    tableDataRef, 
    totalEntries,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    pageIndex,
    totalPage,
    recordsPerPage,
    setRecordsPerPage,
    allowedRezultsPerPageArray = [10, 20, 30, 50],
    minimalControls = false,
}) => {
    const [options, setOptions] = useState([]);
    const [pageSizeValue, setPageSizeValue] = useState(options?.[0]);
    const firstRender = useRef(true);

    useEffect(() => {
        if (minimalControls) {
            return
        }

        if (firstRender.current && allowedRezultsPerPageArray) {

            let temp = [];
        
            allowedRezultsPerPageArray.forEach(pageSize => {
                let value = { value: pageSize, label: `показывать по ${pageSize}` }
    
                temp.push(value)

                if (recordsPerPage === pageSize) {
                    setPageSizeValue(value)
                }
        
            })
    
            setOptions(temp)
            firstRender.current = false;
            
        } else {

            allowedRezultsPerPageArray.forEach(pageSize => {
                let value = { value: pageSize, label: `показывать по ${pageSize}` }

                if (recordsPerPage === pageSize) {
                    setPageSizeValue(value)
                }

            })
        }

    }, [allowedRezultsPerPageArray, recordsPerPage]);

    const handlePageSizeChange = (e) => {
        setPageSizeValue(e)
        setRecordsPerPage(e.value)
    }
    
    return (
        <div id={`${prefix}-table-fake-footer-container`}>
            <div id={`${prefix}-table-fake-footer`}>
                <p 
                    style={{'opacity': `${isLoading ? 0 : 1}`, pointerEvents: `${isLoading ? "none" : 'inherit'}`}} 
                    key={"results-message-block"}
                >
                    Показано {Math.max(page.length, tableDataRef.current.length)} из {totalEntries.current - (page.length - tableDataRef.current.length)} записей
                </p>
                {
                    totalPage > 1 &&
                    <p
                        style={{'opacity': `${isLoading ? 0 : 1}`, pointerEvents: `${isLoading ? "none" : 'inherit'}`}} 
                    >
                        Страница {pageIndex + 1} из {totalPage}
                    </p>
                }
                <div 
                    id={`${prefix}-pagination-controls`}
                    style={{'opacity': `${isLoading ? 0 : 1}`, pointerEvents: `${isLoading ? "none" : 'inherit'}`}} 

                >
                    {
                        totalPage > 1 &&
                        <div className='pagination-buttons-container'>
                            <button className="button pagination fast" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                            </button>{' '}
                            <button className="button pagination" onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                            </button>{' '}
                            <button className="button pagination" onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                            </button>{' '}
                            <button className="button pagination fast" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                            </button>{' '}
                        </div>
                    }
                    {
                        totalPage > 1 && gotoPage && setRecordsPerPage &&
                        <>
                            <span className='go-to-page'>
                                <p>Перейти к странице:</p>
                                <input
                                    type="number"
                                    value={pageIndex + 1}
                                    max={totalPage}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    
                                />
                            </span>{' '}
                            <div className='records-per-page boxProperty'>
                                <Select
                                    id={`${prefix}-records-per-page`}
                                    onChange={handlePageSizeChange}
                                    options={options}
                                    placeholder=""
                                    isSearchable={false}
                                    classNamePrefix="select"
                                    value={pageSizeValue}
                                    captureMenuScroll={true}
                                    closeMenuOnScroll={true}
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default TableFakeFooter;