import React, { useState, useEffect, useRef } from 'react';

import { initialTitleTooltips } from "../settings/tooltipsText"


const ColumnSettings = ({column, columns, hiddenColumns, setHiddenColumns, thisInitialHiddenColumns, showTooltips = true}) => {
    const [isActive, setIsActive] = useState(false);
    const [settingsDisabled, setSettingsDisabled] = useState(true);
    const containerRef = useRef();

    const closeOtherControlls = async () => {

        let thead = containerRef.current.closest('thead');
        let otherExpandedContainers = thead.querySelectorAll('.column-settings-container[expanded$="true"]');

        if (otherExpandedContainers.length > 1) {

            otherExpandedContainers.forEach(e => {
                
                if (e !== containerRef.current) {
                    e.setAttribute('expanded', 'false')
                }
                
            }); 

        }
        
    }

    useEffect(() => {
        let controlsDisablingTimeoutID, controlsVisibilityTimeout;

        if (containerRef?.current.getAttribute('expanded') === 'true') {
            
            controlsDisablingTimeoutID = window.setTimeout(() => {
                setSettingsDisabled(false)
            }, 250)
            
            controlsVisibilityTimeout = window.setTimeout(() => {
                setIsActive(false)
                containerRef?.current.setAttribute('expanded', 'false')

                if (containerRef?.current?.contains(document.activeElement)) {
                    document.activeElement.blur();
                }

            }, 5000)

            closeOtherControlls();

        } else {
            setSettingsDisabled(true)
        }

        return () => {
            controlsDisablingTimeoutID && clearTimeout(controlsDisablingTimeoutID)
            controlsVisibilityTimeout && clearTimeout(controlsVisibilityTimeout)
        }

    }, [isActive])

    const revealControls = () => {
        setIsActive(s => !s);
        containerRef?.current.setAttribute('expanded', 'true')
    }

    const hideColumn = (column) => {
        if (hiddenColumns.length < columns.length) {
            column.toggleHidden()
        } if (hiddenColumns.length === columns.length - 1) {
            setHiddenColumns(thisInitialHiddenColumns)
        }
    }

    return (
        <>
            <div 
                ref={containerRef} 
                className={`column-settings-container`}
                expanded={`false`}   
            >
                <button 
                    className='reveal-controls' 
                    onClick={revealControls} 
                    tabIndex={-1} 
                    title={showTooltips ? "Свойства колонки" : ""} 
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon-settings" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                        <circle cx="12" cy="12" r="3" />
                    </svg>
                </button>
                <button 
                    className='column-setting hide-column' 
                    onClick={() => {hideColumn(column)}} 
                    disabled={settingsDisabled}
                    tabIndex={-1} 
                    title={showTooltips ? initialTitleTooltips?.table?.hideColumn.replace("%NAME", `${column.Header}`) : ""} 
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon-fold" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 3v6l3 -3m-6 0l3 3" />
                        <path d="M12 21v-6l3 3m-6 0l3 -3" />
                        <line x1="4" y1="12" x2="5" y2="12" />
                        <line x1="9" y1="12" x2="10" y2="12" />
                        <line x1="14" y1="12" x2="15" y2="12" />
                        <line x1="19" y1="12" x2="20" y2="12" />
                    </svg>
                </button>
            </div>

        </>
    );
}

export default ColumnSettings;