import React, { useContext } from "react";
import { MainContext } from "../MainContext";
import { Link } from "react-router-dom";

import UserService from "../../services/DirectusUserService";
import RenderOnRole from "../../services/RenderOnRole";
import { get_BASE_DB_URL } from "../../services/CRUDJournalData";
import Toggle from "../commonUI/Toggle";

import { allSecurityRoles } from "../../App";


const Settings = () => {
  const { siteSettings, setSiteSettings } = useContext(MainContext);
   
  const toggleTheme = (e) => {
    if (e.target.checked) {
      setSiteSettings({ ...siteSettings, theme: "dark" })
    } else {
      setSiteSettings({ ...siteSettings, theme: "light" })
    }
  }

  const toggleTooltips = () => {
    setSiteSettings({ ...siteSettings, tooltips: !siteSettings.tooltips })
  }

  const toggleJournal_loggingEnabled = () => {
    setSiteSettings({
      ...siteSettings,
      journal_loggingEnabled: !siteSettings.journal_loggingEnabled,
      debt_loggingEnabled: !siteSettings.debt_loggingEnabled,
      reports_loggingEnabled: !siteSettings.reports_loggingEnabled,
    })
  }

  const toggleBlacklist_loggingEnabled = () => {
    setSiteSettings({ ...siteSettings, blacklist_loggingEnabled: !siteSettings.blacklist_loggingEnabled })
  }

  const toggleDeposits_loggingEnabled = () => {
    setSiteSettings({ ...siteSettings, deposits_loggingEnabled: !siteSettings.deposits_loggingEnabled })
  }

  const toggleVehicles_loggingEnabled = () => {
    setSiteSettings({ ...siteSettings, localVehicles_loggingEnabled: !siteSettings.localVehicles_loggingEnabled })
  }

  const togglePedestrians_loggingEnabled = () => {
    setSiteSettings({ ...siteSettings, localPedestrians_loggingEnabled: !siteSettings.localPedestrians_loggingEnabled })
  }

  const toggleBrands_loggingEnabled = () => {
    setSiteSettings({ ...siteSettings, allBrands_loggingEnabled: !siteSettings.allBrands_loggingEnabled })
  }

  const handleClearStorage = () => {
    let thisButton = document.getElementsByClassName("clear-storage")[0];

    thisButton.setAttribute("disabled", "");

    window.localStorage.clear();
    window.sessionStorage.clear();

    window.setTimeout(() => {
      window.location.reload(true)
    }, 2000)

  }

  const handleLogout = async () => {
    await UserService.doLogout()
    window.setTimeout(() => {
      window.location.reload(true)
    }, 350)
  }

  return (
    <>
      <main id="settings-page">
        <h1>Настройки</h1>
        <ul>
          <li className="setting-container">
            <p>Вы вошли как <span>{UserService.getUsername()}</span></p>
            <button className="button logout" onClick={async () => await handleLogout()} title={siteSettings.tooltips ? "Выйти из учётной записи и перейти к странице входа" : ""} >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-logout" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                <path d="M7 12h14l-3 -3m0 6l3 -3" />
              </svg>
            </button>
          </li>
          <li>
            <Toggle
              disabled={false}
              id="theme-toggle"
              label="Переключатель темы"
              iconsTheme={true}
              changeFn={toggleTheme}
              checked={siteSettings.theme === "dark" && true}
            />
          </li>
          <li>
            <Toggle
              disabled={false}
              id="tooltips-toggle"
              label="Всплывающие подсказки"
              iconsOnOf={true}
              changeFn={toggleTooltips}
              checked={siteSettings.tooltips}
            />
          </li>
          <li className="setting-container">
            <p>Сбросить все настройки</p>
            <button className="button clear-storage" onClick={handleClearStorage} title={siteSettings.tooltips ? "Удалить локальные данные" : ""} >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon-refresh" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
              </svg>
            </button>
          </li>
            <RenderOnRole roles={ [] /* allSecurityRoles */ } >
            <li>
              <Link className="button to-brands" to="/settings/vehicle-brands">Все марки транспорта</Link>
            </li>
          </RenderOnRole>
          <RenderOnRole roles={["admin", "dezhurniy"]} >
            <li>
              <Link className="button to-history" to="/settings/add-to-journal-form-history">Посмотреть историю заявок</Link>
            </li>
          </RenderOnRole>
        </ul>
      </main>
      <RenderOnRole roles={["dev", "analyst"]} >
        <div id="environment-info">
          <div className="boxProperty">
            <Toggle
              disabled={false}
              id="jourlal_loggingEnabled-toggle"
              label="Запросы Журнала в консоли"
              iconsOnOf={true}
              changeFn={toggleJournal_loggingEnabled}
              checked={siteSettings.journal_loggingEnabled}
            />
          </div>
          <div className="boxProperty">
            <Toggle
              disabled={false}
              id="deposits_loggingEnabled-toggle"
              label="Запросы Депозитов в консоли"
              iconsOnOf={true}
              changeFn={toggleDeposits_loggingEnabled}
              checked={siteSettings.deposits_loggingEnabled}
            />
          </div>
          <div className="boxProperty">
            <Toggle
              disabled={false}
              id="blacklist_loggingEnabled-toggle"
              label="Запросы Чёрного списка в консоли"
              iconsOnOf={true}
              changeFn={toggleBlacklist_loggingEnabled}
              checked={siteSettings.blacklist_loggingEnabled}
            />
          </div>
          <div className="boxProperty">
            <Toggle
              disabled={false}
              id="vehicles_loggingEnabled-toggle"
              label="Запросы Штатного транспорта в консоли"
              iconsOnOf={true}
              changeFn={toggleVehicles_loggingEnabled}
              checked={siteSettings.localVehicles_loggingEnabled}
            />
          </div>
          <div className="boxProperty">
            <Toggle
              disabled={false}
              id="pedestrians_loggingEnabled-toggle"
              label="Запросы Пешеходов в консоли"
              iconsOnOf={true}
              changeFn={togglePedestrians_loggingEnabled}
              checked={siteSettings.localPedestrians_loggingEnabled}
            />
          </div>
          <div className="boxProperty">
            <Toggle
              disabled={false}
              id="allBrands_loggingEnabled-toggle"
              label="Запросы Марок транспорта в консоли"
              iconsOnOf={true}
              changeFn={toggleBrands_loggingEnabled}
              checked={siteSettings.allBrands_loggingEnabled}
            />
          </div>
          <div className="boxProperty">
            <label htmlFor="db-source">Используемые Базы данных:</label>
            <code id="db-source">
              {get_BASE_DB_URL()}
              {" \
              "}
              {process.env.REACT_APP_DIRECTUS_URL}
            </code>
          </div>
        </div>
      </RenderOnRole>
    </>
  )
}

export default Settings;
