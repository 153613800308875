import { withErrorBoundary, useErrorBoundary } from "react-use-error-boundary";

const logErrorToMyService = ({
    error,
    resetError,
    errorInfo,
    debugData,
    handleProblems,
    dataSource,
}) => {
    let storedData = JSON.parse(window.localStorage.getItem(`${dataSource}Data`));
    console.log('%clogErrorToMyService called%c', "color:red", "color:initial", 
    "\nerror", error, 
    "\nerrorInfo", errorInfo, 
    "\ndebugData", debugData,
    "\nstoredData:", storedData)
    
    handleProblems();
    resetError(error);
}

const TableRowErrorBoundary = withErrorBoundary(({ dataSource, debugData, hiddenColumns, columns, handleProblems, children }) => {
    const [error, resetError] = useErrorBoundary(

        (error, errorInfo) => logErrorToMyService({
            error,
            resetError,
            errorInfo,
            debugData,
            handleProblems,
            dataSource,
        })

    );

    const handleError = (e) => {
        resetError(e);
        handleProblems();
    }

    if (error) {
        console.log("TableRowErrorBoundary got error - ", error, debugData)

        return (
            <tr>
                <td className='error-boundary not-cool-status-container' colSpan={hiddenColumns.length !== 0 ? columns.length - 1 : columns.length}>
                    <div className='not-cool-status'>
                        <code className='error-message'>Что-то пошло не по плану...</code>
                        <button className="button" onClick={handleError}>Перезагрузить</button>
                    </div>
                </td>
            </tr>
        );
    }

    return children;
});

export default TableRowErrorBoundary;