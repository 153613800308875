import React, { useContext, useRef } from "react";
import UserService from "../../services/DirectusUserService";

import { MainContext } from "../MainContext";
import RenderOnRole from "../../services/RenderOnRole";
import {
  LinkToAddToJournalElement,
  LinkToJournalElement,
  LinkToLocalPedestriansElement,
  LinkToLocalVehiclesElement,
  LinkToSettingsElement,
  LinkToAccountingElement,
  LinkToBlacklistElement,
  LinkToContactsElement,
  LinkToMainSearch,
} from "./NavAnchors";
import FoldableNavigation from "./FoldableNavigation";

import { allSecurityRoles } from "../../App";
import { allRoles } from "../../App";

// const knownLocations = ["/", "/add", "/pedestrians", "/local-vehicles", "/blacklist", "/settings", "/add-to-journal-form-history", "/accounting"];

const getLinksSize = () => {
  let rem = getComputedStyle(document.body).getPropertyValue("font-size");
  let calc = getComputedStyle(document.body).getPropertyValue("--links-size");
  let multipliersArray = calc
    .replace(/[^\d\.\s]/gi, "")
    .replace(/\s+/gi, " ")
    .split(" ");
  multipliersArray.forEach((e, index) => (multipliersArray[index] = parseFloat(e)));

  return Math.ceil(
    parseInt(rem) *
      multipliersArray.reduce(
        (previousValue, currentValue) => previousValue * currentValue,
      ),
  );
};

const NavigationLinksMenu = ({ pathname, navContainerRef, viewportWidth }) => {
  const { userData, siteSettings } = useContext(MainContext);
  const linksSize = useRef(getLinksSize());

  let navElements = [];
  const navLinks = [
    {
      pathname: "*",
      object: (
        <RenderOnRole roles={allRoles} key={"LinkToSettingsElement-render"}>
          <LinkToSettingsElement
            key={"LinkToSettingsElement"}
            navContainer={navContainerRef}
            tooltips={siteSettings?.tooltips}
            currentLocation={pathname}
            roles={allRoles} // component wount use them, it's used to count nav-items to render
          />
        </RenderOnRole>
      ),
    },
    {
      pathname: "/",
      object: (
        <RenderOnRole roles={allSecurityRoles} key={"LinkToJournalElement-render"}>
          <LinkToJournalElement
            navContainer={navContainerRef}
            tooltips={siteSettings?.tooltips}
            currentLocation={pathname}
          />
        </RenderOnRole>
      ),
    },
    {
      pathname: "/add",
      object: (
        <RenderOnRole
          roles={["admin", "dezhurniy"]}
          key={"LinkToAddToJournalElement-render"}>
          <LinkToAddToJournalElement
            navContainer={navContainerRef}
            tooltips={siteSettings?.tooltips}
            currentLocation={pathname}
          />
        </RenderOnRole>
      ),
    },
    {
      pathname: "/pedestrians",
      object: (
        <RenderOnRole
          roles={["admin", "kpp2", "kpp1", "patrol2", "patrol1", "analyst"]}
          key={"LinkToLocalPedestriansElement-render"}>
          <LinkToLocalPedestriansElement
            navContainer={navContainerRef}
            tooltips={siteSettings?.tooltips}
            currentLocation={pathname}
          />
        </RenderOnRole>
      ),
    },
    {
      pathname: "/local-vehicles",
      object: (
        <RenderOnRole roles={allSecurityRoles} key={"LinkToLocalVehiclesElement-render"}>
          <LinkToLocalVehiclesElement
            navContainer={navContainerRef}
            tooltips={siteSettings?.tooltips}
            currentLocation={pathname}
          />
        </RenderOnRole>
      ),
    },
    {
      pathname: "/blacklist",
      object: (
        <RenderOnRole roles={["admin", "patrol1", "patrol2"]} key={"LinkToBlacklistElement-render"}>
          <LinkToBlacklistElement
            navContainer={navContainerRef}
            tooltips={siteSettings?.tooltips}
            currentLocation={pathname}
          />
        </RenderOnRole>
      ),
    },
    {
      pathname: "/accounting",
      object: (
        <RenderOnRole roles={["admin", "accountant"]} key={"LinkToAccountingElement-render"}>
          <LinkToAccountingElement
            navContainer={navContainerRef}
            tooltips={siteSettings?.tooltips}
            currentLocation={pathname}
          />
        </RenderOnRole>
      ),
    },
    {
      pathname: "/contacts",
      object: (
        <RenderOnRole roles={allRoles} key={"LinkToContactsElement-render"}>
          <LinkToContactsElement
            navContainer={navContainerRef}
            tooltips={siteSettings?.tooltips}
            currentLocation={pathname}
          />
        </RenderOnRole>
      ),
    },
    {
      pathname: "/search",
      object: (
        <RenderOnRole
          roles={["admin", "dezhurniy", "patrol1", "patrol2", "analyst"]}
          key={"LinkToMainSearch"}>
          <LinkToMainSearch
            navContainer={navContainerRef}
            tooltips={siteSettings?.tooltips}
            currentLocation={pathname}
          />
        </RenderOnRole>
      ),
    },
  ];

  navLinks
    .filter(el => {
      if (pathname === "/settings" || pathname === "/add-to-journal-form-history") {
        if (el.pathname === "*" && pathname === "/settings") {
          return false;
        } else if (el.pathname !== pathname) {
          return true;
        }
      } else if (el.pathname === "*" && pathname !== "/settings") {
        return true;
      } else if (el.pathname !== pathname) {
        return true;
      }

      return false;
    })
    .forEach(el => {
      navElements.push(el.object);
    });

  const navElementsToRender = navElements.filter(el => {
    return UserService.hasRole(el.props.roles);
  });

  // console.log("NavigationLinksMenu renders")
  // console.log("navElementsToRender.length * linksSize.current > viewportWidth",
  // navElementsToRender.length * linksSize.current > viewportWidth,
  // navElementsToRender.length, linksSize.current, viewportWidth)

  if (navElementsToRender.length * linksSize.current > viewportWidth) {
    let first = navElements.shift();
    let last = pathname === "/search" ? <></> : navElements.pop();

    // console.log("NavigationLinksMenu use hamburger menu")

    return (
      <>
        {first !== navLinks[0].object && (
          <div key={"dummyDiv"} className="nav-dummy-div hell"></div>
        )}
        {first}
        <FoldableNavigation
          addClassName="menu-short"
          contentID="menu-short"
          locationPathname={pathname}>
          {navElements}
        </FoldableNavigation>
        {last}
      </>
    );
  } else {
    if (navElements[0] !== navLinks[0].object) {
      navElements.unshift(<div key={"dummyDiv"} className="nav-dummy-div"></div>);
    }

    // console.log("NavigationLinksMenu uses flat menu")

    return navElements;
  }
};

export default NavigationLinksMenu;
