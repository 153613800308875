import React, { useState, useContext, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import { MainContext } from '../MainContext';
import Spoiler from '../commonUI/Spoiler';
import useCachedDataWithUpdates from '../../services/useCachedDataWithUpdates';

const filterIcon = <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-filter"
        width={44}
        height={44}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
</svg>

const offFilterIcon = <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon-filter-off"
        width={44}
        height={44}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1={3} y1={3} x2={21} y2={21} />
    <path d="M9 5h9.5a1 1 0 0 1 .5 1.5l-4.049 4.454m-.951 3.046v5l-4 -3v-4l-5 -5.5a1 1 0 0 1 .18 -1.316" />
</svg>

const FilterButton = ({ targetID }) => {
    const [isFilterSet, setIsFilterSet] = useState(false);
    const [searchParams, setSearchTerm] = useSearchParams();

    useEffect(() => {

        window.setTimeout(() => {

            if (searchParams.get("origin_ID") !== `${targetID}`) {
                setIsFilterSet(false)
            } else { 
                setIsFilterSet(true)
            }
            
        }, 450)

    }, [searchParams, targetID]);

    const handleClickOnFilter = (isFilterSet) => {

        if (!isFilterSet) {
            setIsFilterSet(true) 
            setSearchTerm({"origin_ID": targetID})

        } else {
            setIsFilterSet(false) 
            setSearchTerm({"origin_ID": ""})

        }


    }

    return (
        <button 
            className='button filter-table-below'
            onClick={ () => handleClickOnFilter(isFilterSet) }
            data-state={`filter${!isFilterSet ? "-not" : ""}-set`}
        >
            {filterIcon}
            {offFilterIcon}
        </button>
    );

}


const AttentionList = ({setIdQuery}) => {
    const { userData} = useContext(MainContext);
    const [suspectsData, setSuspectsData] = useState([])

    const [
        isDataLoading,
        // tableDataRef,
        // justAlteredRowsRef,
        // totalEntries,
        // deletingRowsRef,
        // forceQueryRef,
        // isToggleDisabled,
    ] = useCachedDataWithUpdates({
        location: "contacts",
        // disabled: dataSettings.blockFetching,
        // freeze: selectedFlatRows?.length > 0 ? true : false,
        // freezeRef,
        dataSource: "contacts",
        updateFrequency: 180000,
        cachedDataLifeSpan: 3600000,
        // shallowQuery: dataSettings.shallowQuery,
        initialShowOnlyActiveState: true,
        showOnlyActive: true,
        onViewSwitchFilterCondition: "(archived,eq,false)~and(power_of_attorney_expiration,isnot,null)~and(days_before_powers_expiration,le,3888000)",
        // offViewSwitchFilterCondition: dataSettings.offViewSwitchFilterCondition,
        sortString: "days_before_powers_expiration",
        // altSortString: dataSettings.altSortString,
        // localFieldToCompareBy: dataSettings.localFieldToCompareBy,
        // remoteFieldToCompareBy: dataSettings.remoteFieldToCompareBy,
        nocodb_auth: userData.nocodb_auth,
        // pageIndex,
        // setTotalPages,
        // gotoPage: gotoPageCallback,
        recordsPerPage: 100,
        // filters,
        tableData: suspectsData,
        setTableData: setSuspectsData,
        // editedEntryID,
        // forceReloadRef,
        // hiddenColumns,
        // loggingEnabled: !!siteSettings?.[`${tablePrefix}_loggingEnabled`],
    });

    return (
        <Spoiler 
            header={"Кризисы доверия"} 
            contentID={'suspects-container'} 
            addClassName={!isDataLoading && (!suspectsData || suspectsData?.length < 1) ? 'empty' : ""}
            isDataLoaded={!isDataLoading} 
            defaultCollapsed={true}
            showCount={true}
            itemsCount={suspectsData?.length}
        >
            <ul id="suspects-list">
                { suspectsData.map(contact => {
                    return (
                        <li key={contact.origin_ID}>
                            <p className='powers-status'>
                                {contact?.powersStatus}
                            </p>
                            {contact.object.map( (obj, i) => 
                                <span key={`object-${i}`} className='land-object'>
                                    <p>
                                        {obj.area_name}
                                    </p>
                                </span>
                            )}
                            <p>
                                {contact?.name}
                            </p>
                            <FilterButton targetID={contact?.origin_ID} setIdQuery={setIdQuery} />
                        </li>
                    ); 
                })}
            </ul>
        </Spoiler>
    );
}

export default AttentionList;