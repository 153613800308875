import React, { useState, useContext, useRef, useEffect } from 'react';

import { MainContext } from '../MainContext';
import LoadingIcon from '../commonUI/LoadingIcon';
import { notifySecurityChat, getRidOfAliases } from "../../services/littleHelpers"


const RemoveFromBlacklistDialog = ({ car, confirmFunction, setConfirmationRequiered }) => {
    const { userData } = useContext(MainContext);
    const [dialogStatus, setDialogStatus] = useState('idle')
    const verifyCommentRef = useRef();
    
    const handleClickOnConfirm = async () => {
        setDialogStatus('loading')
        confirmFunction(userData.nocodb_auth, car.origin_ID, verifyCommentRef.current.value)
        
        const notificationData = {
            brand_name: getRidOfAliases(car.brand),
            plate_number: car.number,
            comment: verifyCommentRef.current.value,
        }

        const response = await notifySecurityChat({"blacklist-released": notificationData});
        if (response?.status !== 200) {
            window.alert("Машина убрана из ЧС, но уведомление в чат не доставлено!")
        }
    }
    

    return (
        <div className='blacklist-confirmation-container'>
            {
                dialogStatus === "idle" ?
                <>
                    <h1>Требуется подтверждение</h1>
                    <div className='boxProperty'>
                        <p>Убрать из чёрного списка {car.brand} {car.number}?</p>
                    </div>
                    <div className='boxProperty'>
                        <label htmlFor={`verify-comment`}>Ваш комментарий</label>
                        <textarea 
                            ref={verifyCommentRef}
                            id={`verify-comment`} 
                            className='classyInput'
                            defaultValue={car.comment}
                            spellCheck={true}
                        />
                    </div>
                    <div className='boxProperty'>
                        <button className='button'
                        onClick={handleClickOnConfirm}
                        >
                            Да
                        </button>
                        <button className='button'
                            onClick={() => setConfirmationRequiered(false) }
                        >
                            Отмена
                        </button>
                    </div>
                </>
                :
                <>
                   <LoadingIcon/>
                </>
            }
        </div>
    );
}

export default RemoveFromBlacklistDialog;