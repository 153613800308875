import React, { useState, useContext } from 'react';

import { MainContext } from '../../MainContext';
import ModalDialog from '../../modals/ModalDialog';
import LoadingIcon from '../../commonUI/LoadingIcon';

import { executeBrandsMerge } from '../../../services/CRUDJournalData';

const MergeBrands = ({disabled = true, selectedFlatRows, toggleAllRowsSelected, forceReloadRef}) => {
    const { userData } = useContext(MainContext);
    const [mergingStatus, setMergingStatus] = useState('idle'); // ok, fail, idle
    const [responseOnMerge, setResponseOnMerge] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationContent, setConfirmationContent] = useState(<></>)
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleClickOnConfirmation = async ({brandToDelete, brandToEnrich}) => {
        setIsLoading(true);
        let result = await executeBrandsMerge({
            auth_token: userData.nocodb_auth,
            brandToDelete,
            brandToEnrich
        })

        // console.log("executeBrandsMerge result", result)

        setResponseOnMerge(result)
        setIsLoading(false)

        if (result.status === 200) {

            setMergingStatus('ok')
            toggleAllRowsSelected(false);
            forceReloadRef.curren = true;
            
            window.setTimeout(() => {
                setMergingStatus('idle')
                setIsModalOpen(false)
            }, 2681)
            
        } else {
            setMergingStatus('fail')
        }

    }
    
    const confirmMerge = () => {
        
        // format inner brand data:
        let formatedSelectedRows = selectedFlatRows.reduce((prev, current) => {
            let blacklist_count = Number(current.values.blacklist_count)
            let local_vehicles_count = Number(current.values.local_vehicles_count)
            let journal_count = Number(current.values.journal_count)
            return [...prev, {
                brand: current.values.common_brand,
                origin_ID: current.values.origin_ID,
                blacklist_count,
                local_vehicles_count,
                journal_count,
                totalRelatedVehicles: blacklist_count + local_vehicles_count + journal_count,
            }]
        }, [])

        // sort by ascending totalRelatedVehicles:
        formatedSelectedRows.sort((a, b) => a.totalRelatedVehicles - b.totalRelatedVehicles);
        
        let brandToDelete = formatedSelectedRows[0]
        let brandToEnrich = formatedSelectedRows[1]
        
        setConfirmationContent(
            <div id={'brands-merge-confirmation'}>
                <h2>Требуется подтверждение</h2>
                <p>Марка «{brandToDelete.brand}» будет удалена, 
                а связанные с ней транспорные средства 
                ({brandToDelete.totalRelatedVehicles} ед.) 
                будут прикреплены к «{brandToEnrich.brand}»</p>
                <button 
                    className='button'
                    onClick={ () => handleClickOnConfirmation({brandToDelete, brandToEnrich}) }
                >
                    Да, объединяем
                </button>
            </div>
        )
        
        setIsModalOpen(true);
        
    }
    
    const closeModal = () => {
        setMergingStatus('idle');
        setIsLoading(false);
        setIsModalOpen(false);
    }

    return (
        <>
            <button 
                className='button merge'
                disabled={disabled}
                onClick={confirmMerge}
            >
                <i>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon-merge" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <circle cx="7" cy="18" r="2" />
                        <circle cx="7" cy="6" r="2" />
                        <circle cx="17" cy="12" r="2" />
                        <line x1="7" y1="8" x2="7" y2="16" />
                        <path d="M7 8a4 4 0 0 0 4 4h4" />
                    </svg>
                </i>
                Объединить
            </button>
            <ModalDialog
                isOpen={isModalOpen}
                exitFunction={closeModal}
            >
                {

                    isLoading ?
                    <LoadingIcon addId={"merging-brands"} />
                    :
                    mergingStatus === "idle" ?
                    confirmationContent
                    :
                    mergingStatus === "ok" ?
                    <>
                        <h2>👍</h2>
                        <div className='boxProperty'>
                            <p><code>{responseOnMerge?.statusText}</code></p>
                        </div>
                    </>
                    :
                    mergingStatus === "fail" ?
                    <div className='error-response-container'>
                            <h2>Что-то пошло не так</h2>
                            <div className='boxProperty'>
                                <p>Код ошибки: <code>{responseOnMerge?.status}</code></p>
                            </div>
                            <div className='boxProperty'>
                                <p>Текст ошибки: <code>{responseOnMerge?.statusText}</code></p>
                            </div>
                            <div className='boxProperty'>
                                <button 
                                    className='button'
                                    onClick={closeModal}
                                >
                                    Закрыть окно
                                </button>
                            </div>
                    </div>
                    :
                    null
                }
            </ModalDialog>
        </>
    );
}

export default MergeBrands