import React, { useEffect, useState } from "react";


const History = () => {
    const [tableLines, setTableLines] = useState(<tr><td colSpan={9}>Идёт загрузка</td></tr>)

    useEffect(() => {
        (async () => {
            const lines = await produceHistoryTable()
            setTableLines(lines)
        })();
    }, [])


    const produceHistoryTable = async () => {
        const historyData = await JSON.parse(window.localStorage.getItem("submissionsHistory"))
        if (historyData === null) { return <tr><td colSpan={9}>Пока истории нет</td></tr> }
        
        let vehicleLines = [];
        
        historyData.forEach((a, index) => {
            let vehicles = a.vehicles;
            let status = a.submissonStatus;
            let submissionDate = a.submissionDate;
            let destinations = [];
    
            a.destination.forEach(e => {
                destinations.push(e.label)
            })
            destinations.join();
    
            vehicleLines.push(
            <tr key={`${index}-+-${vehicles.length}`}>
                <td className="st" rowSpan={vehicles.length}>{!status ? "неизвестен" : status.match("!") ? "очередь" : "доставлена"}</td>
                <td className="dest" rowSpan={vehicles.length}>{destinations}</td>
                <td className="num" >{vehicles[0].number}</td>
                <td>{vehicles[0].brand.label}</td>
                <td>{vehicles[0].category.label}</td>
                <td className="comm">{vehicles[0].comment}</td>
                <td className="day">{vehicles[0].daily ? "да" : "нет"}</td>
                <td className="debt">{vehicles[0].debt ? "да" : "нет"}</td>
                <td className="entered">{vehicles[0].entered ? "да" : "нет"}</td>
                <td className="date" rowSpan={vehicles.length}>{new Date(submissionDate).toLocaleString("ru-RU")}</td>
            </tr>
            )
            
            if (vehicles.length > 1) {
                for (let j = 1; j < vehicles.length; j++) {
                    vehicleLines.push(
                        <tr key={`${index}-${j}-${vehicles.length}`}>
                            <td>{vehicles[j].number}</td>
                            <td>{vehicles[j].brand.label}</td>
                            <td>{vehicles[j].category.label}</td>
                            <td>{vehicles[j].comment}</td>
                            <td>{vehicles[j].daily ? "да" : "нет"}</td>
                            <td>{vehicles[j].debt ? "да" : "нет"}</td>
                            <td>{vehicles[j].entered ? "да" : "нет"}</td>
                        </tr>
                    )
                }
            }
        });

        return vehicleLines;
    }



    return (
        <main id="history-container">
            <h1>История отправленных заявок</h1>
            <div id="history-table">
                <table>
                    <thead>
                        <tr>
                            <td>Статус</td>
                            <td>Назначение</td>
                            <td>Номер</td>
                            <td>Марка</td>
                            <td>Категория</td>
                            <td>Комментарий</td>
                            <td>Суточный</td>
                            <td>Кредит</td>
                            <td>Прибыл</td>
                            <td>Дата</td>
                        </tr>
                    </thead>
                    <tbody>
                        {tableLines}
                    </tbody>
                </table>
            </div>
        </main>
    )
}

export default History;
