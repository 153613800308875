import React, {useState, useEffect} from 'react';

import { mergeJournalEntries } from "../../services/CRUDJournalData"
import { getRidOfAliases } from '../../services/littleHelpers';
import ModalDialog from '../modals/ModalDialog';
import LoadingIcon from "../commonUI/LoadingIcon";

import { SimpleInTableCheckbox } from '../inputElements';


export const searchForDuplicates = async ({
    processedData,
    activeJournalDataRef,
}) => {
    
    // console.time('~~~~~searchForDuplicates')
    let possibleDuplicates = [];

    possibleDuplicates = activeJournalDataRef?.current?.filter((je) => {
        
        for (const vehicle of processedData.vehicles) {
            
            if (je.number.includes(vehicle.number) && je.brand.Id === vehicle.brand.value) {
                vehicle.mergeCandidate = true;
                vehicle.mergeMarked = false;
                return true;
            }

        }

    })
    // console.timeEnd('~~~~~searchForDuplicates')
    
    return possibleDuplicates;
}

const JournalEntryMergingConfirmation = ({
    userData,
    formState,
    chosenDestinations,
    proceedFunction,
    setFormState,
    possibleDuplicates,
    dataToSend,
    handleSuccessfulSubmission,
}) => {
    const [mergingStatus, setMergingStatus] = useState('idle'); // ok, fail, idle
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemsToMerge, setItemsToMerge] = useState([{
        origin_ID: undefined,
        dest_ID: undefined,
    }]);

    useEffect(() => {
        
        if (possibleDuplicates?.length > 0 && formState === "sendingData") {
            setIsModalOpen(true)
        }
        // console.log("dataToSend", dataToSend)

    }, [possibleDuplicates.length, formState]);


    const handleCheck = (v, duplicate) => {
        let origin_ID = duplicate.origin_ID;

        if (v.target.checked) {
            let targetDestIds = [];

            duplicate?.dest.forEach(d => {
                targetDestIds.push(d.id)
            })

            setItemsToMerge(i => [{
                origin_ID: origin_ID,
                dest_ID: targetDestIds
            }, ...i])

            for (let v of dataToSend.vehicles) {

                if (v.number === duplicate.number && v?.brand?.value === duplicate?.brand?.Id) {
                    v.mergeMarked = true;
                }

            }

        } else {

            setItemsToMerge(i => i.filter(item => item.origin_ID !== origin_ID))

            for (let v of dataToSend.vehicles) {

                if (v.number === duplicate.number && v?.brand?.value === duplicate?.brand?.Id) {
                    v.mergeMarked = false;
                }
                
            }

        }
    
    }


    const onMergeClick = async () => {

        try {
            setIsLoading(true);

            itemsToMerge.find(item => {
                if (item.origin_ID === undefined) {
                    item.dest_ID = [].concat(chosenDestinations?.[0]?.value);
                }
            })


            let response = await mergeJournalEntries(userData, itemsToMerge)

            let remainingVehiclesData = dataToSend.vehicles.filter((v) => {
                return !v?.mergeCandidate && !v?.mergeMarked 
            })

            if (remainingVehiclesData?.length > 0) {
                dataToSend.vehicles = remainingVehiclesData;
                response = await proceedFunction(dataToSend, true);
            }


            if (response?.status !== 200) {
                setError(response);
                setIsLoading(false);
                setMergingStatus('fail');
                // window.alert("Что-то пошло не так :(")

            } else {
                setIsLoading(false);
                setMergingStatus('ok');

                window.setTimeout(() => {

                    handleSuccessfulSubmission();
                    setFormState("success")
                    setIsModalOpen(false);
                    setItemsToMerge([{
                        origin_ID: undefined,
                        dest_ID: undefined,
                    }]);
                    setMergingStatus('idle');

                }, 450)

            }

        } catch (err) {
            setError(err);
            setIsLoading(false);
            console.error(err)
        }
        
    }

    const onProceedClick = async () => {
        setIsModalOpen(false)
        await proceedFunction(dataToSend)

    }

    const getExistingDestiantions = (possibleDuplicate) => {
        let existingDestiantions = ""
        
        if (!possibleDuplicate?.dest) {
            return existingDestiantions;
        }

        for (const dest of possibleDuplicate.dest) {
            
            if (existingDestiantions === "") {
                existingDestiantions += "«" + getRidOfAliases(dest.name) + "»"
            } else {
                existingDestiantions += (", " + "«" + getRidOfAliases(dest.name) + "»")
            }
        }
        
        return existingDestiantions;
    }

    const closeModal = () => {
        
        setMergingStatus('idle');
        setFormState("readyForInput");
        setIsModalOpen(false);
    }

    return (
        <>
            <ModalDialog isOpen={isModalOpen} exitFunction={closeModal}>
                <div id="duplicate-possible">
                    {
                        isLoading ?
                        <LoadingIcon />
                        :
                        mergingStatus === 'idle' ?
                        <>
                            <h2>Возможно, заявка на доставку</h2>
                            {
                                possibleDuplicates.map((d) => (
                                    <div className="boxProperty" key={`possible-duplicate-${d.origin_ID}`}>
                                        <p>
                                            В журнале уже есть {getRidOfAliases(d.brand.brand)} {d.number}, направляющаяся к {getExistingDestiantions(d)}.
                                        </p>
                                        {
                                            possibleDuplicates.length === 1 ?
                                            <p id="addToExistingEntry">
                                                Добавить пункт назначения «{getRidOfAliases(chosenDestinations[0]?.label)}» к существующей заявке?
                                            </p>
                                            :
                                            <p>
                                                Отметить для объединения
                                            </p>
                                        }
                                        <SimpleInTableCheckbox
                                            changeHandler={(v) => {handleCheck(v, d)}}
                                            isChecked={false}
                                            passed_origin_ID={d.origin_ID}
                                            helperText={'Выбрать к слиянию'}
                                        />
                                    </div>
                                ))
                            }
                            <div className="boxProperty">
                                <button disabled={itemsToMerge.length < 2} className="button" id="merge-with-existing" onClick={onMergeClick}>Объединить</button>
                                <button disabled={itemsToMerge.length !== 1} className="button" id="just-send" onClick={onProceedClick}>Создать отдельную запись</button>
                            </div>
                            {
                                dataToSend?.vehicles?.findIndex(v => !v.mergeCandidate) !== -1 ?
                                <span id="entriesNotForMerging">
                                    Ещё будут доставлены заявки для:
                                    {
                                        dataToSend?.vehicles?.map( v => {

                                            if (!v.mergeCandidate) {
                                                return <>
                                                    <br/>{getRidOfAliases(v.brand.label)} {v.number} {" "}
                                                </> 
                                            }
                                    
                                        })
                                    }
                                </span>
                                :
                                <></>
                            }
                        </>
                        :
                        mergingStatus === 'ok' ?
                        <>
                            <h2>👍</h2>
                        </>
                        :
                        mergingStatus === 'fail' ?
                        <>
                            <h2>Что-то пошло не так</h2>
                            <div className='boxProperty'>
                                <p>Код ошибки: <code>{error?.status}</code></p>
                            </div>
                            <div className='boxProperty'>
                                <p>Текст ошибки: <code>{error?.statusText}</code></p>
                            </div>
                        </>
                        :
                        null
                    }
                </div>
            </ModalDialog>
        </>
    )
}



export default JournalEntryMergingConfirmation;