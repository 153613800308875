import React, { useCallback, useContext, useMemo } from 'react';
import { useSearchParams } from "react-router-dom";

import { MainContext } from "../MainContext";
import RenderOnRole from '../../services/RenderOnRole';
import AttentionList from './AttentionList';

import ContactRowMemoised from './ContactRow';
import Table from '../commonUI/Table';

const initialArrayDataObject = [];
const initialHiddenColumns = {
    onlyActiveHidden: ['origin_ID', "updated_at", "created_at", "archived", "email", 'birth_date', "power_of_attorney_expiration"],
    allHidden: ['origin_ID', "updated_at", "created_at"],
};

const findOutInitialTableView = (idQueryExist) => {
    if (idQueryExist) {
        return false;
    } else {
        return true;
    }
}

const Contacts = () => {
    let [searchParams] = useSearchParams();
    const { userData } = useContext(MainContext);

    const columns = useMemo(() => {
        let cols = [
            {
                Header: 'Архив',
                accessor: 'archived',
                disableFilters: true,
            },
            {
                Header: 'Объект',
                accessor: 'object',
            },
            {
                Header: 'ФИО',
                accessor: 'name',
            },
            {
                Header: 'Позиция',
                accessor: 'position',
            },
            {
                Header: 'Доверие',
                accessor: 'powersStatus',
                disableFilters: true,
            },
            {
                Header: 'Комментарий',
                accessor: 'comment',
                // disableFilters: true,
            },
            {
                Header: 'Телефоны',
                accessor: 'phone',
            },
            {
                Header: 'email',
                accessor: 'email',
            },
            {
                Header: 'Дата рождения',
                accessor: 'birth_date',
            },
            {
                Header: 'Дата истечения доверенности',
                accessor: 'power_of_attorney_expiration',
            },
            {
                Header: 'Создание',
                accessor: 'created_at',
            },
            {
                Header: 'Редакция',
                accessor: 'updated_at',
            },
            {
                Header: 'origin_ID',
                accessor: 'origin_ID',
            }
        ]

        return cols;
    }
    , [userData]);

    const getContactRowMemoised = useCallback(({
        row,
        hiddenColumnsLength,
        deletingRowsRef,
        justAlteredRowsRef,
        setEditedEntryID,
    }) => {

        return (
            <ContactRowMemoised
                key={`row-entry-${row.original.origin_ID}__${row.original.updated_at && row.original.updated_at.replace(/\s/g, '_')}__${hiddenColumnsLength}`} 
                preparedRow={row}
                deletingRowsRef={deletingRowsRef}
                justAlteredRowsRef={justAlteredRowsRef}
                setEditedEntryID={setEditedEntryID}
            />
        );
        
    }, [])


    return (
        <>
            <Table
                location='/contacts'
                tablePrefix="contacts"
                initialArrayDataObject={initialArrayDataObject}
                initialShowOnlyActiveState={findOutInitialTableView(!!searchParams.get('origin_ID'))}
                columns={columns}
                initialHiddenColumns={initialHiddenColumns}
                toggle={
                    {
                        enabled: true,
                        showOnlyActiveLabel: "Актуальные контакты:",
                        showAllLabel: "Все контакты:",
                        onlyHat: true,
                    }
                } 
                returnMemoizedTableRowComponent={getContactRowMemoised}
                dataSettings={{
                    initialPageSize: 10,
                    altInitialPageSize: 10,
                    updateFrequency: 15000,
                    cachedDataLifeSpan: 180000,
                    onViewSwitchFilterCondition: '(archived,eq,false)',
                    offViewSwitchFilterCondition: undefined,
                    sortString:"-updated_at",
                    altSortString: "-updated_at",
                    localFieldToCompareBy: "updated_at_full",
                    remoteFieldToCompareBy: "updated_at",
                    keepTableSettings: true,
                    syncRecordsPerPage: true,   
                }}
                noEntriesMessage={"Подходящих контактов не найдено"}
                keepTableSettings={false}
                hatComponents={
                    <RenderOnRole roles={[] /* ["dev", "admin", "accountant"] */ }>
                        <AttentionList />
                    </RenderOnRole>
                }
                searchParams={searchParams}
            />
        </>
    );
}

export default Contacts;