import React from 'react';
import Spoiler from '../commonUI/Spoiler'
import BlacklistItemMemoised from '../blacklist/BlacklistItem'

const ActiveBlacklist = ({ usersRoles = [], blacklistData, isLoading}) => {
    const canBeReleased = usersRoles.includes('admin') || usersRoles.includes('patrol1') || usersRoles.includes('patrol2');

    return (
        <>
            <Spoiler 
                header={"Чёрный список"} 
                contentID={"blacklist-container"}
                addClassName={!isLoading && (blacklistData?.length < 1 || !blacklistData) ? 'empty' : ""}
                isDataLoaded={!isLoading}
                defaultCollapsed={true}
            >
                {
                    blacklistData?.map(car => (
                        <BlacklistItemMemoised
                            key={`BlacklistItem-${car.origin_ID}`}
                            item={car}
                            canBeReleased={canBeReleased}
                            detailsExpanded={false}
                        />
                    ))
                }
            </Spoiler>
        </>
    );
}

function propsAreEqual(prev, next) {
    if (next?.blacklistData?.length > 0) {
        return prev?.blacklistData?.[0]?.updated_at_full === next?.blacklistData?.[0]?.updated_at_full;
    } else {
        return prev?.blacklistData === next?.blacklistData;
    }
}

const ActiveBlacklistMemoized = React.memo(ActiveBlacklist, propsAreEqual); 

export default ActiveBlacklistMemoized;