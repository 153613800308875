import { useRef, useEffect } from 'react';


const usePersistentTableSettings = ({
    keepTableSettings = true,
    syncRecordsPerPage = true,
    tablePrefix = 'journal',
    previousViewRef,
    showOnlyActive,
    hiddenColumns,
    setHiddenColumns,
    recordsPerPage,
    setRecordsPerPage,
    initialHiddenColumns = {
        onlyActiveHidden: [],
        allHidden: [],
    },
    doLogging = false,


}) => {

    const firstRenderRef = useRef(true);
    const hiddenColumnsState = useRef({
        onlyActiveHidden: initialHiddenColumns["onlyActiveHidden"],
        allHidden: initialHiddenColumns["allHidden"],
    })

    useEffect(() => {

        if (!keepTableSettings) {
            return
        }

        /**
         * Hook used to persist table's view between browser restarts. 
         * @recordsPerPage state persists between sessions.
         * @hiddenColumns state saved for both table's views depening on @showOnlyActive state
         * On first render:
            * values stored in localstorage force corresponding states updates,
        */


        let storedHiddenColumns = JSON.parse(window.localStorage.getItem(`${tablePrefix}HiddenColumns`));
        
        doLogging && console.time(`usePersistentTableSettings run`)

        if (firstRenderRef.current === true) {
            doLogging && console.log("1. First render")

                if (storedHiddenColumns?.onlyActiveHidden && storedHiddenColumns.onlyActiveHidden.join() !== hiddenColumns.join()) {
                    doLogging && console.log("1.1. storedHiddenColumns.%conlyActiveHidden%c exists, if correspondig view active - restore saved hidden columns state", "color:red", "color:initial")
                    
                    showOnlyActive && setHiddenColumns(storedHiddenColumns.onlyActiveHidden)
                    hiddenColumnsState.current.onlyActiveHidden = storedHiddenColumns.onlyActiveHidden
                    hiddenColumnsState.current.allHidden = storedHiddenColumns.allHidden
                    
                } else if (storedHiddenColumns?.allHidden && storedHiddenColumns.allHidden.join() !== hiddenColumns.join()) {
                    doLogging && console.log("1.2. storedHiddenColumns.%callHidden%c exists, if correspondig view active - restore saved hidden columns state", "color:red", "color:initial")
                    
                    !showOnlyActive && setHiddenColumns(storedHiddenColumns.allHidden)
                    hiddenColumnsState.current.allHidden = storedHiddenColumns.allHidden
                    hiddenColumnsState.current.onlyActiveHidden = storedHiddenColumns.onlyActiveHidden

                }


        } else {
            doLogging && console.log("2. Any non-first render with hiddenColumns as", hiddenColumns)

            if (previousViewRef.current !== showOnlyActive) {
                doLogging && console.log("2.A. Filtering by 'showOnlyActive' state changed")

                if (showOnlyActive) {

                    if (storedHiddenColumns?.onlyActiveHidden &&
                        storedHiddenColumns?.onlyActiveHidden?.join()
                        !==
                        hiddenColumns?.join()
                    ) {
                        doLogging && console.log("2.A.1 hidden columns state needs update to hiddenColumnsState.current.onlyActiveHidden")

                        hiddenColumnsState.current.onlyActiveHidden = storedHiddenColumns.onlyActiveHidden;
                        setHiddenColumns(storedHiddenColumns.onlyActiveHidden)
                    }
                } else {
                    if (storedHiddenColumns?.allHidden &&
                        storedHiddenColumns?.allHidden?.join()
                        !==
                        hiddenColumns?.join()
                    ) {
                        doLogging && console.log("2.A.2 hidden columns state needs update to hiddenColumnsState.current.allHidden")

                        hiddenColumnsState.current.allHidden = storedHiddenColumns.allHidden;
                        setHiddenColumns(storedHiddenColumns.allHidden)
                    }
                }

                previousViewRef.current = showOnlyActive;

            } else {
                doLogging && console.log("2.B. 'showOnlyActive' state remain as previous")

                if (showOnlyActive && 
                    hiddenColumnsState.current.onlyActiveHidden?.join() 
                    !== 
                    hiddenColumns?.join()
                ) {
                    doLogging && console.log("2.B.1 updating hiddenColumnsState.current.onlyActiveHidden ref to changed hiddenColumns state")

                    hiddenColumnsState.current.onlyActiveHidden = hiddenColumns;
                } else if (!showOnlyActive && 
                    hiddenColumnsState.current.allHidden?.join() 
                    !== 
                    hiddenColumns?.join()
                ) {
                    doLogging && console.log("2.B.2 updating hiddenColumnsState.current.allHidden ref to changed hiddenColumns state")

                    hiddenColumnsState.current.allHidden = hiddenColumns;

                }

            }

        }

        window.localStorage.setItem(`${tablePrefix}HiddenColumns`, JSON.stringify(hiddenColumnsState.current))
        doLogging && console.log("hiddenColumnsState.current saved to local storage", hiddenColumnsState.current )

        if (syncRecordsPerPage && keepTableSettings) {

            let storedString = window.localStorage.getItem(`${tablePrefix}pageSize-${showOnlyActive ? "showOnlyActive" : ""}`)
            
            if (storedString && storedString !== 'undefined' && firstRenderRef.current === true) {
                let storedPageSize = JSON.parse(storedString);
                setRecordsPerPage(storedPageSize)
            } else {
                window.localStorage.setItem(`${tablePrefix}pageSize-${showOnlyActive ? "showOnlyActive" : ""}`, JSON.stringify(recordsPerPage))
            }
            
        }

        
        firstRenderRef.current = false
        doLogging && console.timeEnd(`usePersistentTableSettings run`)


    }, [
        hiddenColumns,
        setHiddenColumns, 
        recordsPerPage, 
        showOnlyActive,
        doLogging,
        previousViewRef,
        setRecordsPerPage,
        tablePrefix,
    ]);

}

export default usePersistentTableSettings;