import { QueryFilter, readItems } from "@directus/sdk";
import { Destination } from "../../components/add-to-journal/AddToJournalForm";
import { Collections, Schema } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";

export interface Keyword {
  word: string;
  area_id: number;
  area_name: string;
  type: Collections.Keywords["type"];
  comment?: string;
}

export async function provideKeywords({ destinations }: { destinations: Destination[] }): Promise<Keyword[]> {
  const client = getDirectusClient();
  const keywords: Keyword[] = [];
  const tempDestIDs: number[] = [];
  
  destinations.forEach(dest => {
    tempDestIDs.push(dest.value);
  });

  const filter: QueryFilter<Schema, Collections.Keywords> = {
    _and: [
      {
        lands_n_objects: {
          id: {
            _in: tempDestIDs
          }
        }
      },
      {
        archived: {
          _eq: false
        }
      }
    ]
  }

  try {
    // console.log("calling keywords with filter:\n", filter)
    const keywordsData = await client.request(readItems("keywords", {
      limit: 1000,
      offset: 0,
      sort: ["-updated_at"],
      filter,
      fields: [
        "archived",
        "type",
        "id",
        "word",
        "comment",
        {
          "lands_n_objects": ["id", "area_name"]
        }
      ],
      // signal: controller.signal,
    }));
    // console.log("anyKeywordsData?")

    keywordsData.forEach(res => {
      keywords.push({
        word: res.word,
        area_name: res.lands_n_objects.area_name,
        area_id: res.lands_n_objects.id,
        comment: res.comment,
        type: res.type,
      })
    });
    // console.log("keywords returned: \n", keywords)
    return keywords;
  } catch (e) {
    console.log("error", e)
    throw e;
  }

}
