import React, { useContext } from 'react';
import { MainContext } from '../MainContext';
import { initialTitleTooltips } from "../settings/tooltipsText"
import Spoiler from '../commonUI/Spoiler';
import { getRidOfAliases, getRemainingLifeSpan } from "../../services/littleHelpers"


const ActiveDeposits = ({ depositsData, isLoading }) => {
    const { siteSettings } = useContext(MainContext);
    let dataCopy = JSON.parse(JSON.stringify(depositsData));
    
    const sortByDestName = (a, b) => {
        if (a.dest < b.dest) {
            return -1;
        }
        
        if (a.dest > b.dest) {
            return 1;
        }

        return 0;
    }

    return (
        <>
            <Spoiler 
                header={"Активные депозиты"} 
                contentID={'deposits-container'} 
                addClassName={!isLoading && (!depositsData || depositsData?.length < 1) ? 'empty' : ""}
                isDataLoaded={!isLoading} 
                defaultCollapsed={true}
            >
            {
                
                dataCopy.sort(sortByDestName).map(deposit => (
                        <div key={`${deposit.origin_ID}`} className='deposit'>
                            <div 
                                className='deposit-destination'
                                title={`Окончание срока действия депозита: ${deposit?.expiration_date}`}
                            >
                                {getRidOfAliases(deposit.dest)}
                                {
                                    deposit?.comment &&
                                    <i className='message-importante-container' title={`${siteSettings.tooltips && initialTitleTooltips.depositComment } ${deposit.comment}`} >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="message-importante" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
                                            <line x1="12" y1="8" x2="12" y2="11" />
                                            <line x1="12" y1="14" x2="12" y2="14.01" />
                                        </svg>
                                    </i>
                                }
                                {
                                    !deposit.live_long &&
                                    <div className='deposit-life-span-container' title={``}>
                                        <div className='deposit-life-span' style={{width: `${getRemainingLifeSpan(deposit)}%`}}></div>
                                    </div>
                                }
                            </div>
                            <p className='deposit-category'>
                                {deposit.category}
                                <span className='deposit-available'>
                                    x{deposit.available}
                                </span>
                            </p>

                        </div>
                    ))
                }
            </Spoiler>
        </>
    );
}


function propsAreEqual(prev, next) {
    if (next.depositsData.length > 0) {

        if (prev.depositsData?.length === next.depositsData?.length) {
            return prev.depositsData?.[0]?.last_related_update === next.depositsData?.[0]?.last_related_update;
        } else {
            return false
        }
        
    } else {
        return prev.depositsData === next.depositsData;
    }
}

const ActiveDepositsMemoized = React.memo(ActiveDeposits, propsAreEqual); 

export default ActiveDepositsMemoized;