import React, { useState, useRef, useContext, useMemo, useEffect, useCallback } from 'react';
import { useSearchParams } from "react-router-dom";

import {   
    useTable,
    usePagination,
    useRowSelect,
    useFilters 
} from 'react-table';

import { MainContext } from "../MainContext";
import usePersistentTableSettings from "../../services/usePersistentTableSettings"
import useCachedDataWithUpdates from "../../services/useCachedDataWithUpdates"
import TableRowErrorBoundary from './TableRowErrorBoundary';
import ColumnSettings from './ColumnSettings';
import RevealAllColumns from './RevealAllColumns'; 
import RefreshTable from './RefreshTable'; 
import Toggle from './Toggle';
import ColumnFilter from "./ColumnFilter";
import TableFakeFooter from './TableFakeFooter'; 

const PAGE_NO = 0;

const returnInitialHiddenColumns = ({initialShowOnlyActiveState, initialHiddenColumns, idQuery}) => {
    
    if (!initialHiddenColumns["onlyActiveHidden"] || !initialHiddenColumns["allHidden"]) {
        return initialHiddenColumns;
    }

    if (initialShowOnlyActiveState) {
        return initialHiddenColumns["onlyActiveHidden"]
    } else if (idQuery) {
        return initialHiddenColumns["allHidden"].filter(c => c !== "origin_ID")
    } else {
        return initialHiddenColumns["allHidden"]
    }

}

const returnPageSize = ({dataSettings, tablePrefix, showOnlyActive}) => {

    let meantInitialValue = showOnlyActive ? dataSettings.initialPageSize : dataSettings.altInitialPageSize;
    
    if (!dataSettings.syncRecordsPerPage) {
        return meantInitialValue
    }
    
    let rawStoredValue = window.localStorage.getItem(`${tablePrefix}pageSize-${showOnlyActive ? "showOnlyActive" : ""}`)
   
    if (rawStoredValue && rawStoredValue !== 'undefined') {
        let storedValue = JSON.parse(rawStoredValue);
        return storedValue 
    } else {
        return meantInitialValue 
    }

}


const Table = ({
    initialHiddenColumns = {onlyActiveHidden: [], allHidden: []},
    initialArrayDataObject,
    initialShowOnlyActiveState,
    tablePrefix = "journal",
    location = "/",
    columns,
    altColumns = columns,
    hatComponents,
    returnMemoizedTableRowComponent,
    toggle = {
        enabled: true,
        showOnlyActiveLabel: "Только активные",
        showAllLabel: "Все",
        addClassName: undefined,
        toggleContainerAddClassName: undefined,
        mutateId: false,
        isToggleDisabled: false,
        onlyHat: false,
        setId: "setShowOnlyActiveSwitch",
        insertBefore: undefined,
    },
    freezeRef,
    dataSettings = {
        dependency: undefined,
        blockFetching: false,
        initialPageSize: 10,
        altInitialPageSize: 10,
        updateFrequency: 5000,
        cachedDataLifeSpan: 60000,
        shallowQuery: false,
        onViewSwitchFilterCondition: "(is_active,eq,true)",
        offViewSwitchFilterCondition: undefined,
        sortString:"-updated_at",
        altSortString: "-updated_at",
        localFieldToCompareBy: "updated_at_full",
        remoteFieldToCompareBy: "updated_at",
        keepTableSettings: true,
        syncRecordsPerPage: true,
    },
    tableFooter = {
        allowedRezultsPerPageArray: [10, 20, 30, 50],
        minimalControls: false,
    },
    insertAfterTable,
    noEntriesMessage = "Нет подходящих записей",
    idQuery,
    forceReloadRef,
    // searchParams,
}) => {
    const { siteSettings, userData } = useContext(MainContext);
    const [showOnlyActive, setShowOnlyActive] = useState(initialShowOnlyActiveState);
    const [tableData, setTableData] = useState(initialArrayDataObject);
    const [editedEntryID, setEditedEntryID] = useState(false);
    const [totalPage, setTotalPages] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(returnPageSize({dataSettings, tablePrefix, showOnlyActive}));
    const tbodyRef = useRef();
    const previousViewRef = useRef(initialShowOnlyActiveState);
    const previouslySelectedRowRef = useRef();
    const [searchParams] = useSearchParams();


    const defaultColumn = useMemo(
        () => ({
          Filter: ColumnFilter,
        }),
        []
    );

    const defaultFilters = useMemo(() => {
        let filters = [];

        for (const [key, value] of searchParams.entries()) {

            if (!!altColumns.find(el => el?.accessor === key)) {

                filters.push({
                    id: `${key}`,
                    value: value,
                })

            }

        }

        return filters;
        
    },[searchParams]);

    const thisInitialHiddenColumns = returnInitialHiddenColumns({initialShowOnlyActiveState, initialHiddenColumns, idQuery})

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        toggleHideColumn,
        setHiddenColumns,
        setFilter,
        setAllFilters,
        selectedFlatRows,
        toggleAllRowsSelected,
        state: { pageIndex, filters, hiddenColumns},
    } = useTable({
        columns: showOnlyActive ? columns : altColumns, 
        data: tableData,
        // data: tableDataMemoized,
        autoResetHiddenColumns: false,
        // autoResetSelectedRows: true,
        defaultColumn,
        manualFilters: true,
        // autoResetFilters: false,
        // filterTypes,
        initialState: {
            filters: defaultFilters,
            hiddenColumns: thisInitialHiddenColumns,
            pageIndex: PAGE_NO,
            pageSize: recordsPerPage,
            },
        manualPagination: true,
        pageCount: totalPage,
    },
        useFilters,
        usePagination,
        useRowSelect,
    )

    const gotoPageCallback = useCallback((e) => {
        gotoPage(e)
    }, [])

    const [
        isDataLoading,
        tableDataRef,
        justAlteredRowsRef,
        totalEntries,
        deletingRowsRef,
        forceQueryRef,
        isToggleDisabled,
    ] = useCachedDataWithUpdates({
        location,
        disabled: dataSettings.blockFetching,
        freeze: selectedFlatRows?.length > 0 ? true : false,
        freezeRef,
        dataSource: tablePrefix,
        updateFrequency: dataSettings.updateFrequency,
        cachedDataLifeSpan: dataSettings.cachedDataLifeSpan,
        shallowQuery: dataSettings.shallowQuery,
        initialShowOnlyActiveState: initialShowOnlyActiveState,
        showOnlyActive,
        onViewSwitchFilterCondition: dataSettings.onViewSwitchFilterCondition,
        offViewSwitchFilterCondition: dataSettings.offViewSwitchFilterCondition,
        sortString:dataSettings.sortString,
        altSortString: dataSettings.altSortString,
        localFieldToCompareBy: dataSettings.localFieldToCompareBy,
        remoteFieldToCompareBy: dataSettings.remoteFieldToCompareBy,
        nocodb_auth: userData.nocodb_auth,
        pageIndex,
        setTotalPages,
        gotoPage: gotoPageCallback,
        recordsPerPage,
        filters,
        tableData,
        setTableData,
        editedEntryID,
        forceReloadRef,
        hiddenColumns,
        loggingEnabled: !!siteSettings?.[`${tablePrefix}_loggingEnabled`],
    });

    // console.log('siteSettings?.[`${tablePrefix}_loggingEnabled`]', tablePrefix, siteSettings?.[`${tablePrefix}_loggingEnabled`])
    // console.log('dataSettings.syncRecordsPerPage', dataSettings.syncRecordsPerPage)

    usePersistentTableSettings({
        keepTableSettings: dataSettings.keepTableSettings,
        syncRecordsPerPage: dataSettings.syncRecordsPerPage,
        tablePrefix: `${tablePrefix}`,
        previousViewRef,
        showOnlyActive,
        hiddenColumns,
        setHiddenColumns,
        recordsPerPage,
        setRecordsPerPage,
        initialHiddenColumns,
        // doLogging: true,
    })


    useEffect(() => {

        toggleAllRowsSelected(false)
        
        if (!dataSettings.syncRecordsPerPage) {
            setRecordsPerPage(
                returnPageSize({dataSettings, tablePrefix, showOnlyActive})
            )
        }

    }, [showOnlyActive]);

    useEffect(() => {

        for (const [key, value] of searchParams.entries()) {

            if (!!altColumns.find(el => el?.accessor === key)) {
                setFilter(key, value)
                toggleHideColumn(key, false)
            }

        }

    }, [searchParams]);

    const handleToggleActiveView = () => {
        previousViewRef.current = showOnlyActive;
        setShowOnlyActive(!showOnlyActive)
    }

    const rerenderTable = () => {
        setEditedEntryID(editedEntryID => !editedEntryID)
    }

    const handleProblems = () => {
        console.log("Table called %chandleProblems%c, something wrong happened on JounalRows rendering", "color:initital", "color:red")
        setHiddenColumns(thisInitialHiddenColumns);
        setTableData([])
        forceQueryRef.current = true;
        rerenderTable();
    }

    
    return (
        <div id={`${tablePrefix}-table-container`}>
            { hatComponents && hatComponents }
            { 
                toggle.enabled &&
                <div 
                    id={`${toggle.mutateId ? tablePrefix + "-" : ""}toggle-container`} 
                    className={`${toggle.toggleContainerAddClassName ? " boxProperty" : ""}`}
                >
                    {toggle.insertBefore}
                    <Toggle
                        addClassName={`${!hatComponents || toggle.onlyHat ? "only-hat" : ""}${toggle?.addClassName ? " " + toggle?.addClassName : ""}`}
                        disabled={isToggleDisabled} 
                        checked={showOnlyActive}
                        id={toggle.setId}
                        changeFn={handleToggleActiveView} 
                        label={showOnlyActive ? toggle.showOnlyActiveLabel : toggle.showAllLabel}
                    />
                </div>
            }
            <table id={`${tablePrefix}-table-entity`} key={`${tablePrefix}-table`} {...getTableProps()}>
                <thead key={`${tablePrefix}-thead`}>
                {
                    headerGroups.map((headerGroup, i) => {
                      const { key, ...headerGroupsProps } = headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={`head-row-${i}-${key}`} {...headerGroupsProps}>
                            {
                                headerGroup.headers.map(column => {
                                  const { key, ...headerProps} = column.getHeaderProps();
                                  return (
                                    <th key={`header-${column.id}-${key}`} {...headerProps}>
                                        <div className={`header-container-${column.id}`} key={`header-container-${column.id}`}>
                                            {
                                                column.canFilter ?
                                                column.render("Filter")
                                                :
                                                column.id !== "selection" ?
                                                <div className='column-header'>{column.render('Header')}</div>
                                                :
                                                column.render('Header')
                                            }
                                        </div>
                                        {
                                            column.id !== "selection" &&
                                            <ColumnSettings 
                                                column={column}
                                                columns={columns}
                                                hiddenColumns={hiddenColumns}
                                                showTooltips={siteSettings.tooltips}
                                                thisInitialHiddenColumns={thisInitialHiddenColumns}
                                                setHiddenColumns={setHiddenColumns}
                                            />
                                        }
                                    </th>
                                  );
                                })
                            }
                            <th id="column-settings">
                                <RevealAllColumns 
                                    hiddenColumns={hiddenColumns}
                                    thisInitialHiddenColumns={thisInitialHiddenColumns}
                                    setHiddenColumns={setHiddenColumns}
                                    showTooltips={siteSettings.tooltips}
                                />
                            </th>
                            {
                                filters.length > 0 &&
                                <th id="column-refresh">
                                    <RefreshTable 
                                        filters={filters}
                                        setAllFilters={setAllFilters}
                                    />
                                </th>
                            }
                        </tr>
                      );
                    })
                }
                </thead>
                <TableRowErrorBoundary
                    hiddenColumns={hiddenColumns}
                    columns={columns}
                    debugData={rows}
                    handleProblems={handleProblems}
                    dataSource={tablePrefix}
                >
                    {
                        isDataLoading ? 
                        <tbody key={"tbody-loads"} {...getTableBodyProps()}>
                            <tr key={"tbody-loads-tr"}>
                                <td 
                                    key={"tbody-loads-tr-td"} 
                                    className='not-cool-status-container'
                                    colSpan={hiddenColumns?.length !== 0 ? columns.length - 1 : columns.length}
                                >
                                    <div className='not-cool-status'>...загрузка</div>
                                </td>
                            </tr>
                        </tbody>
                        :
                        <tbody ref={tbodyRef} id={`${tablePrefix}-table-body`} key={`tbody---here`} {...getTableBodyProps()}>
                        {
                            rows?.length === 0 ?
                            <tr>
                                <td className='not-cool-status-container' colSpan={hiddenColumns?.length !== 0 ? columns.length - 1 : columns.length}>
                                    <div className='not-cool-status'>{noEntriesMessage}</div>
                                </td>
                            </tr>
                            :
                            rows.map(row => {

                                prepareRow(row);

                                return returnMemoizedTableRowComponent({
                                    row,
                                    hiddenColumnsLength: hiddenColumns?.length,
                                    tableDataRef,
                                    deletingRowsRef,
                                    justAlteredRowsRef,
                                    setEditedEntryID,
                                    showOnlyActive: showOnlyActive,
                                    selectedFlatRows,
                                    previouslySelectedRowRef,
                                });

                            })
                        }
                        </tbody>
                    }
                </TableRowErrorBoundary>
            </table>
            {   
                totalPage > 1 &&
                <TableFakeFooter
                    isLoading={isDataLoading}
                    prefix={tablePrefix}
                    tableDataRef={tableDataRef}
                    totalEntries={totalEntries}
                    page={page}
                    pageIndex={pageIndex}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    totalPage={totalPage}
                    recordsPerPage={recordsPerPage}
                    minimalControls={tableFooter.minimalControls}
                    setRecordsPerPage={!tableFooter.minimalControls ? setRecordsPerPage : undefined}
                    allowedRezultsPerPageArray={!tableFooter.minimalControls ? tableFooter.allowedRezultsPerPageArray : undefined}
                />
            }
            {
                insertAfterTable ?
                insertAfterTable({
                    isDataLoading,
                    toggleAllRowsSelected,
                    selectedFlatRows,
                    justAlteredRowsRef,
                })
                :
                null
            }
        </div>
    );


}

export default Table;