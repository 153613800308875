
import React, { useEffect, useRef, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select, { createFilter } from 'react-select';


import LoadingIcon from "../commonUI/LoadingIcon";
import { reasonableFilterConfig, CreatableBrandSelect, FileInput, onSelectInputChange } from "../inputElements";
import useSelectOptions from '../../services/useSelectOptions';
import { createEntryInBlacklist } from "../../services/CRUDJournalData"
import { notifySecurityChat, getRidOfAliases } from "../../services/littleHelpers"


const SelectSanction = ({setValue, control, errors}) => {
    const sanctionsOptions = [
        {label: "Запрет допуска", value: "Запрет допуска"},
        {label: "Изъять пропуск", value: "Изъять пропуск"},
    ]
    const firstRender = useRef(true);

    useEffect(() => {
        
        if (firstRender.current === true) {
            setValue('sanction', sanctionsOptions[0]);
            firstRender.current = false;
        }

    },[])
    

    return (
        <>
             <label htmlFor={`verify-adding-to-bl-sanction`}>Санкция</label>
                <Controller
                    control={control}
                    name={`sanction`}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                            ref={ref}
                            onChange={(e) => { onChange(e); } }
                            onBlur={ (e) => { onBlur(e)} }
                            options={sanctionsOptions}
                            isSearchable={false}
                            classNamePrefix="select"
                            id={'verify-adding-to-bl-sanction'}
                            value={value}
                            captureMenuScroll={true}
                            closeMenuOnScroll={true}
                        />
                    )}
                    rules={{ required: "Выберите из списка" }}
                />
                <ErrorMessage
                    errors={errors}
                    name={`brand`}
                    render={({message}) => <span className="errorMessage">{message}</span>}
                />
        </>
    );
}

const AddToBlacklistDialog = ({siteSettings, userData, closeDialogFunction}) => {
    const [proceed, setProceed] = useState("idle"); // "idle", "start", "success", "failed"
    const [formState, setFormState] = useState('readyForInput');
    // ! formState can be:
    // readyForInput
    // sendingData
    // success
    // errorOnSubmit
    const errorOnSubmit = useRef([]);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        criteriaMode: "all",
    });

    const [
        areDestinationOptionsLoading,
        allDestinationOptionsRef,
        destinationOptions,
        setDestinationOptions,
    ] = useSelectOptions({
        siteSettings,
        nocodb_auth: userData.nocodb_authC,
        optionName: "destinationOptions",
    })

    const [
        areBrandsLoading,
        allBrandsOptionsRef,
        brandsOptions,
        setBrandsOptions,
    ] = useSelectOptions({
        siteSettings,
        nocodb_auth: userData.nocodb_auth,
        optionName: "brandsOptions",
    })

    const handleFilesUpload = (filesLinks) => {

        if (filesLinks) {
            setValue('files', filesLinks);
        } 

    }

    const onDestinationsInputChange = (value, {action}) => {
        onSelectInputChange(value, destinationOptions, setDestinationOptions, allDestinationOptionsRef, action )
    }

    const saveFormData = async () => {
        window.scrollTo(0, 0);

        let intervalId = window.setInterval(async () => {
            let data = getValues();

            /**
             * FileInput will set data.files to empty array,
             * if there are no files staged to upload
             */

            if (data.files !== undefined) {
                clearInterval(intervalId);

                if (data?.files.length < 1 ) {
                    delete data.files;
                }

                try {
                    let result = await createEntryInBlacklist(userData, data);

                    if (result.status !== 200) {
                        throw new Error(`Request to ${result.url} failed with status: ${result.status}. StatusText: ${result.statusText}`)
                    }

                    const notificationData = {
                        brand_name: getRidOfAliases(data.brand.label),
                        plate_number: data.number,
                        area_name: data.relatedArea?.label,
                        sanction: data.sanction.label,
                        comment: data.comment,
                        files: data.files,
                    }

                    const response = await notifySecurityChat({"blacklist-added": notificationData});
                    
                    if (response?.status !== 200) {
                        window.alert("Машина добавлена в ЧС, но уведомление в чат не доставлено!")
                    }

                    closeDialogFunction(false);
                    setFormState('success');

                } catch (err) {
                    errorOnSubmit.current = err;
                    setFormState('errorOnSubmit');
                    clearInterval(intervalId)
                }

            }

        }, 1000)
        
    }
    
    const onSubmit = async () => {

        try {
            setFormState("sendingData");
            setProceed("start") // if files chosen - upload them;

            await saveFormData(); 

        } catch (e) {
            setFormState("errorOnSubmit");
        }
    }

    const onError = () => { 
        window.scrollTo(0, 0)
    }


    return (
        <div id='blacklist-confirmation-container'>
            {
                formState !== "errorOnSubmit" &&
                <h1>Внести в <br/>Чёрный список</h1>
            }
            <LoadingIcon addClassName={`${formState === "sendingData" ? "" : "invisible"}`} />
            <form 
                id='addToBlacklistForm' 
                onSubmit={handleSubmit(onSubmit, onError)}
                className={`${formState === "readyForInput" || formState === "success" ? "visible" : ""}`} 
            >
                <div className='boxProperty'>
                <label htmlFor={`relatedArea`}>Связанный объект</label> 
                    <Controller
                        control={control}
                        name={`relatedArea`}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Select
                                key={`relatedArea`}
                                id={`relatedArea`}
                                ref={ref}
                                isClearable
                                onChange={(e) => { onChange(e); } }
                                onBlur={ (e) => {onBlur(e)} }
                                options={destinationOptions}
                                isLoading={areDestinationOptionsLoading}
                                loadingMessage={() => {return "Идёт загрузка..."}}
                                noOptionsMessage={() => {return "Нет опций"}}
                                placeholder=""
                                isSearchable={true}
                                filterOption={createFilter(reasonableFilterConfig)}
                                onInputChange={onDestinationsInputChange}
                                value={value}
                                classNamePrefix="select"
                                defaultValue={undefined}
                            />
                        )}
                    />
                </div>
                <div className='boxProperty'>
                    <label htmlFor={`verify-adding-to-bl-number`}>Номер</label>
                    <input 
                        id={`verify-adding-to-bl-number`} 
                        className='classyInput'
                        defaultValue={null}
                        spellCheck={false}
                        {...register(`number`, {required: "Если номера нет, так и напишите"})}
                    />
                    <ErrorMessage
                        errors={errors}
                        name={`number`}
                        render={({message}) => <span className="errorMessage">{message}</span>}
                    />
                </div>
                <div className='boxProperty'>
                    <label htmlFor={`verify-adding-to-bl-brand`}>Марка</label>
                    <Controller
                        control={control}
                        name={`brand`}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <CreatableBrandSelect
                                forwardRef={ref}
                                // localStorageName={"brandsOptionsNCDB"}
                                localStorageName={"brandsOptions=all"}

                                allOptionsRef={allBrandsOptionsRef}
                                options={brandsOptions} 
                                setOptions={setBrandsOptions} 
                                isDisabled={false}
                                isLoading={areBrandsLoading}
                                onBlur={onBlur}
                                value={value}
                                onChangeFunction={onChange}
                                loadingMessage={() => {return "Идёт загрузка..."}}
                                noOptionsMessage={() => {return "Нет опций"}}
                                isSearchable={true}
                                required
                            />
                        )}
                        rules={{ required: "Выберите из списка" }}
                    />
                    <ErrorMessage
                        errors={errors}
                        name={`brand`}
                        render={({message}) => <span className="errorMessage">{message}</span>}
                    />
                </div>
                <div className='boxProperty'>
                    <SelectSanction
                        setValue={setValue}
                        control={control}
                        errors={errors}
                    />
                </div>
                <div className='boxProperty'>
                    <label htmlFor={`verify-adding-to-bl-comment`}>Комментарий</label>
                    <textarea 
                        id={`verify-adding-to-bl-comment`} 
                        className='classyInput'
                        defaultValue={null}
                        spellCheck={true}
                        {...register(`comment`)}
                    />
                </div>
                <div className='boxProperty'>
                    <FileInput
                        inputID={`verify-adding-to-bl-file`}
                        ButtonAdditionalClassName={`file-upload-label`}
                        pathToSaveFiles={`blacklist_vehicles_files`}
                        proceed={proceed}
                        setProceed={setProceed}
                        setUploadedFilesLinks={handleFilesUpload}
                    />
                </div>
                <button 
                    disabled={false}
                    className='button submit-to-blacklist'
                    type="submit"
                >
                    Добавить
                </button>
            </form>
            {
                formState === "errorOnSubmit" &&
                <div id="error-on-submit-container" className='visible'>
                    <p>Добавить не удалось. Пожалуйста, попробуйте ещё раз.</p>
                    { errorOnSubmit.current?.status && errorOnSubmit.current?.statusText ?
                        <>
                            <p>Код ошибки: <code>{errorOnSubmit.current?.status}</code></p>
                            <p>Текст ошибки: <code>{errorOnSubmit.current?.statusText}</code></p>
                        </>
                    :
                        <>
                            <p>Текст ошибки: <code>{errorOnSubmit.current?.name} | {errorOnSubmit.current?.message}</code></p>
                        </>
                    }
                </div>
            }
        </div>
    );
}

export default AddToBlacklistDialog;