import React, { useRef, useEffect, useState, useContext, useCallback, useMemo } from "react";

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import CreatableSelect from 'react-select/creatable';
import Select, { createFilter, components } from 'react-select';

import { MainContext, MainContextType } from "../MainContext";
import { focusNextElementOnEvent, getRidOfAliases } from "../../services/littleHelpers"
import useSelectOptions from '../../services/useSelectOptions';

import { onSelectInputChange } from "../inputElements"
import { provideJournalEntriesCategories } from "../../services/ProvideDataObjects";
import { createJournalRows } from "../../services/CRUDJournalData"
import useCachedDataWithUpdates from "../../services/useCachedDataWithUpdates";
import { initialTitleTooltips } from "../settings/tooltipsText"
// import ModalDialog from '../modals/ModalDialog';
import KeyWordContainer from "./keyWordContainer";
import LoadingIcon from "../commonUI/LoadingIcon";
import { searchForDuplicates } from "./JournalEntryMergingConfirmation";
import JournalEntryMergingConfirmation from "./JournalEntryMergingConfirmation";

const categoryOptions = provideJournalEntriesCategories();

interface FormValues {
  destination: string[];
  vehicles: VehicleEntry[];
}

interface VehicleEntry {
  targetAreaID: null | number | number[];
  number: string;
  brand: { value: string; label: string };
  category: { value: string; label: string };
  comment: string;
  daily: boolean;
  debt: boolean;
  entered: boolean;
}

export interface Destination {
  value: number;
  label: string;
  area_status?: string;
}

interface SelectOption {
  value: string;
  label: string;
}

type FormState = "readyForInput" | "sendingData" | "success" | "errorOnSubmit";

interface ErrorData {
  status?: number;
  statusText?: string;
  name?: string;
  message?: string;
}

const AddToJournalForm: React.FC = () => {
  const [formState, setFormState] = useState<FormState>('readyForInput');
  const [titleTooltips, setTitleTooltips] = useState<typeof initialTitleTooltips | undefined>(initialTitleTooltips);
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [possibleDuplicates, setPossibleDuplicates] = useState([]);
  const [dataToSend, setDataToSend] = useState<any[]>([]);

  const [hasStashedContent, setHasStashedContent] = useState(false)
  const { siteSettings, userData } = useContext<MainContextType>(MainContext);
  const firstRender = useRef(true);
  const aniSpeedVar = useRef<string | undefined>(undefined);
  const previousFormAction = useRef<'append' | 'remove' | 'dublicate' | undefined>(undefined);

  const lastDublicatedItem = useRef(undefined);
  const lastStashTimestamp = useRef(0);
  const stashedContent = useRef(undefined);
  const deleteFallbacks = useRef([])
  const errorData = useRef<ErrorData | null>(null);
  const successType = useRef(undefined);
  const createdBrandIdRef = useRef(undefined);

  const reasonableFilterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.label}`,
    trim: true,
  }

  /*
  * ValueContainer and MultiValueContainer below - parts of react-select API, aimed at customizing default
  * form components, found solution here: https://github.com/JedWatson/react-select/discussions/4647
  * more details on components API here: https://react-select.com/props#statemanager-props
  */
  const ValueContainer = (props) => {
    const innerProps = { ...props.innerProps, title: props.selectProps?.title };
    return <components.ValueContainer {...props} innerProps={innerProps} />;
  };

  // const MultiValueContainer = (props) => {
  //     const innerProps = { ...props.innerProps, title: props.selectProps?.title };
  //     return <components.MultiValueContainer {...props} innerProps={innerProps} />;
  // };

  const MultiValueContainer = (props) => {

    const innerProps = {
      ...props.innerProps,
      title: props.data?.area_status ? `${props.data?.area_status.replace(/,/g, ", ")}` : ""
    };

    return <components.MultiValueContainer {...props} innerProps={innerProps} />;

  };

  const defaultVehicleObject = useMemo<VehicleEntry>(() => ({
    targetAreaID: null,
    number: "",
    brand: null,
    category: categoryOptions[0],
    comment: "",
    daily: false,
    debt: false,
    entered: false,
  }), []);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    criteriaMode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "vehicles",
  })

  useEffect(() => {
    if (siteSettings?.tooltips === null || siteSettings?.tooltips === undefined) {
      setTitleTooltips(initialTitleTooltips);
    } else {
      if (siteSettings.tooltips === true) {
        setTitleTooltips(initialTitleTooltips);
      } if (siteSettings.tooltips === false) {
        setTitleTooltips(undefined)
      }
    }
  }, [siteSettings]);

  const [
    isActiveJournalDataLoading,
    activeJournalDataRef,
    // @ts-expect-error fix possible only after useCachedDataWithUpdates is migrated to TS
  ] = useCachedDataWithUpdates({
    location: '/add',
    dataSource: 'dezhJournal',
    updateFrequency: 10000,
    cachedDataLifeSpan: 180000,
    showOnlyActive: true,
    onViewSwitchFilterCondition: "(is_active,eq,true)",
    localFieldToCompareBy: "updated_at_full",
    remoteFieldToCompareBy: "updated_at",
    sortString: "-updated_at",
    nocodb_auth: userData.nocodb_auth,
    pageIndex: 0,
    recordsPerPage: 500,
    loggingEnabled: !!siteSettings?.[`journal_loggingEnabled`],
  });

  const [
    areDestinationOptionsLoading,
    allDestinationOptions,
    destinationOptions,
    setDestinationOptions,
    // @ts-expect-error fix possible only after useSelectOptions is migrated to TS
  ] = useSelectOptions({
    siteSettings,
    optionName: "destinationOptions",
  })

  const [
    areBrandsLoading,
    allBrandsOptions,
    brandsOptions,
    setBrandsOptions,
    // @ts-expect-error fix possible only after useSelectOptions is migrated to TS
  ] = useSelectOptions({
    siteSettings,
    nocodb_auth: userData.nocodb_auth,
    optionName: "brandsOptions",
    valueToTriggerRecall: createdBrandIdRef.current,
  })


  useEffect(() => {
    // console.log("$$$ initiating value setter")

    if (firstRender.current === true && formState === "readyForInput") {
      aniSpeedVar.current = getComputedStyle(document.body).getPropertyValue('--ani-speed');


      if (!window.localStorage.getItem("stagingValues")) {
        append(defaultVehicleObject)

        window.focus()

      } if (window.localStorage.getItem("stagingValues")) {
        const stagingValuesString = window.localStorage.getItem("stagingValues");

        const tempStashedValues = JSON.parse(stagingValuesString);
        stashedContent.current = tempStashedValues;
        setDestinations(tempStashedValues.destination);
        setValue('destination', tempStashedValues.destination)
        tempStashedValues.vehicles.forEach(vehicle => {
          append(vehicle)
        });
        setHasStashedContent(true)
        window.focus()

      }

      firstRender.current = false

    }

  }, [formState, setValue, append, defaultVehicleObject])

  const stageValues = useCallback((e) => {
    const stashFrequency = 500;
    // console.log("stageValues runs")

    if (e.timeStamp - lastStashTimestamp.current > stashFrequency) {
      const tempValues = getValues();
      if (tempValues.destination === undefined) {
        tempValues.destination = [];
      }

      const knownString = window.localStorage.getItem("stagingValues")
      const newString = JSON.stringify(tempValues)

      if (knownString !== newString) {
        window.localStorage.setItem("stagingValues", newString)

        lastStashTimestamp.current = e.timeStamp;
        setHasStashedContent(true)
        stashedContent.current = newString;
      }
    }

  }, [getValues])

  useEffect(() => {
    const formElement = document.getElementById("add-to-journal-form");

    formElement.addEventListener("focusout", stageValues);

    return () => {
      formElement.removeEventListener("focusout", stageValues)
    }
  }, []);

  useEffect(() => {
    let commentElement: HTMLElement | null, categoryElement: HTMLElement | null;

    if (lastDublicatedItem.current !== undefined) {
      commentElement = document.getElementById(`${lastDublicatedItem.current.id}-propertyComment`);
      categoryElement = document.getElementById(`${lastDublicatedItem.current.id}-propertyCategory`);
    } if (lastDublicatedItem.current !== undefined &&
      commentElement !== null &&
      commentElement.classList.contains("visible")) {
      const dublicateID = fields[fields.length - 1].id;
      document.getElementById(`${dublicateID}-propertyComment`).classList.add('visible');
      document.getElementById(`${dublicateID}-buttonComment`).style.display = "none";
    } if (lastDublicatedItem.current !== undefined &&
      categoryElement !== null &&
      categoryElement.classList.contains("visible")) {
      const dublicateID = fields[fields.length - 1].id;
      document.getElementById(`${dublicateID}-propertyCategory`).classList.add('visible');
      document.getElementById(`${dublicateID}-buttonCategory`).style.display = "none";
    }

    lastDublicatedItem.current = undefined;

    if (fields.length > 1 && previousFormAction.current !== "remove") {
      const lastItemIndex = fields.length - 1;
      const lastItemElement = document.getElementById(`${fields[lastItemIndex].id}-vehicleLine`);
      window.scrollTo(0, lastItemElement.offsetTop);
    }

    if (hasStashedContent === true) {

      window.setTimeout(() => {
        fields.forEach(e => {
          const fieldID = e.id;

          if (e.category.value !== defaultVehicleObject.category.value || e.debt !== defaultVehicleObject.debt) {
            document.getElementById(`${fieldID}-propertyCategory`).classList.add('visible');
            document.getElementById(`${fieldID}-buttonCategory`).style.display = "none";
          } if (e.comment !== defaultVehicleObject.comment) {
            document.getElementById(`${fieldID}-propertyComment`).classList.add('visible');
            document.getElementById(`${fieldID}-buttonComment`).style.display = "none";
          }
        });

      }, 100)
    }

  }, [
    fields,
    defaultVehicleObject.category.value,
    defaultVehicleObject.comment,
    defaultVehicleObject.debt,
    defaultVehicleObject.entered,
    hasStashedContent
  ])


  const onDestinationsInputChange = (value, { action }) => {
    onSelectInputChange(value, destinationOptions, setDestinationOptions, allDestinationOptions, action)
  }

  const onBrandsInputChange = (value, { action }) => {
    onSelectInputChange(value, brandsOptions, setBrandsOptions, allBrandsOptions, action)
  }

  const handleSuccessfulSubmission = () => {
    /**
     * 1) store just sent data in local history
     * 2) remove @stashedContent and clear corresponding
     *    value from local storage
     * 3) replace all used form fields with @defaultVehicleObject
     */

    if (!window.localStorage.getItem("submissionsHistory")) {
      const values = JSON.parse(window.localStorage.getItem("stagingValues"))
      values["submissionDate"] = Date.now();
      values["submissonStatus"] = successType.current;
      const firstEntry = [values]
      window.localStorage.setItem("submissionsHistory", JSON.stringify(firstEntry))
    } else {
      const currentHistory = JSON.parse(window.localStorage.getItem("submissionsHistory"))
      const values = JSON.parse(window.localStorage.getItem("stagingValues"))
      values["submissionDate"] = Date.now();
      values["submissonStatus"] = successType.current;
      currentHistory.unshift(values);
      if (currentHistory.length > 100) {
        currentHistory.splice(-1, 1);
      }
      localStorage.setItem("submissionsHistory", JSON.stringify(currentHistory));
    }

    moveFormToInitialState();
  }

  const moveFormToInitialState = () => {
    window.localStorage.removeItem("stagingValues");
    setHasStashedContent(false);
    setDestinations([]);
    setValue('destination', []);
    remove();
    previousFormAction.current = "remove";
    append(defaultVehicleObject);
    setPossibleDuplicates([]);
  }

  const saveFormData = async (data, isNested = false) => {
    window.scrollTo(0, 0);

    // console.log("data to create journal rows", data)

    const response = await createJournalRows(userData, data, createdBrandIdRef);

    if (response && 'status' in response && response.status !== 200) {
      const responseMessage = 'text' in response ? await response.text() : 'Unknown error';
      throw new Error(`${response.status} | ${response.statusText} | ${responseMessage}`);
    } else if (response && 'status' in response) {
      successType.current = response.statusText;
      /**
       *  below code will artificially slow down
       *  form submission to make it's flashing
       *  less aggressive. + clearing values
       *  BEFORE state update to show blanc
       *  form for further submissions.
       */
      if (!isNested) {

        const timeout_id = window.setTimeout(() => {
          handleSuccessfulSubmission();
          setFormState("success")
          clearTimeout(timeout_id);
        }, 450)

      }

    }

    return response;

  }

  const onSubmit = async (data) => {
    console.time("onSubmit");

    const processData = (data) => {
      const pedCategory = {
        value: "Пешеход",
        label: "Пешеход"
      }

      data.vehicles.forEach(v => {
        if (v.brand.label === "Пешеход") {
          v.category = pedCategory;
        }
        v.created_by = userData.roles.join();
      });

      const betterData = data;

      return betterData;
    }

    try {
      setFormState("sendingData");
      const processedData = processData(data);

      const duplicatesFound = await searchForDuplicates({
        processedData,
        proceedFunction: async () => await saveFormData(processedData),
        activeJournalDataRef,
      } as any); // remove "any" after useCachedDataWithUpdates is migrated to TS

      if (duplicatesFound?.length === 0) {
        await saveFormData(processedData)

      } else {
        setDataToSend(processedData);
        setPossibleDuplicates(duplicatesFound);
      }

    } catch (e) {
      errorData.current = e;
      setFormState("errorOnSubmit");
    }

    console.timeEnd("onSubmit");

  }

  const onError = () => {
    window.scrollTo(0, 0)
  }

  const dublicateVehicleLine = index => {
    const formValues = getValues();

    const dublicatedItemID = fields[index].id;
    lastDublicatedItem.current = { id: dublicatedItemID, index: index };

    /*
     * lastDublicatedItem.current is used in useEffect hook
     * to replicate copied line fields visibility. This is required,
     * because actions need to be triggered per render, as
     * reffered in docs https://react-hook-form.com/api/usefieldarray
     */

    append({
      number: formValues.vehicles[index].number,
      entered: formValues.vehicles[index].entered,
      brand: formValues.vehicles[index].brand,
      category: formValues.vehicles[index].category,
      targetAreaID: formValues.vehicles[index].targetAreaID,
      comment: formValues.vehicles[index].comment,
      daily: formValues.vehicles[index].daily,
      debt: formValues.vehicles[index].debt,
    })
  }

  const commentLine = (itemID) => {
    const commentElement = document.getElementById(`${itemID}-propertyComment`);
    commentElement.classList.add('visible');
    document.getElementById(`${itemID}-buttonComment`).style.display = "none";
    commentElement.querySelectorAll("input")[0].focus();
  }

  const addCategoryForLine = (itemID) => {
    const categoryElement = document.getElementById(`${itemID}-propertyCategory`);
    categoryElement.classList.add('visible');
    document.getElementById(`${itemID}-buttonCategory`).style.display = "none";
    categoryElement.querySelectorAll("input")[0].focus();
  }

  const deleteLine = (e, index, itemID) => {
    const buttonElement = document.getElementById(`${itemID}-delete-button`);
    const deletingElement = document.getElementById(`${itemID}-vehicleLine`);
    let timeout;

    if (buttonElement.classList.contains("fallback")) {
      const thisFallbackIndex = deleteFallbacks.current.findIndex((e) => e.id === itemID)
      const thisFallback = deleteFallbacks.current[thisFallbackIndex];
      timeout = thisFallback.timeout
      clearTimeout(timeout);
      buttonElement.classList.remove("fallback");
      deletingElement.classList.remove("deleting")

      deleteFallbacks.current.splice(thisFallbackIndex, 1);

      return
    } if (!buttonElement.classList.contains("fallback")) {
      timeout = setTimeout(() => {
        deletingElement.classList.add("fadingOut")
        window.setTimeout(() => {
          const currentIndex = fields.findIndex((e) => {
            return itemID === e.id
          });
          remove(currentIndex);
          deleteFallbacks.current.splice(
            deleteFallbacks.current.findIndex((e) => e.id === itemID),
            1);
        }, parseInt(aniSpeedVar.current))

        stageValues(e);
        previousFormAction.current = "remove";
      }, 3000)

      deleteFallbacks.current.push({ id: itemID, timeout: timeout })
      buttonElement.classList.add("fallback")
      deletingElement.classList.add("deleting")

      return
    }

  }

  const dailyAndDebtCheckboxHandler = (e, itemID) => {

    if (e.target.checked === true) {
      if (e.target.name.includes("daily")) {
        const debtElement = document.getElementById(`${itemID}-debt`) as HTMLInputElement;
        if (debtElement) debtElement.checked = false;
      } if (e.target.name.includes("debt")) {
        const dailyElement = document.getElementById(`${itemID}-daily`) as HTMLInputElement;
        if (dailyElement) dailyElement.checked = false;
      }
    }
  } // only allow to have credit or daily access for one entry

  const produceStashedContentTable = (content) => {
    let destString, vehiclesData;
    const destinations = [];
    const vehicleLines = [];

    if (content?.destination !== undefined) {
      content.destination.forEach(e => {
        destinations.push(e.label)
      })
      destinations.join();

      destString = destinations;

      vehiclesData = content.vehicles;

      vehiclesData.forEach((v, index) => {
        vehicleLines.push(
          <tr key={`${index}-${vehiclesData.length}`}>
            <td>{destString}</td>
            <td>{v?.number}</td>
            <td>{v?.brand?.label}</td>
            <td>{v?.category?.label}</td>
            <td>{v?.entered ? "да" : "нет"}</td>
            <td>{v?.daily ? "да" : "нет"}</td>
            <td>{v?.debt ? "да" : "нет"}</td>
            <td>{v?.comment}</td>
          </tr>
        )
      });
    } else {
      return null;
    }

    return (
      <div id="failed-to-send-data">
        <span>Неотправленные данные:</span>
        <table>
          <thead>
            <tr>
              <td>Адрес</td>
              <td>Номер</td>
              <td>Марка</td>
              <td>Категория</td>
              <td>Въехал</td>
              <td>Суточный</td>
              <td>Кредит</td>
              <td>Комментарий</td>
            </tr>
          </thead>
          <tbody>
            {vehicleLines}
          </tbody>
        </table>
      </div>
    )
  }

  const canBeMarkedEntered = () => {
    const currentValues = getValues();
    const destination = currentValues?.destination?.[0] as any; // Temporary fix, update the type definition later

    if (
      destination?.area_status?.includes("Служебный")
      ||
      destination?.area_status?.includes("Внешний")
      ||
      destination?.area_status?.includes("Свободный доступ")
      ||
      destination?.area_status?.includes("Арендатор")
    ) {
      return true
    }

    return false
  }



  return (
    <>
      <LoadingIcon addClassName={`${formState === "sendingData" ? "" : "invisible"}`} />
      <div id="add-to-journal-wrapper">
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          id="add-to-journal-form"
          className={`${formState === "readyForInput" || formState === "success" ? "visible" : ""}`}
        >
          <div className="boxProperty destinationBox">
            <div className="propertyContainer" title={titleTooltips?.destination}>
              <label htmlFor={`destination`}>Пункт(ы) назначения</label>
              <Controller
                control={control}
                name={`destination`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select<Destination, true>
                    key={`destination`}
                    id={`destination`}
                    ref={ref}
                    components={{ MultiValueContainer }}
                    onChange={(e) => { onChange(e); setDestinations(e as Destination[]) }}
                    onBlur={onBlur}
                    // onBlur={(e) => { onBlur(e) }}
                    options={destinationOptions as Destination[]}
                    isMulti={true}
                    isLoading={areDestinationOptionsLoading}
                    loadingMessage={() => { return "Идёт загрузка..." }}
                    noOptionsMessage={() => { return "Нет опций" }}
                    placeholder=""
                    isSearchable={true}
                    filterOption={createFilter({ ...reasonableFilterConfig, matchFrom: 'any' } as any)}
                    onInputChange={onDestinationsInputChange}
                    value={value as unknown as Destination[]}
                    classNamePrefix="select"
                    defaultValue={undefined}

                  />
                )}
                rules={{ required: "Выберите из списка" }}
              />
              <ErrorMessage
                errors={errors}
                name={`destination`}
                render={({ message }) => <span className="errorMessage">{message}</span>}
              />
            </div>
            <KeyWordContainer destinations={destinations} />
          </div>
          <ul id="vehiclesList">
            {
              fields.length < 1 &&
              <div className="buttons-container">
                <button
                  type="button"
                  className="button add"
                  onClick={() => { setFormState("readyForInput"); append(defaultVehicleObject) }}
                  title={titleTooltips?.buttons?.addFirst}
                >
                  <p>{`${formState === "success" ? "Повторить" : "Добавить"}`}</p>
                  <i>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon-square-plus" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="4" y="4" width="16" height="16" rx="2" />
                      <line x1="9" y1="12" x2="15" y2="12" />
                      <line x1="12" y1="9" x2="12" y2="15" />
                    </svg>
                  </i>
                </button>
              </div>
            }
            {fields.map((item, index) => (
              <li key={item.id} id={`${item.id}-vehicleLine`} className="vehicleLine">
                <div className="boxProperty">
                  <div className="propertyContainer" title={titleTooltips?.number}>
                    <label htmlFor={`${item.id}-number`}>Гос. номер / Имя</label>
                    <input
                      autoComplete="off"
                      key={`${item.id}-number`} // important to include key with field's id
                      className="classyInput"
                      id={`${item.id}-number`}
                      // title={titleTooltips?.number}
                      {...register(`vehicles.${index}.number`, { required: "Если номера нет, так и напишите" })}
                      defaultValue={item.number} // make sure to include defaultValue
                    // autoFocus
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`vehicles.${index}.number`}
                      render={({ message }) => <span className="errorMessage">{message}</span>}
                    />
                  </div>
                  {
                    canBeMarkedEntered() &&
                    <div className="checkboxContainer">
                      <label htmlFor={`${item.id}-entered`}>Прибыл</label>
                      <input
                        className="classyCheckbox"
                        title={titleTooltips?.entered}
                        type="checkbox"
                        id={`${item.id}-entered`}
                        {...register(`vehicles.${index}.entered`)}
                        defaultChecked={item.entered}
                      // onChange={(e) => {dailyAndDebtCheckboxHandler(e, item.id)}}
                      />
                    </div>
                  }
                </div>
                <div className="boxProperty">
                  <div className="propertyContainer" title={titleTooltips?.brand}>
                    <label htmlFor={`${item.id}-brand`}>Марка</label>
                    <Controller
                      control={control}
                      name={`vehicles.${index}.brand`}
                      defaultValue={item.brand}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <CreatableSelect<SelectOption>
                          isClearable
                          key={`${item.id}-brand`}
                          id={`${item.id}-brand`}
                          ref={ref}
                          onChange={(e) => { focusNextElementOnEvent(e); onChange(e); }}
                          onBlur={onBlur}
                          options={brandsOptions as SelectOption[]}
                          isSearchable={true}
                          filterOption={createFilter({ ...reasonableFilterConfig, matchFrom: 'any' } as any)}
                          onInputChange={onBrandsInputChange}
                          isLoading={areBrandsLoading}
                          loadingMessage={() => { return "Идёт загрузка..." }}
                          noOptionsMessage={() => { return "Нет опций" }}
                          placeholder=""
                          formatCreateLabel={(value) => `Добавить новую марку: "${value}"`}
                          value={value}
                          classNamePrefix="creatable-select"
                          defaultValue={defaultVehicleObject.brand}
                        />
                      )}
                      rules={{ required: "Выберите из подходящее или создайте новую" }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`vehicles.${index}.brand`}
                      render={({ message }) => <span className="errorMessage">{message}</span>}
                    />
                  </div>
                  <div className="checkboxContainer">
                    <label htmlFor={`${item.id}-daily`}>24ч</label>
                    <input
                      className="classyCheckbox"
                      title={titleTooltips?.daily}
                      type="checkbox"
                      id={`${item.id}-daily`}
                      {...register(`vehicles.${index}.daily`)}
                      // defaultValue={item.daily}
                      onChange={(e) => { dailyAndDebtCheckboxHandler(e, item.id) }}
                    />
                  </div>
                </div>
                <div className="boxProperty category" id={`${item.id}-propertyCategory`}>
                  <div className="propertyContainer">
                    <label htmlFor={`${item.id}-category`}>Категория</label>
                    <Controller
                      control={control}
                      name={`vehicles.${index}.category`}
                      defaultValue={null}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select<SelectOption>
                          key={`${item.id}-category`}
                          id={`${item.id}-category`}
                          ref={ref}
                          onChange={(e) => { focusNextElementOnEvent(e); onChange(e); }}
                          onBlur={onBlur}
                          options={categoryOptions}
                          noOptionsMessage={() => { return "Нет опций" }}
                          filterOption={createFilter({ ...reasonableFilterConfig, matchFrom: 'any' } as any)}
                          placeholder=""
                          isSearchable={true}
                          value={value}
                          classNamePrefix="select"
                          // title={titleTooltips?.category || ''}
                          components={{ ValueContainer }}
                          defaultValue={defaultVehicleObject?.category}

                        />
                      )}
                    />
                  </div>
                  <div className="checkboxContainer">
                    <label htmlFor={`${item.id}-debt`}>Кредит</label>
                    <input
                      className="classyCheckbox"
                      type="checkbox"
                      title={titleTooltips?.debt}
                      id={`${item.id}-debt`}
                      {...register(`vehicles.${index}.debt`)}
                      defaultChecked={item.debt}
                      onChange={(e) => { dailyAndDebtCheckboxHandler(e, item.id) }}
                    />
                  </div>
                </div>
                <div className="boxProperty comment" id={`${item.id}-propertyComment`}>
                  <label htmlFor={`${item.id}-comment`}>Комментарий</label>
                  <input
                    autoComplete="off"
                    key={`${item.id}-comment`} // important to include key with field's id
                    className="classyInput"
                    title={titleTooltips?.comment}
                    id={`${item.id}-comment`}
                    {...register(`vehicles.${index}.comment`)}
                    defaultValue={item.comment} // make sure to include defaultValue
                  />
                </div>
                <div className="buttons-container">
                  <button
                    title={titleTooltips?.buttons.add}
                    type="button"
                    className="button add"
                    onClick={() => { append(defaultVehicleObject); previousFormAction.current = "append" }}
                  >
                    <p>Добавить</p>
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon-square-plus" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="4" y="4" width="16" height="16" rx="2" />
                        <line x1="9" y1="12" x2="15" y2="12" />
                        <line x1="12" y1="9" x2="12" y2="15" />
                      </svg>
                    </i>
                  </button>
                  <button
                    title={titleTooltips?.buttons?.category}
                    type="button"
                    className="button category"
                    id={`${item.id}-buttonCategory`}
                    onClick={() => { addCategoryForLine(item.id) }}
                  >
                    <p>Категория</p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon-category" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <line x1="16.5" y1="9.4" x2="7.5" y2="4.21" />
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
                      <line x1="12" y1="22.08" x2="12" y2="12" />
                    </svg>
                  </button>
                  <button
                    title={titleTooltips?.buttons?.comment}
                    className="button comment"
                    id={`${item.id}-buttonComment`}
                    type="button"
                    onClick={() => commentLine(item.id)}
                  >
                    <p>Сообщение</p>
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon-comment" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
                        <line x1="12" y1="12" x2="12" y2="12.01" />
                        <line x1="8" y1="12" x2="8" y2="12.01" />
                        <line x1="16" y1="12" x2="16" y2="12.01" />
                      </svg>
                    </i>
                  </button>
                  <button
                    title={titleTooltips?.buttons?.trash}
                    className="button trash"
                    // disabled
                    id={`${item.id}-delete-button`}
                    type="button"
                    onClick={(e) => { deleteLine(e, index, item.id); }}
                  >
                    <p>Удалить</p>
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon-trash" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="4" y1="7" x2="20" y2="7" />
                        <line x1="10" y1="11" x2="10" y2="17" />
                        <line x1="14" y1="11" x2="14" y2="17" />
                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                      </svg>
                    </i>
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icom-undo" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 4.55a8 8 0 0 0 -6 14.9m0 -4.45v5h-5" />
                        <line x1="18.37" y1="7.16" x2="18.37" y2="7.17" />
                        <line x1="13" y1="19.94" x2="13" y2="19.95" />
                        <line x1="16.84" y1="18.37" x2="16.84" y2="18.38" />
                        <line x1="19.37" y1="15.1" x2="19.37" y2="15.11" />
                        <line x1="19.94" y1="11" x2="19.94" y2="11.01" />
                      </svg>
                    </i>
                  </button>
                  <button
                    title={titleTooltips?.buttons?.duplicate}
                    className="button duplicate" type="button" onClick={() => {
                      dublicateVehicleLine(index);
                      previousFormAction.current = "dublicate";
                    }
                    }>
                    <p>Дублировать</p>
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon-copy" width="64" height="64" viewBox="0 0 24 24" strokeWidth="2" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="8" y="8" width="12" height="12" rx="2" />
                        <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                      </svg>
                    </i>
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div id="submit-form-container" className={`${formState === "success" || formState === "readyForInput" ? "" : "invisible"}`}>
            <button disabled={fields.length > 0 && !isActiveJournalDataLoading ? false : true} className="button submit" id="main-submit" type="submit" title={titleTooltips?.buttons?.submit}>Отправить</button>

            {hasStashedContent === true &&
              <button className="button refresh" onClick={moveFormToInitialState} title={titleTooltips?.buttons?.refresh} >
                <svg xmlns="http://www.w3.org/2000/svg" className="icon-refresh" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentcolor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                  <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
                </svg>
              </button>
            }
          </div>
          {
            successType.current !== undefined
            &&
            (formState === "success" || formState === "errorOnSubmit")
            &&

            <div id="success-on-submit-container" className={`${formState === "errorOnSubmit" ? "visible" : ""}`}>
              <p>{successType.current}</p>
            </div>
          }
        </form>
        <div id="error-on-submit-container" className={`${formState === "errorOnSubmit" ? "visible" : ""}`}>
          <h1>При отправке произошла ошибка</h1>
          {produceStashedContentTable(stashedContent.current)}
          <div className="appeal">
            <p>Пожалуйста, свяжитесь с <a href="https://t.me/RomanSergeev" target="_blank" rel="noreferrer">@Романом</a> и сообщите ему информацию об ошибке:</p>
            {errorData.current && 'status' in errorData.current && 'statusText' in errorData.current ?
              <>
                <p>Код ошибки: <code>{errorData.current?.status}</code></p>
                <p>Текст ошибки: <code>{errorData.current?.statusText}</code></p>
              </>
              :
              <>
                <p>Текст ошибки: <code>{errorData.current?.name || 'Unknown'} | {errorData.current?.message || 'No message'}</code></p>
              </>
            }
            <button className="button retry" onClick={() => { window.location.href = "/add" }}>Попробовать ещё раз</button>
          </div>
        </div>
      </div>
      <JournalEntryMergingConfirmation
        userData={userData}
        formState={formState}
        chosenDestinations={destinations}
        possibleDuplicates={possibleDuplicates}
        proceedFunction={saveFormData}
        setFormState={setFormState}
        dataToSend={dataToSend}
        handleSuccessfulSubmission={handleSuccessfulSubmission}
      />
    </>
  )
}


export default AddToJournalForm;
