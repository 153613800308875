import React from 'react';
import { getRidOfAliases } from '../../../services/littleHelpers';
import { 
    TextInput, 
    ParkingTypeSelect,
    SimpleInTableCheckbox
} from "../../inputElements";

import InTableFilesPreview from "../../commonUI/InTableFilesPreview";


const DebtRow = ({ preparedRow, justAlteredRowsRef }) => {

    let backhoeIcon = 
        <svg xmlns="http://www.w3.org/2000/svg" className="icon-backhoe" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="4" cy="17" r="2" />
            <circle cx="13" cy="17" r="2" />
            <line x1="13" y1="19" x2="4" y2="19" />
            <line x1="4" y1="15" x2="13" y2="15" />
            <path d="M8 12v-5h2a3 3 0 0 1 3 3v5" />
            <path d="M5 15v-2a1 1 0 0 1 1 -1h7" />
            <path d="M21.12 9.88l-3.12 -4.88l-5 5" />
            <path d="M21.12 9.88a3 3 0 0 1 -2.12 5.12a3 3 0 0 1 -2.12 -.88l4.24 -4.24z" />
        </svg>

    // console.log(tableDataRef.current)
    const cellRenderFunction = (cell) => {

        if (cell.column.id === "dest") {
            let d = cell.value[0]

            return (
                    <span 
                        className={(d?.status?.includes("Строящийся") || d?.status?.includes("На реконструкции")) ? "on-construction" : ""} 
                        title={d.name + `${d?.status ? " — " + d?.status.replace(",", ", ") : ""}`}
                        key={`dest-in-${cell.row.id}-row-${d.id}`}
                    >
                        {(d?.status?.includes("Строящийся") || d?.status?.includes("На реконструкции")) && backhoeIcon}
                        <p>{getRidOfAliases(d.name)}</p>
                    </span>
            );

        } else if (cell.column.id === "number") {
            
            return (
                <p className='vehicle-number'>
                    {cell.value}
                </p>
            );

        } else if (cell.column.id === "brand") {

            return (
                <p>
                    {getRidOfAliases(cell.value.brand)}
                </p>
            );
        } else if (cell.column.id === "category") {

            return (
                cell.value
            );

        } else if (cell.column.id === "comment") {
            
            const shouldBeDisabled = () => {
                let shouldBe = true;
                
                if (
                    (cell.row.original.credit.state === true && cell.row.original.paid.state === false) 
                        || 
                    cell.row.original.parking === "Парковка в кредит"
                ) {
                    shouldBe = false;
                }

                return shouldBe;
            }

            return(
                <TextInput
                    cell={cell}
                    textArea={true}
                    targetEndpoint="journal"
                    allowedRoles={['admin', 'dev', 'accountant']}
                    customDisablingFunction={shouldBeDisabled}
                />
            );

        } else if (cell.column.id === "useful_photo") {
            // console.log(cell.row.original)

            return (
                <InTableFilesPreview
                    files={cell.value}
                />
            );

        } else if (cell.column.id === "parking_type") {

            return (
                <ParkingTypeSelect
                    cell={cell}
                    allowedRoles={['admin', 'dev', 'accountant']}
                />
            );

        } else if (cell.column.id === "paid") {
            let disabled = true;
            
            if (cell.row.original.credit.state === true) {
                disabled = false
            }

            return (
                <SimpleInTableCheckbox
                    cell={cell}
                    justAlteredRowsRef={justAlteredRowsRef}
                    disabled={disabled}
                />
            );
        } else if (cell.column.id === "entry_point") {

            return cell.value
        }
        
        return cell.render('Cell');

    }

    return (
        <>
            <tr 
                key={`row-updated-at-${preparedRow?.original?.updated_at_full}`}
                id={`debt-entry-id-${preparedRow.original.origin_ID}`}
                className={`debt-entry`}
                {...preparedRow.getRowProps()}
            >
                {
                    preparedRow.cells.map((cell, i) => (
                        <td 
                            className={`cell-in-${cell.column.id} boxProperty`} 
                            key={`${cell.row.original.origin_ID}-${cell.column.header}`} 
                            {...cell.getCellProps()}
                        >
                            { cellRenderFunction(cell) }
                        </td>
                    ))
                }
            </tr>
        </>
    );

}


function rowsPropsAreEqual(prevRow, nextRow) {
    return prevRow.preparedRow.original.origin_ID === nextRow?.preparedRow?.original?.origin_ID
}

const DebtRowMemoised = React.memo(DebtRow, rowsPropsAreEqual)

export default DebtRowMemoised;