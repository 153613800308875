import { createDirectus, rest, authentication } from "@directus/sdk";
import { Schema } from "../models/DirectusSchema";

export function getDirectusClient() {
  const client = createDirectus<Schema>(process.env.REACT_APP_DIRECTUS_URL)
    .with(authentication("session", {
      credentials: "include",
      autoRefresh: true,
    }))
    .with(rest({
      credentials: 'include',
      onRequest: (options) => ({
        ...options,
        cache: 'no-store',
        'X-Requested-With': 'XMLHttpRequest',
      }),
    }));

  return client;
}
