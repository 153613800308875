// import { provideBrands } from "./DataProviders/provideBrands";
import { provideBrands } from "./ProvideDataObjects";
import { provideAreas, ProvideAreasOptions } from "./DataProviders/provideAreas"
import { useState, useEffect, useRef, useCallback } from "react";

interface SelectOption {
  value: string | number;
  label: string;
  credit_count?: number;
}

export interface UseSelectOptionsProps {
  optionName: "destinationOptions" | "brandsOptions";
  currentViewFilterCondition?: string | ProvideAreasOptions["currentViewFilterCondition"];
  siteSettings: {
    refreshFrequency: number;
  };
  valueToTriggerRecall?: any;
  enrichOptionsWithDeptorsData?: Record<string, number>;
  nocodb_auth?: string;
}

const useSelectOptions = ({
  optionName,
  currentViewFilterCondition,
  siteSettings,
  valueToTriggerRecall,
  enrichOptionsWithDeptorsData,
  nocodb_auth
}) => {

  const [options, setOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const allOptionsRef = useRef(options);
  const abortControllerRef = useRef<AbortController | null>(null);
  const inStorageName = `${optionName}=${currentViewFilterCondition ? JSON.stringify(currentViewFilterCondition) : "all"}`
  
  const fetchAndProcessOptions = useCallback(async (controller: AbortController, enrichOptions: Record<string, number>) => {
    // console.log("%cuseSelectOptions called", "color: red; font-weight: bold;")
    setIsLoading(true)
    let tempOptions: SelectOption[] | undefined;

    if (optionName === "destinationOptions") {
      tempOptions = await provideAreas({
        currentViewFilterCondition,
        controller: controller,
      });
    } if (optionName === "brandsOptions") {
      tempOptions = await provideBrands({
        controller: controller,
        auth_token: nocodb_auth // remove when changing data source to directus
      });
    }

    if (!!enrichOptions && tempOptions !== undefined) {

      Object.keys(enrichOptions).forEach(debtor => {

        const d = tempOptions?.find(option => {
          return option.value.toString() === debtor
        });

        d.credit_count = enrichOptions[`${debtor}`];
      });

    }
    if (tempOptions) {
      setOptions(tempOptions);
      allOptionsRef.current = tempOptions;
      window.localStorage.setItem(
        inStorageName,
        JSON.stringify({
          arrayOfOptions: tempOptions,
          dateOfInsertion: new Date(),
        })
      );
    }
    setIsLoading(false)
    // currentViewFilterCondition removed from dependency array to avoid rerenders, as it is an object recreated on every render
    // At the same time, currentViewFilterCondition, as a string included in inStorageName so there is no need to include 
  }, [inStorageName, nocodb_auth, optionName])


  useEffect(() => {
    abortControllerRef.current = new AbortController();

    const fetchOptions = async () => {
      const storedData = window.localStorage.getItem(inStorageName);

      if (!storedData) {
        await fetchAndProcessOptions(abortControllerRef.current, enrichOptionsWithDeptorsData);
      } else {
        const tempData = JSON.parse(storedData);

        if (
          Date.now() - Date.parse(tempData.dateOfInsertion) < siteSettings.refreshFrequency &&
          Array.isArray(tempData.arrayOfOptions)
        ) {
          setOptions(tempData.arrayOfOptions);
          allOptionsRef.current = tempData.arrayOfOptions;
        } else {
          await fetchAndProcessOptions(abortControllerRef.current, enrichOptionsWithDeptorsData);
        }
      }
    };

    fetchOptions();

    return () => {
      abortControllerRef.current.abort();
    }

  }, [enrichOptionsWithDeptorsData, valueToTriggerRecall, fetchAndProcessOptions, inStorageName, siteSettings.refreshFrequency]);

  return [
    isLoading,
    allOptionsRef,
    options,
    setOptions,
  ] as const;

}

export default useSelectOptions;
