import React, { useContext, useMemo, useEffect, useRef, useCallback, useState } from 'react';

import { MainContext } from "../../MainContext";
import Table from '../../commonUI/Table';

import { AllRowsSelection } from "../../inputElements";
import SelectAreaForReport, { useAreaToFilterBy } from "./SelectAreaForReport";
import useSelectOptions from '../../../services/useSelectOptions';
import VehicleForReportRowMemoised from "./VehicleForReportRow";
import GetReportMemoized from './GetReport';

import { provideJournalEntries } from '../../../services/ProvideDataObjects';

const initialArrayDataObject = [];

const useDebtorsMap = ({ recentReportAreaId }) => {
  const { userData } = useContext(MainContext);
  const [debtors, setDebtors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const abortControllerRef = useRef(undefined);
  const isFirstRender = useRef(true);
  // console.log("recentReports", recentReportAreaId);
  // console.log("isFirstRender", isFirstRender.current);

  const provideJournalDebtsCallback = useCallback(async () => {
    const dateFilterConstrain = `~not(entered_at,like,2022-)~not(entered_at,like,2023-01)~not(entered_at,like,2023-02)~not(entered_at,like,2023-03)~not(entered_at,like,2023-04)`

    let temp = await provideJournalEntries({
      auth_token: userData.nocodb_auth,

      currentViewFilterCondition: dateFilterConstrain + "~not(area_status,like,Постоплата)~and((entered,eq,true)~and(((credit,eq,true)~and(paid,eq,false))~or(parking,eq,Парковка в кредит))))",
      quick: true,
      recordsLimit: 1000,
      controller: abortControllerRef.current
    })

    return temp;
  }, [userData.nocodb_auth]);

  const recentReportAreaIdString = useMemo(() => JSON.stringify(recentReportAreaId) || "none", [recentReportAreaId]);

  useEffect(() => {

    if (isFirstRender.current) {
      abortControllerRef.current = new AbortController();

      (async () => {
        setIsLoading(true);
        const debtorsEntries = await provideJournalDebtsCallback();

        let uniqueIds = debtorsEntries.data.reduce((prev, next) => {

          if (!prev) {
            prev = {
              [`${next.dest_id}`]: 1
            }
          } else if (prev.hasOwnProperty(next.dest_id)) {
            prev[`${next.dest_id}`] += 1;
          } else {
            prev[`${next.dest_id}`] = 1;
          }

          return prev;

        }, {})

        // console.log("%cuseDebtorsMap just set fresh setDebtors", "color:green")
        setDebtors(uniqueIds);
        setIsLoading(false);
      })();
      isFirstRender.current = false;
    }

    return () => {
      abortControllerRef.current?.abort();
      abortControllerRef.current = undefined;
      isFirstRender.current = true;
    }
  }, [provideJournalDebtsCallback, recentReportAreaIdString])

  // console.log("useDebtors runs recentReportAreaIdString", recentReportAreaIdString)

  // return [isLoading, debtors]
  return useMemo(() => [isLoading, debtors], [isLoading, debtors]);
}


const PostpaidReports = () => {
  const { siteSettings, userData } = useContext(MainContext);
  const [recentReportAreaId, setRecentReportAreaId] = useState();
  // console.log(
  // "postpaidReports component renders"
  // )
  const [isLoading, debtors] = useDebtorsMap({ recentReportAreaId });

  // const currentViewFilterCondition = useMemo(() => {
  //   console.log("debtors", debtors)
  //   const condition = {
  //     _or: [
  //       {
  //         _and: [
  //           {
  //             status: {
  //               _contains: "Постоплата",
  //             },
  //           },
  //           {
  //             status: {
  //               _ncontains: "Только по депозитам",
  //             }
  //           }
  //         ]
  //       }
  //     ]
  //   };

  //   if (Object.keys(debtors)?.length > 0) {
  //     condition._or.push({
  //       id: {
  //         _in: Object.keys(debtors)
  //       }
  //     });
  //   }

  //   return condition;
  // }, [debtors]);

  const [
    areOptionsLoading,
    allDestinationOptionsRef,
    destinationOptions,
  ] = useSelectOptions({
    siteSettings,
    nocodb_auth: userData.nocodb_auth,
    optionName: "destinationOptions",
    // currentViewFilterCondition: `${Object.keys(debtors)?.length > 0 ?"(Id,in," + Object.keys(debtors)?.join() + ")~or" : ""}(status,like,Постоплата)~not(status,like,Только по депозитам)`,
    // currentViewFilterCondition: currentViewFilterCondition,
    currentViewFilterCondition: {
      _or: [
        {
          _and: [
            {
              status: {
                _contains: "Постоплата",
              },
            },
            {
              status: {
                _ncontains: "Только по депозитам",
              }
            }
          ]
        },
        {
          id: {
            _in: Object.keys(debtors)?.length > 0 ? Object.keys(debtors) : ""
          }
        }
      ]
    },
    valueToTriggerRecall: debtors,
    enrichOptionsWithDeptorsData: debtors
  })

  const [
    areaToFilterBy,
    setAreaToFilterBy,
  ] = useAreaToFilterBy({ destinationOptions });


  const initialHiddenColumns = {
    onlyActiveHidden: ['dest', 'useful_photo', 'entry_point', 'entered_at', 'paid', 'deposit_id', 'parking_type', 'origin_ID'],
    allHidden: ['dest', 'useful_photo', 'origin_ID']
  };

  const cols = useMemo(() => {
    return [
      {
        Header: 'Объект',
        accessor: 'dest',
      },
      {
        Header: 'Номер',
        accessor: 'number',
      },
      {
        Header: 'Марка',
        accessor: 'brand',
      },
      {
        Header: 'Категория',
        accessor: 'category',
      },
      {
        Header: 'Комментарий',
        accessor: 'comment',
      },
      {
        Header: 'Статус парковки',
        accessor: 'parking_type',
      },
      {
        Header: 'Фото',
        accessor: 'useful_photo',
        disableFilters: true,
      },
      {
        Header: 'Доступ оплачен',
        accessor: 'paid',
        disableFilters: true,
      },
      {
        Header: 'Доступ в кредит',
        accessor: 'credit',
        disableFilters: true,
      },
      {
        Header: 'По депозиту c ID:',
        accessor: 'deposit_id',
        // disableFilters: true,
      },
      {
        Header: 'Время заявки',
        accessor: 'created_at',
        // disableFilters: true,
      },
      {
        Header: 'Время доступа',
        accessor: 'entered_at',
        // disableFilters: true,
      },
      {
        Header: 'Точка доступа',
        accessor: 'entry_point',
      },
      {
        Header: 'origin_ID',
        accessor: 'origin_ID',
        // disableFilters: true,
      }
    ];
  }, [])

  const columns = useMemo(() => {
    let tempArr = [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <AllRowsSelection
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        disableFilters: true,
      }
    ]

    let fin = tempArr.concat(cols)

    return fin;

  }, [cols]);

  const altColumns = useMemo(() => {
    return cols;
  }, [cols])

  const getVehicleForReportRowMemoised = useCallback(({
    row,
    hiddenColumnsLength,
    justAlteredRowsRef,
    selectedFlatRows,
  }) => {

    return <VehicleForReportRowMemoised
      key={`row-entry-${row.original.origin_ID}__${row.original.updated_at.replace(/\s/g, '_')}__${hiddenColumnsLength}__${row.isSelected}`}
      preparedRow={row}
      justAlteredRowsRef={justAlteredRowsRef}
      selectedFlatRows={selectedFlatRows}
    />

  }, [])

  const GetMemoizedReportCallback = useCallback(({
    isLoading,
    selectedFlatRows,
    justAlteredRowsRef,
    toggleAllRowsSelected,
  }) => {

    return (
      <>
        {
          areaToFilterBy && selectedFlatRows?.length > 0 &&
          <GetReportMemoized
            isLoading={isLoading}
            areaToFilterBy={areaToFilterBy}
            markedEntries={selectedFlatRows}
            justAlteredRowsRef={justAlteredRowsRef}
            toggleAllRowsSelected={toggleAllRowsSelected}
            setRecentReportAreaId={setRecentReportAreaId}
          />
        }
      </>
    );

  }, [areaToFilterBy])

  const getFilterStringCallback = useCallback(({ showOnlyForReport = true }) => {
    let freeCategoriesFilter = '~not(category,like,Пешеход)'
    let enterDateConstrains = '';

    if (!areaToFilterBy?.area_status.includes('Весь транспорт платный')) {
      freeCategoriesFilter += '~not(category,like,Легковой)'
    }

    if (
      areaToFilterBy?.label?.includes('100 У') ||
      areaToFilterBy?.label?.includes('1215/5 У') ||
      areaToFilterBy?.label?.includes('21 У') ||
      areaToFilterBy?.label?.includes('43 У') ||
      areaToFilterBy?.label?.includes('49 У') ||
      areaToFilterBy?.label?.includes('58 У') ||
      areaToFilterBy?.label?.includes('65 У')
    ) {
      /**
       * TODO: contact nocodb devs -- no way to keep it -- we need a way to filter by dates...
       */

      // freeCategoriesFilter += '~and((category,neq,Легковой)~or(entered_at,like,2022-10-)  ~or(entered_at,like,2022-11-)~or(entered_at,like,2022-12-)~or(entered_at,like,2023))'
      enterDateConstrains += '~not(entered_at,like,2022)~not(entered_at,like,2023-01)~not(entered_at,like,2023-02)~not(entered_at,like,2023-03)~not(entered_at,like,2023-04)'
    } else if (areaToFilterBy?.label?.includes('9/2 У')) {
      /**
       * TODO: get rid of this ugly solution too! This area is under construction
       * since 2022-03-01
       */

      enterDateConstrains += '~not(entered_at,like,2022-)~not(entered_at,like,2023-01)~not(entered_at,like,2023-02)'
    } else if (areaToFilterBy?.label?.includes('35 У')) {
      /**
       * TODO: get rid of this ugly solution too! This area is under construction
       * since 2023-05-22
       */

      enterDateConstrains += '~not(entered_at,like,2022-)~not(entered_at,like,2023-01)~not(entered_at,like,2023-02)~not(entered_at,like,2023-03)~not(entered_at,like,2023-04)~not(entered_at,like,2023-05-0)~not(entered_at,like,2023-05-1)~not(entered_at,like,2023-05-20)'
    } else if (areaToFilterBy?.label?.includes('61 У')) {
      /**
       * TODO: get rid of this ugly solution too! This area is under construction
       * since 2023-05-22
       */

      enterDateConstrains += '~not(entered_at,like,2022-)~not(entered_at,like,2023-)~not(entered_at,like,2024-0)~not(entered_at,like,2024-10-01)~not(entered_at,like,2024-10-02)~not(entered_at,like,2024-10-03)~not(entered_at,like,2024-10-04)~not(entered_at,like,2024-10-05)~not(entered_at,like,2024-10-06)'
    } else if (areaToFilterBy?.label?.includes('46 У')) {
      /**
       * TODO: get rid of this ugly solution too! This area is under construction
       * since 2024-01-01
       */
      enterDateConstrains += '~not(entered_at,like,2022-)~not(entered_at,like,2023)'

    } else {
      enterDateConstrains += '~not(entered_at,like,2022-)~not(entered_at,like,2023-01)~not(entered_at,like,2023-02)~not(entered_at,like,2023-03)~not(entered_at,like,2023-04)'
    }

    // console.log("Ffs")
    // console.log("areaToFilterBy", areaToFilterBy)


    let currentViewFilter = `(entered,eq,true)` +
      `${showOnlyForReport ? "~and((paid,eq,false)~or(parking,eq,Парковка в кредит))" : ""}` +
      `${areaToFilterBy ? "~and(nc_xcf____nc_m2m_x1blmhmkaeList,eq," + areaToFilterBy.value + ")" : ""}` +
      `${freeCategoriesFilter + enterDateConstrains}`;

    if (areaToFilterBy?.credit_count > 0) {
      currentViewFilter = `(entered,eq,true)` +
        `${showOnlyForReport ? "~and(((paid,eq,false)~and(credit,eq,true))~or(parking,eq,Парковка в кредит))" : ""}` +
        `${areaToFilterBy ? "~and(nc_xcf____nc_m2m_x1blmhmkaeList,eq," + areaToFilterBy.value + ")" : ""}` +
        "~not(category,like,Пешеход)" +
        `${showOnlyForReport ? enterDateConstrains : ""}`;
    }


    return currentViewFilter;

  }, [areaToFilterBy])


  return (
    <Table
      location='/accounting'
      tablePrefix="reports"
      initialArrayDataObject={initialArrayDataObject}
      initialShowOnlyActiveState={true}
      columns={columns}
      altColumns={altColumns}
      initialHiddenColumns={initialHiddenColumns}
      toggle={
        {
          enabled: true,
          showOnlyActiveLabel: "К оплате:",
          showAllLabel: "Все записи:",
          addClassName: "reports-toggle",
          toggleContainerAddClassName: "boxProperty",
          mutateId: true,
          isToggleDisabled: false,
          onlyHat: false,
          setId: "setShowOnlyForReportSwitch",
          insertBefore: (
            <SelectAreaForReport
              isLoading={isLoading}
              optionsRef={allDestinationOptionsRef}
              options={destinationOptions}
              setAreaToFilterBy={setAreaToFilterBy}
              value={areaToFilterBy}
              label={"Целевой объект"}
            />
          )
        }
      }
      returnMemoizedTableRowComponent={getVehicleForReportRowMemoised}
      noEntriesMessage={'Подходящие записи не найдены 🙃'}
      dataSettings={{
        blockfetching: areOptionsLoading,
        dependency: areaToFilterBy,
        initialPageSize: 1000,
        altInitialPageSize: 10,
        updateFrequency: 5000,
        cachedDataLifeSpan: 60000,
        onViewSwitchFilterCondition: getFilterStringCallback({ showOnlyForReport: true }),
        offViewSwitchFilterCondition: getFilterStringCallback({ showOnlyForReport: false }),
        sortString: "-updated_at",
        altSortString: "-updated_at",
        localFieldToCompareBy: "updated_at_full",
        remoteFieldToCompareBy: "updated_at",
        keepTableSettings: false,
        syncRecordsPerPage: false,
      }}
      insertAfterTable={GetMemoizedReportCallback}
    />
  );

}

// export default PostpaidReports;
export default React.memo(PostpaidReports);
