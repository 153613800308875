import Keycloak from 'keycloak-js';

const returnKeycloakConfig = () => {
    if (process.env.NODE_ENV === "production") {

        if (window.location.origin === "https://service.landshaft.life") {
            return process.env.REACT_APP_KEYCLOAK_BASE_CONFIG
        } else {
            return process.env.REACT_APP_KEYCLOAK_RESERVE_CONFIG
        }

    } else {
        return process.env.REACT_APP_KEYCLOAK_CONFIG
    }
}

const _kc = new Keycloak(
    returnKeycloakConfig()
)

const initKeycloak = (onAuthenticatedCallback) => {
    console.log("initKeycloak runs")
    
    console.log("freaky function returns client config: ",
        returnKeycloakConfig() 
    )

    _kc.init({
        enableLogging: true,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        silentCheckSsoFallback: false,
        pkceMethod: 'S256',
    }).then((authenticated) => {

        if (authenticated) {
            onAuthenticatedCallback();
        } else {
            console.warn("not authenticated!");
            doLogin();
        }

    }).catch((e) => console.log(e))
}

const isLoggedIn = () => !!_kc.token;
const doLogin = _kc.login;
const doLogout = _kc.logout;

const getToken = () => _kc.token;

const updateToken = (successCallback) => {
    _kc.updateToken(5)
        .then(successCallback)
        .catch(doLogin);
}

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getParsedToken = () => _kc.tokenParsed

const getParsedIdToken = () => _kc.idTokenParsed

// const getResourceAccess = () => _kc.resourceAccess

// const getRealmAccess = () => _kc.realmAccess


const hasRole = (roles) => {
/**
 * returns true if any of the passed roles met
 * among current logged in user's resource roles
 */
    const appName = getParsedToken()?.azp;
    const usersRoles = _kc.resourceAccess?.[`${appName}`]?.roles;

    if (usersRoles === undefined) return false;

    let result = roles.some((role) => {

        if (usersRoles.findIndex((r) => r === role) !== -1) {
            return true
        } else {
            return false
        }

    })

    return result;
}

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    getParsedToken,
    getParsedIdToken,
    // getResourceAccess,
    // getRealmAccess,
    hasRole,
  };
  
  export default UserService; 