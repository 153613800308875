import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./App.scss";
import { MainContext, MainContextType } from "./components/MainContext";
import LoginPage from "./components/login/login";
import NotWelcomePage from "./components/NotWelcomePage";
import Dev from "./components/settings/dev/Dev";
import AddToJournalForm from "./components/add-to-journal/AddToJournalForm";
import Navigation from "./components/navigation/Navigation";
import Settings from "./components/settings/Settings";
import History from "./components/settings/History";
import VehicleBrands from "./components/settings/VehicleBrands/VehicleBrands";
import Journal from "./components/journal/Journal";
import LocalPedestrians from "./components/pedestrians/LocalPedestrians";
import Accounting from "./components/accounting/Accounting";
import AllDeposits from "./components/accounting/AllDeposits/AllDeposits";
import MainSearch from "./components/main-search/MainSearch";

import UserService from "./services/DirectusUserService";
import RenderOnRole from "./services/RenderOnRole";
import LocalVehicles from "./components/local-vehicles/LocalVehicles";
import BlacklistPage from "./components/blacklist/BlacklistPage";
import Contacts from "./components/contacts/Contacts";
import LoadingIcon from "./components/commonUI/LoadingIcon";

const defaultSiteSettings = {
  theme: "light",
  tooltips: true,
  refreshFrequency: 60 * 60 * 1000,
  journal_loggingEnabled: false,
  debt_loggingEnabled: false,
  reports_loggingEnabled: false,
  blacklist_loggingEnabled: false,
  deposits_loggingEnabled: false,
  localVehicles_loggingEnabled: false,
  localPedestrians_loggingEnabled: false,
  allBrands_loggingEnabled: false,
};

export const allSecurityRoles = [
  "Chief of Security",
  "Administrator",
  "Master",
  "dev",
  "admin",
  "analyst",
  "dezhurniy",
  "kpp1",
  "kpp2",
  "kpp3",
  "starshoi",
  "patrol1",
  "patrol2",
] as const;

export const allRoles = [...allSecurityRoles, "accountant"] as const;

function App() {
  const [siteSettingsState, setSiteSettingsState] = useState(
    window.localStorage.getItem("siteSettings") !== "null" &&
      window.localStorage.getItem("siteSettings") !== null
      ? JSON.parse(window.localStorage.getItem("siteSettings"))
      : defaultSiteSettings,
  );
  const [_isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [userData, setUserData] = useState(null);
  const { setIsAuthenticated: storeAuthenticationStateInContext, changeUserData: storeUserDataInContext } = useContext(MainContext);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      try {
        const authenticated = await UserService.checkAuth();
        setIsAuthenticated(authenticated);
        storeAuthenticationStateInContext(authenticated);

        if (authenticated) {
          const user = await UserService.getCurrentUser();
          setUserData(user);
          storeUserDataInContext(user);
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [storeUserDataInContext, storeAuthenticationStateInContext]);

  const contextValue = {
    siteSettings: siteSettingsState,
    setSiteSettings: newSettings =>
      setSiteSettingsState({ ...siteSettingsState, ...newSettings }),
    userData,
    changeUserData: setUserData,
    isAuthenticated: isAuthenticated === true,
    setIsAuthenticated,
  } as MainContextType;

  useEffect(() => {
    if (siteSettingsState?.theme === "dark") {
      trans();
      document.documentElement.setAttribute("data-theme", "dark");
    }
    if (siteSettingsState?.theme === "light") {
      trans();
      document.documentElement.setAttribute("data-theme", "light");
    }

    window.localStorage.setItem("siteSettings", JSON.stringify(siteSettingsState));
  }, [siteSettingsState]);

  const trans = () => {
    document.documentElement.classList.add("transition");
    window.setTimeout(() => {
      document.documentElement.classList.remove("transition");
    }, 1000);
  };

  if (isAuthenticated === null) {
    return <LoadingIcon addClassName={``} />;
  }

  return (
    <>
      <MainContext.Provider value={contextValue}>
        <Navigation />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          {isAuthenticated ? (
            <>
              <Route path="*" element={<NotWelcomePage />} />
              <Route
                path="/add"
                element={
                  <RenderOnRole
                    roles={["admin", "dezhurniy"]}
                    fallbackElement={<NotWelcomePage />}>
                    <AddToJournalForm />
                  </RenderOnRole>
                }
              />
              <Route
                path={"/"}
                element={
                  <RenderOnRole
                    roles={allSecurityRoles}
                    fallbackElement={<NotWelcomePage />}>
                    <Journal />
                  </RenderOnRole>
                }
              />
              <Route
                path="/pedestrians"
                element={
                  <RenderOnRole
                    roles={["admin", "kpp2", "kpp1", "patrol1", "patrol2", "analyst"]}
                    fallbackElement={<NotWelcomePage />}>
                    <LocalPedestrians />
                  </RenderOnRole>
                }
              />
              <Route
                path="/local-vehicles"
                element={
                  <RenderOnRole
                    roles={allSecurityRoles}
                    fallbackElement={<NotWelcomePage />}>
                    <LocalVehicles />
                  </RenderOnRole>
                }
              />
              <Route
                path="/blacklist"
                element={
                  <RenderOnRole
                    roles={allSecurityRoles}
                    fallbackElement={<NotWelcomePage />}>
                    <BlacklistPage />
                  </RenderOnRole>
                }
              />
              <Route
                path="/settings"
                element={
                  <RenderOnRole roles={allRoles} fallbackElement={<NotWelcomePage />}>
                    <Settings />
                  </RenderOnRole>
                }
              />
              <Route
                path="/accounting"
                element={
                  <RenderOnRole
                    roles={["admin", "accountant"]}
                    fallbackElement={<NotWelcomePage />}>
                    <Accounting />
                  </RenderOnRole>
                }
              />
              <Route
                path="/accounting/deposits"
                element={
                  <RenderOnRole
                    roles={["admin", "accountant"]}
                    fallbackElement={<NotWelcomePage />}>
                    <AllDeposits />
                  </RenderOnRole>
                }
              />
              <Route
                path="/settings/add-to-journal-form-history"
                element={
                  <RenderOnRole
                    roles={["admin", "dezhurniy"]}
                    fallbackElement={<NotWelcomePage />}>
                    <History />
                  </RenderOnRole>
                }
              />
              <Route
                path="/settings/vehicle-brands"
                element={
                  <RenderOnRole
                    roles={allSecurityRoles}
                    fallbackElement={<NotWelcomePage />}>
                    <VehicleBrands />
                  </RenderOnRole>
                }
              />
              <Route
                path="dev"
                element={
                  <RenderOnRole roles={allRoles} fallbackElement={<NotWelcomePage />}>
                    <Dev />
                  </RenderOnRole>
                }
              />
              <Route
                path="contacts"
                element={
                  <RenderOnRole roles={allRoles} fallbackElement={<NotWelcomePage />}>
                    <Contacts />
                  </RenderOnRole>
                }
              />
              <Route
                path="search"
                element={
                  <RenderOnRole
                    roles={["admin", "dezhurniy", "patrol1", "patrol2", "analyst"]}
                    fallbackElement={<NotWelcomePage />}>
                    <MainSearch />
                  </RenderOnRole>
                }
              />
            </>
          ) : (
            <Route
              path="*"
              element={<Navigate to="/login" replace state={{ from: location }} />}
            />
          )}
        </Routes>
        <footer>
          <div id="sticky-footer">
            <p>👮 2022</p>
          </div>
        </footer>
      </MainContext.Provider>
    </>
  );
}

export default App;
