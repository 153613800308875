import React from 'react';
import { useSearchParams } from "react-router-dom";


const RefreshTable = ({filters, setAllFilters, showTooltips = true}) => {
    let [, setSearchParams] = useSearchParams();

    const handleRefresh = () => {
        const toReset = []
        filters.forEach(f => {
            toReset.push({
                id: f.id,
                value: undefined,
            })
            setSearchParams(f.id, undefined)
        });

        setAllFilters(toReset)
    }
    
    return (
        <button 
            className='refresh-columns' 
            onClick={handleRefresh} 
            tabIndex={-1}
            title={showTooltips ? "Сбросить все фильтры `Shift + Alt + C`" : ""}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-refresh" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
            </svg>
        </button>
    );
}

export default RefreshTable;