import React, { useRef, useState, useContext } from 'react';

import { MainContext } from "../MainContext";
import useCachedDataWithUpdates from "../../services/useCachedDataWithUpdates";

import LoadingIcon from "../commonUI/LoadingIcon";
import BlacklistItemMemoised from "./BlacklistItem"
import ModalDialog from "../modals/ModalDialog";
import AddToBlacklistDialog from "./AddToBlacklistDialog"


const initialArrayDataObject = [];

const BlacklistPage = () => {
    const { userData, siteSettings } = useContext(MainContext);
    const [confirmationRequiered, setConfirmationRequiered] = useState(false);
    const [blacklistData, setBlacklistData] = useState();
    const confirmationSubjectRef = useRef(<></>);

    const [
        isBlacklistLoading,
    ] = useCachedDataWithUpdates({
        location: "/blacklist",
        dataSource: "blacklist",
        updateFrequency: 3000,
        onViewSwitchFilterCondition: '(archived,eq,false)',
        sortString: "-updated_at",
        offViewSwitchFilterCondition: undefined,
        altSortString: "-updated_at",
        localFieldToCompareBy: "updated_at",
        remoteFieldToCompareBy: "updated_at_full",
        nocodb_auth: userData.nocodb_auth,
        setTableData: setBlacklistData,
        initialStateObject: initialArrayDataObject,
        loggingEnabled: !!siteSettings?.blacklist_loggingEnabled,
    });

    
    const handleAddingToBlacklist = () => {
        confirmationSubjectRef.current = 
            <AddToBlacklistDialog
                userData={userData}
                siteSettings={siteSettings}
                closeDialogFunction={setConfirmationRequiered}
            />
        setConfirmationRequiered(true);
    }


    return (
        <>
        <div id="blacklist-page">
            <h2>Чёрный список</h2>
            {   
                !isBlacklistLoading && blacklistData ?
                <div id="blacklist-container">
                    <div id="add-to-blacklist-container">
                        <button 
                            className='button add-to-blacklist'
                            onClick={handleAddingToBlacklist}
                        >
                            +
                        </button>

                    </div>
                    {
                        blacklistData.map((car) => (
                            <BlacklistItemMemoised 
                                key={`BlacklistItem-${car.origin_ID}`}
                                item={car}
                                canBeReleased={true}
                                setConfirmationRequiered={setConfirmationRequiered}
                                confirmationSubjectRef={confirmationSubjectRef}
                                detailsExpanded={true}
                            />
                        ))
                    }
                </div>
                :
                <LoadingIcon />
            }
        </div>
        <ModalDialog 
            isOpen={confirmationRequiered} 
            exitFunction={setConfirmationRequiered}
        >
            {confirmationSubjectRef.current}
        </ModalDialog>

        </>
    );

}

export default BlacklistPage;