import React, { useContext } from "react";
import { MainContext } from "../MainContext";

import Spoiler from "../commonUI/Spoiler";
import Debt from "./Debt/Debt";
import DepositsShort from "./DepositsShort";
import PostpaidReports from "./PostpaidReports/PostpaidReports";

const Accounting = () => {
    const { userData, siteSettings } = useContext(MainContext);
    
    return (
        <div id="accounting-page">
            <Spoiler
                header={"Задолженности"}
                contentID={"accounting-debt-container"}
                addClassName={"debt"}
                showCount={false}
            >
                <Debt />
            </Spoiler>
            <Spoiler
                header={"Депозиты"}
                contentID={"accounting-short-deposits-container"}
                showCount={false}
                shouldRenderCollapsed={true}
            >
                <DepositsShort
                    siteSettings={siteSettings}
                    userData={userData}
                />
            </Spoiler>
            <Spoiler
                header={"Ведомости для постоплаты"}
                contentID={"accounting-reports-container"}
                showCount={false}
            >
                <PostpaidReports
                    tooltips={siteSettings?.tooltips}
                    userData={userData}
                />
            </Spoiler>
        </div>
    );
};

export default Accounting;
